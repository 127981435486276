<app-dialog-header title="Sélection" />

<div mat-dialog-content class="thin-scrollbar">
  <h3 *ngIf="entite" style="text-align: center;">{{ piece.name }} <span *ngIf="entite.name">pour
      <span>{{ (entite.type === 'famille' ? 'la famille ' : '') + entite.name }}</span></span>
  </h3>
  <h3 *ngIf="!entite" style="text-align: center;">{{ piece.name }}</h3>
  <app-file-preview [fileName]="uploadingFile.name" [previewURL]="previewURL"></app-file-preview>
  <div class="uploading-file-name">
    Fichier à envoyer :
    <span>{{ uploadingFile.name }}</span>
  </div>

  <mat-progress-bar *ngIf="uploadProgress !== null" [value]="uploadProgress"></mat-progress-bar>
</div>

<div mat-dialog-actions align="end">
  <ng-container *ngIf="!errorMessage; else uploadError">
    <button mat-raised-button color="warn" (click)="cancelUpload()">Annuler</button>
    <button mat-raised-button color="primary" (click)="onUploadSubmit()" [disabled]="uploadProgress !== null">
      Envoyer
    </button>
  </ng-container>

  <ng-template #uploadError>
    <span class="err-message">
      <i class="fas fa-exclamation-triangle icon-exclamation"></i>
      <span [innerHtml]="errorMessage | safe"></span>
    </span>
  </ng-template>
</div>