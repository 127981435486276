import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dynamic',
    standalone: false
})
export class DynamicPipe implements PipeTransform {

  // @TODO: think about replacing this with a parse in Init / Change of component, would be more efficient / lighter
  transform(value: string, variables: object): string {
    // Not this way @TODO: instead check for {{ varname }}, capture the varname and replace with value if exists (or '' by default)
    Object.keys(variables || {}).forEach(variable => {
      const regexp = new RegExp('{{[\\s]*(' + variable + ')[\\s]*}}', 'g');
      const result = regexp.exec(value);
      if (result) {
        value = value.replace(regexp, this.getValue(variables, variable));
      }
    });

    return value;
  }

  // @TODO: improve to access child property etc (ex: user.age, or child[1].age)
  getValue(object, keyChain) {
    return object[keyChain];
  }
}
