<mat-card appearance="raised" *ngFor="let day of byDays" class="date-container mat-elevation-z1" style="padding: 16px;">
  <mat-card-title class="date-header primary">{{ day.date|date:'fullDate'|titlecase }}</mat-card-title>

  <div class="items">
    <div *ngFor="let presence of day.items; let last = last" class="item" [ngClass]="{'attente': presence.attente, 'unselected': presence.attente === -1 ,
         'canceling':presence.askCancel , 'error': presence.error}">
      <span class="time" [ngClass]="{'multiple': !!presence.startTime2}">
        <ng-container *ngIf="presence.showTimes">
          {{ presence.startTime }} - {{ presence.endTime }}
          <span *ngIf="presence.startTime2">{{ presence.startTime2 }} - {{ presence.endTime2 }}</span>
        </ng-container>
      </span>
      <span class="color" [style.backgroundColor]="presence.color"></span>
      <span class="title">
        {{ presence.title }}
        <ng-container *ngIf="presence.activities?.length as nbActivities">
          (+ {{ nbActivities }} activités)
        </ng-container>
      </span>

      <span *ngIf="presence.askCancel" class="canceling">Demande d'annulation</span>

      <span *ngIf="presence.attente" class="attente">
        <mat-checkbox *ngIf="planningData.currentPeriode.gestionListeAttente === 'demande'; else autoAttente"
          [checked]="presence.attente === 1" (change)="onToggleAttente(presence, $event)">
          Inscrire sur liste d'attente
        </mat-checkbox>
      </span>

      <span *ngIf="presence.error" class="error-icon" [matTooltip]="presence.error.errorMessage">
        <mat-icon color="warn">error_outline</mat-icon>
      </span>
    </div>
  </div>
</mat-card>

<ng-template #autoAttente>
  Liste d'attente
</ng-template>