import { Component, OnInit, Input, QueryList, ViewChildren, ElementRef, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GedConfig, GedDocument, GedEntity, GedPiece } from '@app/models/ged';
import { GedService } from '@app/services/ged.service';
import { forkJoin } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { DocDetailsComponent } from '../../ged/doc-details/doc-details.component';
import { PlatformService, SnackbarService } from '@app/services';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { FileUploadDialogComponent } from '../../ged/file-upload-dialog/file-upload-dialog.component';

@Component({
    selector: 'app-demarche-ged',
    templateUrl: './demarche-ged.component.html',
    styleUrls: ['./demarche-ged.component.scss'],
    standalone: false
})
export class DemarcheGedComponent implements OnInit {

  isLoading: boolean;
  documents: GedDocument[];
  idEntite: number;
  uploadingFile: File;
  uploadDialogRef: MatDialogRef<any>;
  previewURL: any;
  errorMessage: string;
  documentDownload: { [doc: number]: number } = {};
  configGed: GedConfig;
  canValidate: boolean;

  @Input() entite: GedEntity;
  @Input() piecesAFournir: GedPiece[];
  @Output() validate = new EventEmitter();

  @ViewChildren('inputFile') inputFile: QueryList<ElementRef>;
  @ViewChild('pieceDetailsDialog', { static: true }) pieceDetailsDialog: TemplateRef<any>;

  constructor(
    private gedService: GedService,
    private matDialog: MatDialog,
    public platformService: PlatformService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.piecesAFournir = this.piecesAFournir.filter(piece => (piece.obj.toLowerCase() === this.entite.type.toLowerCase()) && piece.enabled);
    this.gedService.sortByOrder(this.piecesAFournir);

    this.loadData().subscribe(_ => {
      this.isLoading = false;
      this.checkValidate();
    })
  }

  loadData() {
    this.isLoading = true;
    const loaders = [];

    loaders.push(this.gedService.getConfig().pipe(tap((configGed: GedConfig) => {
      this.configGed = configGed;
    })));

    loaders.push(this.gedService.getCurrentFamilyDocuments().pipe(tap((docs: GedDocument[]) => {
      this.documents = docs.filter(doc => new Date().getTime() < new Date(doc.dateFinValidite).getTime());
    })));

    return forkJoin(loaders);
  }


  getFileIcon(doc: GedDocument) {
    return this.gedService.getFileIcon(doc);
  }

  getDocumentsFor(piece: GedPiece, entity: GedEntity) {
    let idKey: string;
    idKey = entity.type === 'Famille' ? 'idFamille' : 'id' + entity.type.charAt(0).toUpperCase() + entity.type.slice(1);
    return this.documents.filter(doc => doc.idPieceAFournir === piece.id && doc[idKey] === entity.id);
  }

  openPieceDetailsDialog(piece: GedPiece) {
    const data = {
      piece,
      mandatoryAccueils: piece.accueils.filter(acc => acc.obligatoire),
      optionalAccueils: piece.accueils.filter(acc => !acc.obligatoire)
    };

    const dial = this.matDialog.open(this.pieceDetailsDialog, { data, maxWidth: 600 });

    this.platformService.adaptDialogToScreen(dial);
  }

  checkValidate() {
    let docPieceExist: Array<boolean> = [];
    this.piecesAFournir.forEach(piece => {
      if (piece.pieceJointeObligatoire && !this.getDocumentsFor(piece, this.entite)?.length) {
        docPieceExist.push(false);
      }

      if (!piece.pieceJointeObligatoire) {
        docPieceExist.push(true);
      }

    });
    this.canValidate = docPieceExist.every(doc => doc === true);

  }

  onFileSelect(fileList: FileList, piece: GedPiece) {
    if (fileList.length) {
      this.uploadingFile = fileList[0];
      this.errorMessage = this.gedService.checkDocuments(fileList, this.configGed, this.uploadingFile);
      this.openFileUploadDialog(this.entite, piece);
    }
  }

  openDocDetails(doc: GedDocument) {
    const piece = this.configGed.listePiecesAFournir.find(p => p.id === doc.idPieceAFournir);

    const dialog = this.matDialog.open(DocDetailsComponent, {
      data: { doc, piece, documentDownload: this.documentDownload }
    });

    this.platformService.adaptDialogToScreen(dialog);

    dialog.afterClosed().subscribe(_ => {
      window.URL.revokeObjectURL(this.previewURL);
      this.previewURL = null;
    });
  }

  deleteDocument(doc: GedDocument) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: { message: `Voulez vous supprimer le fichier : ${doc.nomFichier} ?` },
      maxWidth: 500
    });

    this.platformService.adaptDialogToScreen(dialogRef);

    dialogRef.afterClosed().subscribe(closeCode => {

      if (closeCode) {
        this.gedService.delete(doc.idDocumentElectronique).subscribe(res => {
          this.snackbarService.info('Document supprimé');

          // delete locally
          this.deleteLocalDoc(doc.idDocumentElectronique);

        });
      }
    });
  }

  deleteLocalDoc(id: number) {
    this.documents.forEach((x, index) => {
      if (x.idDocumentElectronique === id) {
        this.documents.splice(index, 1);
      }
    });
    this.checkValidate();
  }

  openFileUploadDialog(entite: GedEntity, piece: GedPiece) {
    this.uploadDialogRef = this.matDialog.open(FileUploadDialogComponent, {
      data: {
        entite,
        piece,
        uploadingFile: this.uploadingFile,
        errorMessage: this.errorMessage
      },
      maxWidth: 600,
      minWidth: 200,
    });

    this.platformService.adaptDialogToScreen(this.uploadDialogRef);

    // CANCEL
    this.uploadDialogRef.componentInstance.onCancel.pipe(
      takeUntil(this.uploadDialogRef.afterClosed())
    ).subscribe(result => {
      if (result) {
        this.uploadDialogRef.close();
      }
    })

    // SUBMIT
    this.uploadDialogRef.componentInstance.onSubmit.pipe(
      takeUntil(this.uploadDialogRef.afterClosed())
    ).subscribe(result => {
      if (result) {
        this.uploadDialogRef.close();
        this.gedService.getCurrentFamilyDocuments().subscribe((docs: GedDocument[]) => {
          this.documents = docs.filter(doc => new Date().getTime() < new Date(doc.dateFinValidite).getTime());
          this.checkValidate();
        });
      }
    });

    this.uploadDialogRef.afterClosed().subscribe(_ => {
      this.previewURL = null;
      this.errorMessage = null;
      this.inputFile.map(input => input.nativeElement.value = null);
    });
  }

  onValidate() {
    this.validate.emit(true);
  }

}
