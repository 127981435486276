import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
    standalone: false
})
export class HighlightPipe implements PipeTransform {

  // transform(value: string, search: string): string {
  //   if (!search ) return value;

  //   const regex = new RegExp(search, 'gi');
  //   return value.replace(regex, match => `<span class="highlight">${match}</span>`);
  // }

  transform(value: any, args: any): any {
    if (!args) { return value; }
    var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    return value.replace(re, "<mark>$&</mark>");
  }

}
