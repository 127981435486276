import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { UserService } from '@app/services';
import { Role } from '@app/models/user';

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.scss'],
    standalone: false
})
export class ConfirmEmailComponent implements OnInit {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ConfirmEmailComponent>,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
  }

  sendMailConfirm() {
    const user = this.userService.currentUser;
    if (user && (user.role === Role.User)) {
      this._bottomSheetRef.dismiss();
      this.userService.sendConfirmEmail(user).subscribe();
    }
  }

  cancel() {
    this._bottomSheetRef.dismiss();
  }

}
