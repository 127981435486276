import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '@app/services/menu.service';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { PlatformService } from '@app/services/platform.service';
import { GlobalConfigService } from '@app/services/global-config.service';
import { Router, NavigationEnd } from '@angular/router';
import { distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { routesAnimation } from './route-animation';
import { BannerConfig, HeaderConfig } from '@app/models/global-config';
import { HeaderService } from '@app/services/header.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    animations: routesAnimation,
    standalone: false
})
export class MainComponent implements OnInit, OnDestroy {

  @ViewChild(MatSidenav, { static: true }) sidenav: MatSidenav;
  @ViewChild(MatSidenavContent, { static: true }) mainContent: MatSidenavContent;

  private onDestroy$ = new Subject<void>();

  headerConfig: HeaderConfig;
  banner: BannerConfig;
  inAdmin: boolean;
  isMobile: boolean;

  constructor(
    public menuService: MenuService,
    public platformService: PlatformService,
    private globalConfigService: GlobalConfigService,
    private router: Router,
    private headerService: HeaderService
  ) { }

  ngOnInit() {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => this.isMobile = value);

    this.menuService.sidenav = this.sidenav;
    this.menuService.mainContent = this.mainContent;

    this.updateBannerOnChange();
    this.headerService.currentBanner$.subscribe(b => this.banner = b);

    this.router.events.pipe(filter(e => e instanceof NavigationEnd), startWith(true)).subscribe(() => {
      this.inAdmin = this.router.url?.startsWith('/admin');
    });
  }

  updateBannerOnChange() {
    merge(
      // @TODO: add a method in configService to listen for "config change", needed for preview / live refresh when admin updates one
      this.globalConfigService.getPart<HeaderConfig>('header').pipe(tap(conf => this.headerConfig = conf)),
      // @NB: maybe try something else, NavigationEnd happens a bit late (banner should appear even when page content loads)
      this.router.events.pipe(filter(e => e instanceof NavigationEnd))
    ).subscribe(_ => {
      // @TODO: update banner according to config & current route
      const currentUrl = this.router.url;

      // const banner = (this.headerConfig.banners || []).find(b => b.pages.includes(currentUrl));
      const banner = currentUrl.startsWith('/admin') ? null : this.headerConfig.banner;

      this.headerService.setBanner(banner);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
