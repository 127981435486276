import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GedDocument, GedPiece } from '@app/models/ged';
import { MandatSepa } from '@app/models/mandat-sepa';
import { GedService } from '@app/services/ged.service';

@Component({
    selector: 'app-doc-details',
    templateUrl: './doc-details.component.html',
    styleUrls: ['./doc-details.component.scss'],
    standalone: false
})
export class DocDetailsComponent implements OnInit {

  piece: GedPiece;
  doc: GedDocument;
  mandat: MandatSepa;
  documentDownload: { [doc: number]: number } = {};
  previewURL: any;

  loadingPreview: boolean;
  error: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private gedService: GedService,
    private sanitizer: DomSanitizer,
  ) {
    this.piece = data.piece;
    this.doc = data.doc;
    this.mandat = data.mandat;
    this.documentDownload = data.documentDownload;
  }

  ngOnInit(): void {
    //   // If preview possible, load file from API ...
    const fileExtension = this.gedService.getExtension(this.doc.nomFichier);
    if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'pdf'].includes(fileExtension)) {
      this.loadingPreview = true;
      this.gedService.getDocument(this.doc.idDocumentElectronique).subscribe(resp => {
        if (resp.type === 'response') {
          if (fileExtension === 'pdf') {
            (resp.value as Blob).arrayBuffer().then(buf => this.previewURL = buf);
          } else {
            this.previewURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(resp.value));
          }

          this.loadingPreview = false;
        }
      }, err => {
        this.error = err;
        this.loadingPreview = false;
      });
    }
  }

  downloadDocument() {
    this.gedService.downloadDocument(this.doc, this.documentDownload);
  }

}
