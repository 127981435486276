import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { tap, map, mergeMap, catchError } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { MatFormFieldAppearance } from '@angular/material/form-field';
// import { SassTemplate } from '@app/components/_admin/theme/theme-customizer/theme-template';
import themeDefinitionDefault from '../../theme/theme-definition-defaut.json';
import { BaseConfigService, loadConf } from './config/base.service';

interface ThemeCompileResponse {
  css: string;
  saved?: boolean;  // Only for save response

}

export interface ColorPalette {
  name: string;
  shade?: string;
  shadeLight?: string;
  shadeDark?: string;
  hexNormal: string;
  hexLight: string;
  hexDark: string;
  contrastNormal?: string;
  contrastLight?: string;
  contrastDark?: string;
  shades: { contrast: {} };
  variantAuto: boolean;
  contrastAuto: boolean;
}

export interface ThemeDefinition {
  primaryPalette: ColorPalette;
  accentPalette: ColorPalette;
  warnPalette: ColorPalette;
  brightness: 'dark' | 'light' | 'custom' | 'light-dark';
  customBrightness: {
    isDark: boolean;
    backgroundColor: string;
    textColor: string;
  };
  formFieldAppearance: MatFormFieldAppearance;
  fontFamily: string;
  darkModeSwitch: boolean;
  buttonRadius: number;
}

const storageKey = 'custom-theme';

@Injectable({ providedIn: 'root' })
export class ThemeService {

  static COMMON_COLORS = {
    darkPrimaryText: 'rgba(black, 0.87)',
    lightPrimaryText: 'white'
  };

  // For the run
  public formFieldAppearance$: BehaviorSubject<MatFormFieldAppearance> = new BehaviorSubject<MatFormFieldAppearance>('outline');

  // For customizer
  compilerIsReday: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  themeDefinition: ThemeDefinition;
  themeDefinitionDefault: ThemeDefinition;
  themeScss: string;
  lastThemeDef: ThemeDefinition;
  themeDefUnsaved: boolean;

  darkMode$ = new BehaviorSubject(false);
  isCustom$ = new BehaviorSubject(false);

  darkModeSwitchEnabled$ = new BehaviorSubject(false);

  themeBrightnessClass$: Observable<string> = this.darkMode$.pipe(
    map(darkMode => darkMode ? 'dark-theme' : 'light-theme'
    ));
  customOrDefaultClass$: Observable<string> = this.isCustom$.pipe(
    map(isCustom => isCustom ? 'custom-theme' : 'default-theme'),
  );


  constructor(
    overlayContainer: OverlayContainer,
    private http: HttpClient,
    private baseConfigService: BaseConfigService
  ) {
    const overlayClassList = overlayContainer.getContainerElement().classList;
    overlayClassList.add('theme-wrapper');

    this.customOrDefaultClass$.subscribe(defaultOrCustomClass => {
      overlayClassList.remove('custom-theme', 'default-theme');
      overlayClassList.add(defaultOrCustomClass);
    })

    this.themeBrightnessClass$.subscribe(brightnessClass => {
      overlayClassList.remove('dark-theme', 'light-theme');
      overlayClassList.add(brightnessClass);
    })
  }

  loadTheme() {

    let conf = this.baseConfigService.getFirstConf("theme");

    if (conf.content.css) {
      this.applyCustomTheme(conf.content);
    }
    // since this return is useless ... 
    // !! see app.component @line ~124  for details !!
    // return of(conf.content);
  }

  getThemeDefinition(forceRefresh?: boolean): Observable<ThemeDefinition> {
    if (forceRefresh && this.lastThemeDef) { return of(JSON.parse(JSON.stringify(this.lastThemeDef))); }
    if (this.themeDefinition && !forceRefresh) { return of(this.themeDefinition); }

    return this.http.get<ThemeDefinition>('/conf/theme-definition').pipe(
      mergeMap(themeDef => {
        if (!themeDef) {
          return this.getThemeDefinitionDefault();
        } else {
          return of(themeDef);
        }
      }),
      tap(themeDef => {
        this.lastThemeDef = JSON.parse(JSON.stringify(themeDef));
        this.themeDefinition = themeDef;
      })
    );
  }

  getThemeDefinitionDefault(): Observable<ThemeDefinition> {
    let obs: Observable<ThemeDefinition>;

    if (this.themeDefinitionDefault) {
      obs = of(this.themeDefinitionDefault);
    } else {
      obs = of(themeDefinitionDefault as any).pipe(
        tap(themeDef => this.themeDefinitionDefault = themeDef)
      );
    }

    return obs.pipe(
      map(themeDef => {
        return JSON.parse(JSON.stringify(themeDef));
      })
    );
  }

  switchDarkMode() {
    this.darkMode$.next(!this.darkMode$.value);
    this.saveDarkModeInStorage(this.darkMode$.value);
  }

  applyCSS(css: string) {
    const styleTag = document.querySelector('style#custom-theme');

    if (styleTag) {
      styleTag.innerHTML = css;
    } else {
      console.error('Style tag for custom theme not found');
    }
  }

  applyCustomTheme(theme: any) {
    this.applyCSS(theme.css);

    this.formFieldAppearance$.next(theme.formFieldAppearance);
    this.darkModeSwitchEnabled$.next(theme.darkModeSwitch);

    if (theme.fontFamily) {
      this.loadFont(theme.fontFamily);
    }

    this.applySavedDarkMode();
    this.isCustom$.next(true);
  }

  clearCustomTheme() {
    document.querySelector('head style#custom-theme').innerHTML = '';

    this.isCustom$.next(false);
  }

  compileTheme(themeDefinition: ThemeDefinition): Observable<ThemeCompileResponse> {
    this.themeDefUnsaved = true;

    // const themeTemplate = SassTemplate.ThemeVariablesCustomizerTemplate(themeDefinition);
    // console.log(themeTemplate)
    // return this.http.post<ThemeCompileResponse>('/conf/theme/compile', { template: themeTemplate });
    return this.http.post<ThemeCompileResponse>('/conf/theme/compile', { themeDefinition });

  }

  saveTheme(themeDefinition: ThemeDefinition): Observable<ThemeCompileResponse> {
    return this.http.put<ThemeCompileResponse>('/conf/theme', { themeDefinition }).pipe(
      tap((res) => {
        this.themeDefUnsaved = false;
        this.lastThemeDef = JSON.parse(JSON.stringify(themeDefinition));
      }),
      catchError(err => {
        console.error('Erreur lors de l\'enregistrement du thème', err);
        return throwError(() => new Error(err));
      })
    );
  }

  // Very basic for now, improve it later if gets really used ...
  // (we need at least an array of loaded fonts, to avoid duplicate "<link>" to same font)
  loadFont(name: string) {
    name = name.replace(/[\s]+/g, '+');
    // @NB: caution with this, changing the page header seems to make the browser "blink" (probably to refresh style render)
    // => might cause some style to reset / or even disappear ...
    document.head.innerHTML += `<link href="https://fonts.googleapis.com/css?family=${name}:300,400,500" rel="stylesheet">`;

    // On charge les polices depuis Google au moment où on en a besoin (toutes ici pour les afficher)
    // @TODO: faire un truc pour charger la police sélectionnée dans le thème ...
    // document.head.innerHTML += `
    //   <link rel="preconnect" href="https://fonts.googleapis.com">
    //   <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    // `;

    // const chainedFamilies = this.fontChoices.map(f => 'family=' + f.replace(/[\s]+/g, '+') + ':wght@300;400;500').join('&');

    // const importFontsTag = document.createElement('link');
    // importFontsTag.setAttribute('rel', 'stylesheet');
    // importFontsTag.setAttribute('href', 'https://fonts.googleapis.com/css2?' + chainedFamilies);

    // document.head.appendChild(importFontsTag);

  }

  saveDarkModeInStorage(dark: boolean) {
    localStorage.setItem('is-dark-mode', JSON.stringify(dark));
  }

  applySavedDarkMode() {
    const isDarkMode = JSON.parse(localStorage.getItem('is-dark-mode'));

    if (isDarkMode) {
      this.darkMode$.next(true);
    }
  }

  getThemeFromStorage() {
    const storedCustomThemeJSON = localStorage.getItem(storageKey);

    try {
      return storedCustomThemeJSON ? JSON.parse(storedCustomThemeJSON) : {};
    } catch (error) {
      return {};
    }
  }
}
