import { Component } from '@angular/core';

@Component({
    selector: 'app-planning-legend',
    templateUrl: './legende-icons.component.html',
    styleUrls: ['./legende-icons.component.scss'],
    standalone: false
})
export class LegendeIconsComponent {
  statuses = ['waiting', 'canceling', 'canceled', 'denied', 'accepted', 'expired', 'expired_canceling', 'other_account'];
}
