import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService, UserService } from '@app/services';
import { MessagerieService } from '@app/services/messagerie.service';
import { Subject } from 'rxjs';
import { NewMessageComponent } from './new-message/new-message.component';
import { EtablissementService } from '@app/services/etablissement.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Messagerie } from '@app/models/message';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseConfigService } from '@app/services/config/base.service';
import { BoardConfig } from '@app/models/board-card';

@Component({
    selector: 'app-messagerie',
    templateUrl: './messagerie.component.html',
    styleUrls: ['./messagerie.component.scss'],
    animations: [
        trigger('fadeInLeft', [
            state('void', style({ opacity: 0, transform: 'translateX(-5%)' })),
            state('*', style({ opacity: 1, transform: 'translateX(0)' })),
            transition(':enter', animate('300ms ease-out')),
            transition(':leave', animate('150ms ease-in')),
        ]),
    ],
    standalone: false
})

export class MessagerieComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('chatArea', { static: false }) chatArea: ElementRef;
  @Input() selectMode: string | boolean;
  dataSource: MatTableDataSource<Messagerie>;
  total: any;
  message: Messagerie
  etablissements = [];
  onDestroy$ = new Subject<void>();
  isLoading = true;
  displayedColumns = ['objetMessage', 'contenu'];
  destroyed = new Subject<void>();
  currentScreenSize: string;
  isSmallScreen = false;
  offset: number = 0;
  perPage: number = 10;
  search = "";
  isSearching = false;
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'Small'],
  ]);
  userId: number;
  selectedRow: any;
  rows: any[] = [];
  timer: any;
  isMessagesLoaded = false;
  isAnimationTriggered = false;
  shouldScrollToBottom = true;
  config: BoardConfig;
  etablissementsUser = [];
  selectedEtablissementId = null;

  constructor(
    private messagerieService: MessagerieService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private userService: UserService,
    public etablissementservice: EtablissementService,
    breakpointObserver: BreakpointObserver,
    private baseConfigService: BaseConfigService,
  ) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        let currentScreenSize = 'Unknown';
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
          }
        }
        this.isSmallScreen = currentScreenSize == 'Small';
      });
  }

  ngOnInit() {
    this.etablissementservice.getAllEtablissement().subscribe(res => {
      this.etablissements = res;
      this.getMessages();
    })
    this.config = this.baseConfigService.getFirstConf("board-user").content as BoardConfig;
    this.etablissementsUser = this.config.cards.filter(item => item.component === 'messagerie').map(item => item.selectedEtablissementsUser);
  }

  getMessages() {
    this.isMessagesLoaded = false;
    this.messagerieService.getMessages(this.offset, this.perPage, this.search, this.selectedEtablissementId).subscribe(res => {
      this.total = res.total;
      this.isSearching == !!this.search;
      res.messages.forEach(message => {
        message.etablissements = this.etablissements.filter(etb => message._etablissements.includes(etb.idEtablissement));
        message.newResponseCounts = this.countNewResponses(message);
      });
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(res.messages);
      this.dataSource.sort = this.sort;
      this.isMessagesLoaded = true;
      this.userId = this.userService.currentUser.id;
      this.message = res.messages.find(msg => this.message && msg.idPortailMessage == this.message.idPortailMessage) || this.message;
    })
  }

  countNewResponses(msg) {
    return msg._responses.reduce((count, resp) => count + this.countNewResponses(resp), msg.idEtablissementExpediteur && !msg.messageLu)
  }

  handlePageEvent(e: PageEvent) {
    this.perPage = e.pageSize;
    this.offset = e.pageIndex * this.perPage;
    this.isMessagesLoaded = false;
    this.getMessages();
  }

  createImageDataUrl(data: string): string {
    return data;
  }

  markMessageAsRead(idMessage: number) {
    this.messagerieService.markMessageAsRead(idMessage).subscribe(res => {
      this.message.newResponseCounts = 0
    });
  }

  showDetails(message) {
    this.isAnimationTriggered = false;
    this.shouldScrollToBottom = true;
    setTimeout(() => {
      this.isAnimationTriggered = true;
      this.message = message;
      this.shouldScrollToBottom = false;
      const markMessageAndResponsesAsRead = (msg) => {
        if (msg.idEtablissementExpediteur && !msg.messageLu) {
          this.markMessageAsRead(msg.idPortailMessage);
        }
        if (msg._responses && msg._responses.length > 0) {
          for (const response of msg._responses) {
            markMessageAndResponsesAsRead(response);
          }
        }
      };
      if (message.newResponseCounts) markMessageAndResponsesAsRead(message);
      setTimeout(() => {
        this.scrollToBottom();
      });
    }, 400);
  }

  private scrollToBottom() {
    if (this.chatArea && this.chatArea.nativeElement) {
      const chatAreaEl: HTMLElement = this.chatArea.nativeElement;
      chatAreaEl.scrollTop = chatAreaEl.scrollHeight;
    }
  }

  addMessage(msg?: null) {
    const dialogRef = this.dialog.open(NewMessageComponent, {
      data: {
        etablissements: this.etablissements,
        etablissementsUser: this.etablissementsUser,
        idMessageParent0: this.message ? this.message.idPortailMessage : null,
        message: msg,
      },

      disableClose: true,
      maxWidth: '700px',
      width: this.isSmallScreen ? '90%' : '50%',
      minHeight: '500px'
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getMessages();
      }
    })
  }

  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.isSearching = true;
    this.isMessagesLoaded = false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getMessages();
    }, 2000)
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  close() {
    this.isAnimationTriggered = false;
    setTimeout(() => {
      this.message = null;
      this.isAnimationTriggered = true;
    }, 300);
  }
}
