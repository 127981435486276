import { Component, OnInit, OnDestroy } from '@angular/core';
import { FacturationService } from '@app/services/facturation.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RegieData } from '@app/models/facturation';
import { BoardConfigService, FamilyService } from '@app/services';
import { filter, takeUntil, switchMap } from 'rxjs/operators';
import { FlashService } from '@app/services/flash.service';

@Component({
    selector: 'app-user-factures',
    templateUrl: './facture.component.html',
    styleUrls: ['./facture.component.scss'],
    standalone: false
})
export class FactureComponent implements OnInit, OnDestroy {

  data: RegieData[];
  facturesToPayByRegie: { [key: number]: number } = {};

  paymentReturnData: object;
  dismiss: boolean;

  expanded: number;

  onDestroy$ = new Subject<void>();

  constructor(
    private facturationService: FacturationService,
    private boardConfigService: BoardConfigService,
    private familyService: FamilyService,
    private flashService: FlashService,
    public router: Router
  ) { }

  ngOnInit() {
    this.paymentReturnData = this.flashService.getFlash('payment_return_data', true);

    this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      switchMap(f => this.facturationService.getFamilyFacturationData(f.id))
    ).subscribe(data => {
      this.data = data;
      this.data.forEach(row => {
        if (row.regie.afficheEtatFacture) {
          const countToPay = row.factures.filter(f => f.status === 'EnAttenteDePaiement').length;

          this.facturesToPayByRegie[row.regie.id] = countToPay;

          if (countToPay && !(this.expanded || this.expanded === 0)) {
            this.expanded = row.regie.id;
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
