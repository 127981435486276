import { Injectable } from '@angular/core';
import { StepType, ExtendedFormlyFieldConfig } from '@app/forms/config/form-model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { tap, map, catchError, distinctUntilChanged } from 'rxjs/operators';
import { FormatterService } from './formatter.service';
import { SnackbarService } from './snackbar.service';
import { FormConfigAdapterService } from './adapter/form-config-adapter.service';
import { clone } from '@app/utils/object';

@Injectable({ providedIn: 'root' })
export class FormConfigService {

  apiUrl = '/conf/formulaire/';
  apiUrlAdmin = '/conf/formulaire-admin/';


  constructor(
    private http: HttpClient,
    private formatter: FormatterService,
    private snackbarService: SnackbarService,
    private formConfigAdapter: FormConfigAdapterService
  ) { }

  getForm(name: string, part: string = '', adminMode = false): Observable<StepType[]> {
    // @TODO: re enable cache (or SW) with handle or "part" mode ...
    // const fromCache = this.cache.getItem(name);

    // if (fromCache) {
    //   return of(fromCache);
    // }

    const url = (adminMode ? this.apiUrlAdmin : this.apiUrl) + name + (part ? '/' + part : '');

    return this.http.get(url).pipe(
      map(config => this.formConfigAdapter.adapt(config, name)))
  }

  updateFormFieldsConfig(name: string, formFieldsConfig) {

    const parsedConfig = this.formConfigAdapter.reverse(formFieldsConfig, name);

    return this.http.post(this.apiUrlAdmin + name, { formFieldsConfig: parsedConfig }).pipe(
      tap(() => {
        this.snackbarService.open({ message: 'Formulaire enregistré' });
      }),
      catchError(err => {
        console.error('Erreur lors de l\'enregistrement du formulaire (' + name + ')', err);
        return throwError(err);
      })
    );
  }

  // parseAllFields(fields, func, ...args) {
  //   for (const field of fields) {
  //     if (field.fields) { field.fields = this.parseAllFields(field.fields, func, ...args); }
  //     if (field.fieldGroup) { field.fieldGroup = this.parseAllFields(field.fieldGroup, func, ...args); }
  //     if (field.fieldArray) { field.fieldArray.fieldGroup = this.parseAllFields(field.fieldArray.fieldGroup, func, ...args); }

  //     func(field, ...args);
  //   }
  //   return fields;
  // }

  parseAllFields(fields, func) {
    for (const field of fields) {
      func(field);
      if (field.fields) { field.fields = this.parseAllFields(field.fields, func); }
      if (field.fieldGroup) { field.fieldGroup = this.parseAllFields(field.fieldGroup, func); }
      if (field.fieldArray) { field.fieldArray.fieldGroup = this.parseAllFields(field.fieldArray.fieldGroup, func); }

    }
    return fields;
  }


  /**
   * Build form view from given model
   * If data object is given and field with matching key is found, will set defaultValue accordingly
   */
  getFormView(modelFields: ExtendedFormlyFieldConfig[] | StepType[], adminMode = false) {
    const formView = clone(modelFields);
    let currentStep = null;

    this.parseAllFields(formView, (field: ExtendedFormlyFieldConfig) => {

      if (field.stepName) {
        currentStep = field;
      }

      if (field.format) {
        setTimeout(() => this.applyFormatter(field));
      }

      if (field.hide === undefined) {
        field.hide = false;
      }

      if (adminMode) { // not necessary in "normal mode" because the API already remove hidden options when not admin
        if (field.templateOptions && field.templateOptions.options && field.availablesOptions) {
          field.templateOptions.options = (field.templateOptions.options as any[])
            .filter(opt => field.availablesOptions.includes(opt.value));
        }
      }

      if (!field.requiredInderterminate) {
        if (field.templateOptions && field.templateOptions.required === undefined) {
          field.templateOptions.required = false;
        }
        if (field.expressionProperties && field.expressionProperties['templateOptions.required']) {
          delete field.expressionProperties['templateOptions.required'];
        }
      }

      if (!field.readOnlyIndeterminate) {
        if (field.templateOptions && field.templateOptions.disabled === undefined) {
          field.templateOptions.disabled = false;
        }

        if (field.expressionProperties && field.expressionProperties['templateOptions.disabled']) {
          delete field.expressionProperties['templateOptions.disabled'];
        }
      }

      if (!adminMode) { // defaultValue can contain examples for admin mode (do not keep it for "normal" mode)
        if (field.type === 'datatable' || field.type === 'suggestion' && field.defaultValue) {
          delete field.defaultValue;
        }
      }


      // Select depend on another select
      if (field.type === 'select') {
        if (field.dependOn && currentStep) {
          currentStep.selectOptionsData = currentStep.selectOptionsData ? currentStep.selectOptionsData : {};
          currentStep.selectOptionsData[field.key as string] = field.templateOptions.options;

          if (this.findFieldByName(currentStep.fields, field.dependOn.field)) {
            field.expressionProperties = field.expressionProperties ? field.expressionProperties : {};

            field.expressionProperties['templateOptions.options'] =
              `formState.selectOptionsData.${field.key}.filter(data => data?.${field.dependOn.key} === model?.${field.dependOn.field})`;

            field.expressionProperties['model.' + field.key] =
              `field.templateOptions.options.find(o => o.value === model?.${field.key}) ? model?.${field.key}:null`;
          }
        }
      }

      if (!field.className) {
        field.className = field.type === 'hidden' ? 'hidden' : 'flex-0';
      }
    });

    return formView;
  }


  filterOptions(baseList: any[], filterList: string[], considerEmptyFull = true) {
    if (considerEmptyFull && (!filterList || !filterList.length)) {
      return baseList;
    }

    // In pipe if options is Observable
    return baseList.filter(opt => filterList.includes(opt.value));
  }

  applyFormatter(field: ExtendedFormlyFieldConfig) {
    if (!field.formControl) {
      return;
    }

    // We store every valueChanges subscriptions on the field, and here we cancel old ones
    if (field.subs) {
      field.subs.forEach((oldSub: Subscription) => oldSub.unsubscribe());
    }

    if (!field.format) {
      return;
    }

    const formatter = this.formatter.getFormatFunction(field.format);

    if (!formatter) {
      console.warn('Unknown format: ', field.format);
      return;
    }

    const sub = field.formControl.valueChanges.pipe(
      distinctUntilChanged()
    )
      .subscribe(val => {
        if (val && typeof val === 'string') {
          field.formControl.setValue(formatter(val, field.formatOptions), { onlySelf: true });
        }
      });

    field.subs = [...field.subs || [], ...[sub]];
  }

  findFieldByName(fields: ExtendedFormlyFieldConfig[], key: string): ExtendedFormlyFieldConfig {
    let found = null;

    if (fields?.length) {
      for (const field of fields) {

        if (typeof field === 'object') {
          if ('key' in field && field.key === key) {
            return field;
          }
          /* eslint-disable no-cond-assign */
          if ('fields' in field && (found = this.findFieldByName(field.fields, key))) {
            return found;
          }

          if ('fieldGroup' in field && (found = this.findFieldByName(field.fieldGroup, key))) {
            return found;
          }

          if ('fieldArray' in field && (found = this.findFieldByName(field.fieldArray.fieldGroup, key))) {
            return found;
          }
        }
      }
    }


    return null;
  }

  findStep(fields: ExtendedFormlyFieldConfig[], step: string) {
    return fields.find(f => f.stepName === step);
  }

  getKeysListOfFormSteps(form: StepType[]): string[] {
    let listKeysFields = [];
    const formStep: StepType[] = this.getFormView(form).filter(f => f.enabled);
    for (const step of formStep) {
      for (const field of step.fields) {
        for (const fieldGroup of field.fieldGroup) {
          if (fieldGroup.fieldGroup) {
            for (const f of fieldGroup.fieldGroup) {
              listKeysFields.push(f.key)
            }
          } else {
            listKeysFields.push(fieldGroup.key);
          }
        }
      }
    }
    return listKeysFields
  }

  getKeysListOfOneStep(step: StepType): string[] {
    let listKeysFields = [];
    for (const field of step.fields) {
      for (const fieldGroup of field.fieldGroup) {
        if (fieldGroup.fieldGroup) {
          for (const f of fieldGroup.fieldGroup) {
            listKeysFields.push(f.key)
          }
        } else {
          listKeysFields.push(fieldGroup.key);
        }
      }
    }
    return listKeysFields
  }

  findFieldsByType(fields: ExtendedFormlyFieldConfig[], type: string): ExtendedFormlyFieldConfig[] {
    let found = null;
    let typeFields: ExtendedFormlyFieldConfig[] = [];

    for (const field of fields) {
      if (typeof field === 'object') {
        if (field.type === type && 'templateOptions' in field && 'options' in field.templateOptions) {
          typeFields.push(field);
        }

        if ('fields' in field && (found = this.findFieldsByType(field.fields, type))) {
          typeFields.push(...found);
        }

        if ('fieldGroup' in field && (found = this.findFieldsByType(field.fieldGroup, type))) {
          typeFields.push(...found);
        }

        if ('fieldArray' in field && (found = this.findFieldsByType(field.fieldArray.fieldGroup, type))) {
          typeFields.push(...found);
        }
      }
    }

    return typeFields;
  }

  sortOptionsInSelectFields(fields: ExtendedFormlyFieldConfig[]) {
    for (const field of fields) {
      if (typeof field === 'object') {
        if (field.type === 'select' && 'templateOptions' in field && 'options' in field.templateOptions) {
          if (field.templateOptions?.options) {
            let options = (field.templateOptions.options as Array<any>);
            options.sort((a, b) => {
              return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' });
            });
          }
        }
      }

      if ('fields' in field) {
        this.sortOptionsInSelectFields(field.fields);
      }

      if ('fieldGroup' in field) {
        this.sortOptionsInSelectFields(field.fieldGroup);
      }

      if ('fieldArray' in field) {
        this.sortOptionsInSelectFields(field.fieldArray.fieldGroup);
      }
    }
  }
}

