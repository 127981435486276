import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { radioYesNoOptions } from '@app/services/adapter/form-config-adapter.service';

@Component({
  selector: 'app-form-oui-non-type',
  templateUrl: './form-oui-non-type.component.html',
  styleUrls: ['./form-oui-non-type.component.scss'],
  standalone: false
})
export class FormOuiNonTypeComponent extends FieldType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    this.to.options = radioYesNoOptions;
    this.field.type = 'radio';
    this.field.wrappers = ['form-field'];
    this.props.floatLabel = 'always';
  }
}
