<app-dialog-header title="{{ date|date:'EEEE dd MMMM y'|titlecase }}" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="(platformService.isMobile$ | async)" cdkDragHandle />

<div *ngIf="periode.modeSelection && periode.modeSelection !== 'free' " mat-dialog-content
  class="thin-scrollbar fx-direction-col">
  <div class="new-presence-header">
    <span *ngIf="dispoPlanning">{{'reservation.planning.available_slots'|trans}}</span>
    <span *ngIf="!dispoPlanning">{{'reservation.planning.not_available_slots'|trans}}</span>
  </div>
  <mat-selection-list #slots *ngIf="dispoPlanning" [(ngModel)]="selectedSlotsTab" (ngModelChange)="clickOnSlot($event)"
    style="margin-bottom:10px;">
    <mat-list-option *ngFor="let slot of dispoPlanning.details" [value]="slot">
      {{slot.start}} - {{slot.end}}
    </mat-list-option>
  </mat-selection-list>
</div>

<div *ngIf="periode.modeSelection === 'free' || !periode.modeSelection" mat-dialog-content class="thin-scrollbar">
  <div class="new-presence-header">
    <label>Rubrique : </label>{{ rubrique?.name }}
  </div>

  <div>
    <div class="time-input">
      <label>Heure d'arrivée : </label>
      <app-time-input [(ngModel)]="times.start" [minuteGap]="periode.intervalleMinHoraire" [picker]="true"
        [min]="limits.min" [max]="limits.max" (ngModelChange)="onChangeTime()" (keydown.enter)="validate()">
      </app-time-input>
    </div>

    <div class="time-input">
      <label>Heure de départ : </label>
      <app-time-input [(ngModel)]="times.end" [minuteGap]="periode.intervalleMinHoraire" [picker]="true"
        [min]="limits.min" [max]="limits.max" (ngModelChange)="onChangeTime()" (keydown.enter)="validate()">
      </app-time-input>
    </div>

  </div>
</div>

<mat-error *ngIf="error">
  <span [ngSwitch]="error">
    <span *ngSwitchCase="'etablissement_times_constraint'">
      {{ 'reservation.error_type.etablissement_times_constraint'|trans:limits }}
    </span>
    <span *ngSwitchCase="'minutes_interval'">
      {{ 'reservation.error_type.minutes_interval'|trans:{'interval': periode.intervalleMinHoraire} }}
    </span>
    <span *ngSwitchDefault>{{ ('reservation.error_type.' + error)|trans }}</span>
  </span>
</mat-error>

<div mat-dialog-actions align="right">
  <button *ngIf="!periode.modeSelection || periode.modeSelection === 'free'" mat-raised-button color="primary-lighter"
    (click)="validate()" [disabled]="error">
    Valider
  </button>
  <button *ngIf="periode.modeSelection && periode.modeSelection !== 'free'" mat-raised-button color="primary-lighter"
    (click)="validate()" [disabled]="error || !dispoPlanning || selectedSlotsTab.length < 1">
    Valider
  </button>
</div>