import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Usager } from '@app/models/consumer';
import { DemarcheFormReponse, DemarcheItem, DemarcheStep, ReponseStepQuestion } from '@app/models/demarche';
import { GedEntity } from '@app/models/ged';
import { PermissionService, PlatformService } from '@app/services';
import { DemarcheService } from '@app/services/demarche.service';
import { HeaderService } from '@app/services/header.service';
import { clone } from '@app/utils/object';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-demarche',
  templateUrl: './demarche.component.html',
  styleUrls: ['./demarche.component.scss'],
  standalone: false
})
export class DemarcheComponent implements OnInit {

  demarche: DemarcheItem;
  stepperOrientation: Observable<StepperOrientation>;
  usager: Usager;
  gedEntite: GedEntity;
  addDocumentPermission: boolean;
  showMessageDemarcheCompleted: boolean;
  fromDemarchesList: boolean;
  url: string;

  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(
    private headerService: HeaderService,
    private demarcheService: DemarcheService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    public breakpointObserver: BreakpointObserver,
    private permissionService: PermissionService,
    public platformService: PlatformService
  ) {
    // Configuration de l'orientation du stepper basée sur la largeur de l'écran
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit(): void {
    // Récupération de l'identifiant de la démarche à partir des paramètres de la route
    const id = this.activatedRoute.snapshot.params.idDemarche;
    this.url = this.router.url;

    // Vérification de la permission d'ajouter des documents
    this.addDocumentPermission = this.permissionService.hasPermission('document_add');

    // Chargement des données de la démarche
    this.demarcheService.getDemarcheById(id).subscribe(demarche => {
      if (!demarche) {
        // Si la démarche n'existe pas, rediriger vers la page précedente
        history.back();
      }

      // Cloner les données pour éviter des modifications non intentionnelles
      this.demarche = clone(demarche);

      // Si l'utilisateur peut ajouter des documents, enrichir les étapes
      if (this.addDocumentPermission) {
        const newSteps: DemarcheStep[] = [];
        this.demarche.steps.forEach(step => {
          newSteps.push(step);
          if (step.docPieceStep && step.activePieceAFournir) {
            newSteps.push(step.docPieceStep);
          }
        });
        this.demarche.steps = newSteps;
      }

      // Mise à jour du titre de la page avec le label de la démarche
      this.headerService.setCurrentPageTitle(this.demarche.label);
    });
  }

  // Gestion de la sélection d'un usager
  getUsagerSelected(event: Usager) {
    this.usager = event;
    this.gedEntite = { id: this.usager.id, type: this.usager.type };
  }

  // Ajout d'une étape pour ajouter un formulaire spécifique (Enfant ou Conjoint)
  addStepToCreate(formName: 'Enfant' | 'Conjoint', step: DemarcheStep, index: number) {
    const formStep = step.stepsForCreationMode.find(formStep => formStep.formName === formName);
    this.demarche.steps.splice(index + 1, 0, formStep);

    setTimeout(() => {
      this.stepper.next(); // Passer à l'étape suivante après l'ajout
    });
  }

  // Validation d'une étape avec un formulaire
  onValidateStepForm(event: DemarcheFormReponse, step: DemarcheStep) {
    // Mise à jour des données de l'usager et de l'entité GED
    if (!event.usager) {
      this.usager = {} as Usager;
      this.usager.id = event.id;
      this.usager.type = event.type;
    } else {
      this.usager = event.usager;
    }

    this.gedEntite = { id: event.id, type: event.type };

    // Actions après validation de l'étape
    if (event) {
      this.afterValidateStep();
    }
  }

  // Gestion de la validation d'une question
  onValidateQuestion(event: any, step: DemarcheStep, index: number) {
    const idReponse = event;
    const reponse = step.reponses.find(reponse => reponse.id === idReponse);

    if (idReponse && reponse?.steps.length) {
      this.handleResponseUpdate(reponse);
      this.insertSteps(reponse, step, index);
    } else {
      this.handleNoMoreSteps(index);
    }
  }

  // Mise à jour de la réponse pour ajouter des étapes spécifiques
  handleResponseUpdate(reponse: ReponseStepQuestion) {
    if (this.addDocumentPermission && !reponse.alreadyUpdated) {
      reponse.steps = reponse.steps.flatMap(step =>
        step.docPieceStep && step.activePieceAFournir ? [step, step.docPieceStep] : [step]
      );
      reponse.alreadyUpdated = true;
    }
  }

  // Insertion des étapes liées à une réponse après la réponse donnée
  insertSteps(reponse: ReponseStepQuestion, step: DemarcheStep, index: number) {
    if (this.demarche.steps.length === 1) {
      // Cas particulier où il n'y a qu'une étape
      this.demarche.steps.push(...reponse.steps);

      if (reponse.isLoop) {
        this.demarche.steps.push(step); // Réinsertion de l'étape en cas de boucle
      }

      setTimeout(() => {
        this.stepper.next();
      });
    } else {
      // Cas général : insertion après l'étape actuelle
      this.demarche.steps.splice(index + 1, 0, ...reponse.steps);

      if (reponse.isLoop) {
        const newIndex = index + 1 + reponse.steps.length;
        this.demarche.steps.splice(newIndex, 0, step); // Réinsertion en boucle
      }

      setTimeout(() => {
        this.afterValidateStep();
      });
    }
  }

  // Gestion de la fin des étapes si aucune autre étape n'est disponible
  handleNoMoreSteps(index: number) {
    const hasMoreSteps = this.demarche.steps.length > index + 1;

    if (!hasMoreSteps) {
      if (this.demarche.messageCompletedDemarche) {
        this.showMessageDemarcheCompleted = true;

        if (this.url?.includes('demarches')) {
          this.fromDemarchesList = true;
        }
      } else {
        if (this.url?.includes('demarches')) {
          this.router.navigate([`account`]);
        } else {
          this.location.back();
        }
      }

    } else {
      setTimeout(() => this.stepper.next());
    }
  }

  // Gestion de la validation d'une pièce à fournir
  onValidatePieceAFournir(event, step: DemarcheStep) {
    if (event) {
      this.afterValidateStep();
    }
  }

  // Actions communes après validation d'une étape
  afterValidateStep() {
    if (this.demarche?.steps.length === 1 || this.isLastStep()) {

      if (this.demarche.messageCompletedDemarche) {
        this.showMessageDemarcheCompleted = true;

        if (this.url?.includes('demarches')) {
          this.fromDemarchesList = true;
        }
      } else {
        if (this.url?.includes('demarches')) {
          this.router.navigate([`account`]);
        } else {
          this.location.back();
        }
      }

    } else {
      setTimeout(() => {
        this.stepper.next();
      });
    }
  }

  // Vérification si l'étape actuelle est la dernière
  isLastStep(): boolean {
    if (this.stepper) {
      return this.stepper.selectedIndex === this.stepper.steps.length - 1;
    } else {
      return false;
    }
  }
}
