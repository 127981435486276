import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';
import { Messagerie } from '@app/models/message';
import { PlatformService, SnackbarService } from '@app/services';
import { MessagerieService } from '@app/services/messagerie.service';


@Component({
    selector: 'app-chat-bubble',
    templateUrl: './chat-bubble.component.html',
    styleUrls: ['./chat-bubble.component.scss'],
    standalone: false
})
export class ChatBubbleComponent implements OnInit {

  @Input() message;
  @Input() userId;
  @Input() isClosed;
  @Input() search = "";
  @Input() depth: number = 1;
  @Output() addMessage = new EventEmitter<Messagerie>();
  @ViewChild('documentDetails', { static: true }) documentDetailsDialog: TemplateRef<any>;

  file: any;
  previewURL: any;
  loadingPreview = false;
  documentDownload: { [doc: number]: number } = {};


  constructor(
    private sanitizer: DomSanitizer,
    public platformService: PlatformService,
    private matDialog: MatDialog,
    private messageService: MessagerieService,
    private snackbarService: SnackbarService,
  ) {
  }

  ngOnInit(): void {
  }

  downloadFile(file) {
    this.messageService.getFile(file.idMessagePJ).subscribe(resp => {
      if (resp.type === 'dl_progress') {
        this.documentDownload[file] = resp.value as number;
      } else if (resp.type === 'response') {
        this.openBlob(file, resp.value);
      }
    }, err => this.snackbarService.error('Erreur lors de l\'ouverture du fichier !'));
  }

  openBlob(file, blob: Blob) {
    const blobURL = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobURL;
    link.download = file.nomFichier;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(() => {
      this.documentDownload[file] = null;
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }

  respond(message) {
    if (!message.isClosed) {
      if (this.userId >= 0) { this.addMessage.emit(message) }
    }
  }

  openDocDetails(file) {
    this.loadingPreview = true
    const data = { file, date: this.message.dateHeureEnvoi };
    const dialog = this.matDialog.open(this.documentDetailsDialog, { data });
    this.platformService.adaptDialogToScreen(dialog);
    this.messageService.getFile(file.idMessagePJ).subscribe(res => {
      if (res.type === "response") {
        if (file.type.includes('image')) {
          this.previewURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res.value));
        } else if (file.type.includes('/pdf')) {
          (res.value as Blob).arrayBuffer().then(buf => this.previewURL = buf);
        }
        this.loadingPreview = false
      }
    })
    dialog.afterClosed().subscribe(_ => {
      window.URL.revokeObjectURL(this.previewURL);
      this.previewURL = null;
    });
  }

  getEtabRead() {
    return this.message._readEtablissements.map(etablissement => etablissement.nomEtab)
  }
}


