import { Component, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { News } from '@app/models/news';
import { FamilyService } from '@app/services';
import { NewsService } from '@app/services/news.service';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import SwiperCore, { Pagination, Navigation, SwiperOptions, Autoplay } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
    selector: 'app-home-news',
    templateUrl: './home-news.component.html',
    styleUrls: ['./home-news.component.scss'],
    standalone: false
})
export class HomeNewsComponent implements OnInit, OnDestroy {

  news: News[];
  accountVars: any;

  @Input() categories: any[];
  @Input() nbNewsEnabled: number;
  @Input() newsStyleType: string;
  @Input() isAdmin: boolean;

  swiperConfig: SwiperOptions = {
    autoplay: { pauseOnMouseEnter: true, disableOnInteraction: true },
    pagination: true,
    navigation: true,
    loop: true
  };

  seeMoreLink: string;

  private onDestroy$ = new Subject<void>();

  constructor(
    private newsService: NewsService,
    private familyService: FamilyService
  ) { }

  ngOnInit() {
    this.familyService.currentFamilyChange$.pipe(
      takeUntil(this.onDestroy$),
      switchMap(fam => fam ? this.familyService.getFamilyVariables(fam.id) : of({}))
    ).subscribe((vars: any) => {
      this.accountVars = vars;
      this.loadNews();
    });
  }

  loadNews() {
    this.news = null;

    this.newsService.getNewsByCatOrAll(this.categories).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(news => {
      this.news = news;

      if (!this.isAdmin) {
        this.news = news.filter(news =>
          !news.isPrivate || !news.etablissementAccueil.length || news.accueilsIds?.some(id =>
            this.accountVars.etablissementAccueil?.some((accEtb) => accEtb.accueils.includes(id)))
        )
      }

      // handle max limit
      if (this.nbNewsEnabled && (this.news?.length > this.nbNewsEnabled)) {
        this.news = this.news.slice(0, this.nbNewsEnabled);
        this.seeMoreLink = this.categories?.length === 1 ? ('/news/categories/' + this.categories) : '/news';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isAdmin) {
      if (changes['categories']) {
        if (changes['categories'].currentValue === this.categories) {
          this.loadNews();
        } else {
          this.categories = changes['categories'].currentValue;
          this.loadNews();
        }
      }

      if (changes.nbNewsEnabled) {
        this.loadNews();
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
