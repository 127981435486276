import { Component, OnInit, ViewChild, Input, AfterViewInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PlatformService } from 'src/app/services/platform.service';
import { Reservation } from '@app/models/reservation';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { PlanningData } from '../planning/planning-data';

const tableColumns = ['numDossier', 'creationDate', 'nomReferent', 'etablissement', 'dates', 'etat', 'action'];

@Component({
    selector: 'app-user-reservations-list',
    templateUrl: './reservation-list.component.html',
    styleUrls: ['./reservation-list.component.scss'],
    standalone: false
})
export class ReservationListComponent implements OnInit, AfterViewInit {

  @Input() data: PlanningData;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('reservationDetail') detailDialog: TemplateRef<MatDialog>;

  displayedColumns: string[];

  dataSource: MatTableDataSource<Reservation> = new MatTableDataSource([]);

  constructor(
    private dialog: MatDialog,
    private platformService: PlatformService
  ) { }

  ngOnInit() {

    this.platformService.isSmall$.pipe(
      tap(val => console.log(val)),
      distinctUntilChanged()
    ).subscribe(small => this.updateTableColumns(small))

    if (this.data) {
      this.data.onPresenceUpdate$.subscribe(_ => this.updateTableData());
      if (this.data.reservations) {
        this.updateTableData();
      }
    }
  }

  updateTableColumns(small: boolean) {
    this.displayedColumns = small ? tableColumns.filter(x => x !== 'etablissement') : tableColumns;
  }

  updateTableData() {
    this.dataSource.data = this.data.reservations.filter(r => !r.fromDomino);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDetail(reservation: Reservation) {
    const ref = this.dialog.open(this.detailDialog, { data: { reservation } });
    this.platformService.adaptDialogToScreen(ref);
  }
}
