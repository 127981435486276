<ng-container *ngIf="inDialog; else content">

  <app-dialog-header title="Changement d'adresse email"></app-dialog-header>

  <mat-dialog-content class="thin-scrollbar">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="saving || newEmail.invalid" (click)="onConfirm()">
      <app-loader *ngIf="saving" diameter="18" class="icon-loader"></app-loader>
      <mat-icon *ngIf="!saving">check</mat-icon>
      Valider
    </button>

    <button mat-raised-button color="warn" [disabled]="saving" (click)="onCancel()">
      <mat-icon>close</mat-icon>
      Annuler
    </button>
  </mat-dialog-actions>

</ng-container>

<ng-template #content>

  <div class="fields">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Nouvelle adresse email</mat-label>
      <input matInput [formControl]="newEmail" type="email" required name="new-email" (keyup.enter)="onConfirm()">
      <mat-error>Vous devez saisir une adresse email valide.</mat-error>
    </mat-form-field>
  </div>

</ng-template>