import { Component, Input, OnInit } from '@angular/core';
import { BannerConfig } from '@app/models/global-config';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    standalone: false
})
export class BannerComponent implements OnInit {

  @Input() config: BannerConfig;

  constructor() { }

  ngOnInit(): void {
  }

}
