<mat-card style="max-width: 500px; max-height:300px; margin-inline: auto; margin-top: 20px;">
  <mat-card-content *ngIf="listUsagers;else loader">
    <mat-form-field *ngIf="listUsagers" style="width: 100%;">
      <mat-label *ngIf="step.choiceTypeSelection === 'usager'">{{ 'entity.usager'|trans }}</mat-label>
      <mat-label *ngIf="step.choiceTypeSelection === 'enfant'">Enfant</mat-label>
      <mat-label *ngIf="step.choiceTypeSelection === 'adulte'">Adulte</mat-label>
      <mat-select required panelClass="usager-select" [(ngModel)]="selectedUsager">
        <mat-optgroup *ngIf="step.choiceTypeSelection === 'usager' || step.choiceTypeSelection === 'adulte'">
          {{ 'entity.adults'|trans }}
          <ng-container *ngFor="let usager of listUsagers">
            <mat-option *ngIf="usager.type === 'adulte'" [value]="usager">
              {{ usager.prenom }} {{ usager.nom }}
            </mat-option>
          </ng-container>
        </mat-optgroup>

        <mat-optgroup *ngIf="step.choiceTypeSelection === 'usager' || step.choiceTypeSelection === 'enfant'">
          {{ 'entity.children'|trans }}
          <ng-container *ngFor="let usager of listUsagers">
            <mat-option *ngIf="usager.type === 'enfant'" [value]="usager">
              {{ usager.prenom }} {{ usager.nom }}
              <span *ngIf="usager.age">({{ 'enfant.age'|trans:{age: usager.age.years} }})</span>
            </mat-option>
          </ng-container>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <div class="fx-direction-row fx-center-center fx-gap-5">
      <button *ngIf="permToAddChild && step.choiceTypeSelection !== 'adulte'" mat-stroked-button color="primary"
        (click)="onClickToAdd('Enfant')" class="btn-add" style="width: 100%;">
        <mat-icon style="margin-right: 5px;">add_box</mat-icon><span>Ajouter un Enfant</span>
      </button>
      <button mat-stroked-button color="primary"
        *ngIf="step.choiceTypeSelection !== 'enfant' && !conjointExist && permToEditFoyer"
        (click)="onClickToAdd('Conjoint')" class="btn-add" style="width: 100%;">
        <mat-icon style="margin-right: 5px;">add_box</mat-icon><span>Ajouter un Conjoint</span>
      </button>

    </div>

  </mat-card-content>

  <mat-card-actions class="fx-align-self-center" style="padding-top: 20px;">
    <button mat-raised-button (click)="onValider()" color="primary" [disabled]="!selectedUsager">Valider</button>
  </mat-card-actions>

</mat-card>

<ng-template #loader>
  <app-loader [message]="'Chargement...'" diameter="40" style="margin-top: 16px;"></app-loader>
</ng-template>