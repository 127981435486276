import { Component, OnInit, Input } from '@angular/core';
import { Periode } from '@app/models/periode';
import { Consumer } from '@app/models/consumer';
import { PeriodeService, weekDays } from '@app/services/periode.service';

@Component({
    selector: 'app-periode-details',
    templateUrl: './periode-details.component.html',
    styleUrls: ['./periode-details.component.scss'],
    standalone: false
})
export class PeriodeDetailsComponent implements OnInit {

  @Input() periode: Periode;
  @Input() consumer: Consumer;

  weekDays = weekDays;

  constructor(
    private periodeService: PeriodeService
  ) { }

  ngOnInit() {
  }

}
