import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-hidden-field',
    styles: [``],
    template: /*html*/ ``,
    standalone: false
})


export class FormHiddenFieldComponent extends FieldType {}
