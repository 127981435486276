import { Component, OnInit, Input } from '@angular/core';
import { DemarcheItem } from '@app/models/demarche';
import { Router } from '@angular/router';
import { DemarcheService } from '@app/services/demarche.service';

@Component({
    selector: 'app-demarches-list',
    templateUrl: './demarches-list.component.html',
    styleUrls: ['./demarches-list.component.scss'],
    standalone: false
})
export class DemarchesListComponent implements OnInit {

  isLoading: boolean;
  demarches: DemarcheItem[];

  constructor(
    private route: Router,
    private demarcheService: DemarcheService
  ) { }

  ngOnInit(): void {
    this.demarcheService.getConfig().subscribe(config => {
      this.demarches = config.demarches.filter(demarche => demarche.enabled && demarche.displayed);
    })
  }

  onClickDemarche(demarche: DemarcheItem) {
    this.route.navigate([`account/demarches/${demarche.id}`]);
  }
}
