import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthenticationService, PlatformService } from '@app/services';
import { PermissionService } from '@app/services/permission.service';
import { OpenIdConfig } from '@app/models/authentification-config';
import { Location } from '@angular/common';
import { oidcMessage } from '@app/models/authentification-config'

const storageUsernameKey = 'portail-remember-login';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() homeCard = false;

  loginForm: FormGroup = this.formBuilder.group({
    username: ['', { validators: [Validators.required] }],
    password: ['', { validators: [Validators.required] }],
    remember: [true]
  });

  loginError: string;
  normalLoginLoading = false;
  showPass: boolean = false;

  redirecting: boolean;
  redirectingMessage: string;

  onDestroy$ = new Subject<void>();
  externalAuthConf: OpenIdConfig;
  oidcMessage: oidcMessage = null
  oidcVariablesForMsg

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public authService: AuthenticationService,
    public permService: PermissionService,
    public platformService: PlatformService,
    private location: Location
  ) {
  }

  ngOnInit() {
    this.authService.getExternalAuthConf().pipe(takeUntil(this.onDestroy$))
      .subscribe((conf: OpenIdConfig) => this.externalAuthConf = conf);

    const inputEmail = localStorage.getItem(storageUsernameKey);

    if (inputEmail) {
      this.loginForm.get('username').setValue(inputEmail);
    }

    this.authService.oidcMessage$.subscribe(msg => this.oidcMessage = msg)

    if (this.route.snapshot.queryParamMap.has('redirect') && !!this.authService.currentUserValue) { // simple redirect when already logged (for reload purpose)
      this.authService.redirectAfterLogin(this.authService.currentUserValue, this.route.snapshot)
    } else { // Normal login
      this.loginForm.valueChanges.subscribe(_ => {
        this.loginForm.setErrors(null)
        this.loginError = null;
      })
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  login() {
    this.loginError = null;
    if (this.loginForm.invalid) {
      return;
    }

    this.normalLoginLoading = true;
    this.authService.login(this.loginForm.value)
      .pipe(finalize(() => {
        this.loginForm.markAsPristine();
        this.normalLoginLoading = false;
      }))
      .subscribe(user => {

        if (user && user.token) {
          if (this.loginForm.get('remember').value) {
            localStorage.setItem(storageUsernameKey, this.loginForm.value?.username);
          }
          this.authService.redirectAfterLogin(user, this.route.snapshot)
        }

      }, error => {
        this.loginError = error;
      });
  }

  clearOidcMessage() {
    this.oidcMessage = null;
    this.authService.setOidcMessage(null)
    // this.router.navigate(['/login'])
    this.location.go('/login') // modify url without change route 
  }

}
