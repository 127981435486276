<div *ngIf="previewURL" style="text-align: center;">
  <pdf-viewer *ngIf="extension === 'pdf'" [src]="previewURL" class="pdf-viewer" [render-text]="false"
    [original-size]="false"></pdf-viewer>
  <img *ngIf=" extension !=='pdf'" [src]="previewURL" class="image-viewer">
</div>

<div *ngIf="!previewURL" class="no-preview">
  <app-icon *ngIf="!extension.startsWith('doc')" type='fa' size="100" icon="file-alt">
  </app-icon>
  <app-icon *ngIf="extension.startsWith('doc')" type='fa' size="100" icon="file-word">
  </app-icon>

  <div style="text-align: center;font-size: large;">Visuel non disponible</div>
</div>
