<div class="container">
  <mat-card appearance="raised">
    <h1>
      <mat-card-title>{{'app_name'|trans}}</mat-card-title>
    </h1>
    <mat-card-content>
      <mat-icon>code</mat-icon>
      <span>Version</span> {{version}}
    </mat-card-content>
  </mat-card>
</div>
