<form [formGroup]="totpForm" (ngSubmit)="verify()" class="totp-form">

  <app-dialog-header title="Vérification en 2 étapes" />

  <mat-dialog-content class="thin-scrollbar">


    <div *ngIf="!mustRestart else fatalError" class="main-container">

      <div *ngIf="verifying" class="loader-container">
        <div class="loader-overlay"></div>

        <app-loader size=2 color="primary"></app-loader>
      </div>

      <div class="help-msg" [innerHTML]="authConfig.doubleAuth.msgHelpUtilisation | safe  ">
      </div>

      <mat-form-field class="totp-code-input">
        <mat-label>Code </mat-label>
        <input #codeIntput type="text" matInput formControlName="totpCode" required minlength="6" maxlength="6" size="6"
          aria-label="Saisie du Code TOTP" cdkFocusInitial [appFocusOnShow]="true" (keypress.enter)="verify()">

        <!-- <totp-code-input [(ngModel)]="validationCode" name="test">
                </totp-code-input> -->
        <mat-error>
          {{totpError}}
        </mat-error>
      </mat-form-field>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions>

    <button *ngIf="!mustRestart" type="submit" mat-raised-button color="primary" (click)="verify()"
      [disabled]=" verifying || !totpForm.valid">
      <mat-icon>check</mat-icon>
      Valider
      <!-- <app-loader *ngIf="verifying" size="0.75"></app-loader> -->
    </button>

    <button type="button" mat-raised-button color="warn" (click)="cancel()">
      <mat-icon>close</mat-icon>
      {{ mustRestart ? 'Fermer' : 'Annuler'}}
    </button>

  </mat-dialog-actions>



</form>


<ng-template #fatalError>
  <div class="fatal-error">
    <mat-card-title class="mat-error">
      <mat-icon color="warn" style="float: left; margin-right: 10px;">error</mat-icon>
      <span>Erreur</span>
    </mat-card-title>
    <mat-card-content>
      <pre [innerHTML]="fatalErrorMsg | safe" class="mat-error"></pre>
    </mat-card-content>

  </div>
</ng-template>