<div class="main-container" [ngClass]="{'with-etx-auth':externalAuthIsVisible, 'force-vertical':containerWidth<700}">

  <!-- <ng-container *ngIf="!!externalAuth && !isLoading  &&  !id && actualStep && actualStep.stepName === 'connexion'"> -->
  <ng-container *ngIf="externalAuthIsVisible">

    <div class="external-auth" [ngClass]="'stepper-is-'+stepperOrientation">
      <mat-card appearance="raised">
        <mat-card-content>
          <app-open-id-form (redirecting)="isLoading=$event" (redirectingMessage)="loadingMessage='login.'+$event"
            [from]="'register'">
          </app-open-id-form>
        </mat-card-content>
      </mat-card>

      <div class="choice-separator">
        Ou
      </div>
    </div>
  </ng-container>

  <app-form-viewer *ngIf="!isLoading; else loader" [ngClass]="{'hidden': isSaving}" [readOnly]="readOnly"
    [formFields]="form" [model]="data" (save)="onSave($event)" [formTitle]="formTitle"
    (stepChange)="onStepChange($event)" [typeForm]="typeForm"
    [verticalStepperBeakpoint]="externalAuthIsVisible ? 1000: 800" (containerWidthChange)="containerWidth=$event"
    (stepperOrientationChange)="stepperOrientationChanged($event)">
  </app-form-viewer>
  <ng-container *ngIf="isSaving">
    <ng-container *ngTemplateOutlet="loader"></ng-container>
  </ng-container>

</div>


<ng-template #loader>
  <div style="width: 100%; height: 50vh; display:flex;">
    <app-loader isLoading="true" style="text-align: center;">
      <h3>{{ loadingMessage|trans }}</h3>
    </app-loader>
  </div>
</ng-template>

<div #loginCheckindicator id="loginCheckindicator">
  <app-loader diameter="32" class="" *ngIf="loginCheckState==='loading'"></app-loader>
  <mat-icon color="primary" *ngIf="loginCheckState==='ok'">check_circle</mat-icon>
  <mat-icon color="warn" *ngIf="loginCheckState==='ko'">report_problem</mat-icon>
</div>

<ng-template #loginAlreadyExistQuestion>
  <mat-card-header>
    <mat-card-title class="accent">
      <mat-icon color="accent" style="float:left;">warning</mat-icon>
      Cette adresse e-mail est déjà utilisée
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="mat-subtitle-2 text-center" style="padding:0.5em;">
      Vous avez déjà un compte sur notre site?
    </div>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <mat-list-item (click)="$event.preventDefault();goLogin();bottomSheet.dismiss();" color="primary">
        <span mat-line>Connexion</span>
      </mat-list-item>

      <mat-list-item (click)="$event.preventDefault();goForgotPassword();bottomSheet.dismiss();">
        <span mat-line>Vous avez oublié votre mot de passe ?</span>
      </mat-list-item>

      <mat-list-item (click)="$event.preventDefault();bottomSheet.dismiss();">
        <span mat-line>Continuer avec une autre adresse e-mail </span>
      </mat-list-item>
    </mat-nav-list>
  </mat-card-content>
</ng-template>

<ng-template #updateAddressBottomSheet>

  <div class="fx-direction-row fx-between-center">
    <span></span>
    <span class="primary font-size-title" style="font-weight: bold;">{{'update_address.title_card' | trans}}</span>
    <button mat-icon-button (click)="$event.preventDefault();bottomSheet.dismiss();" matTooltip="Fermer">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-card appearance="outlined">
    <mat-card-content class="fx-center-center">
      <span>{{ data.numRue }} {{ data.rue }}<br></span>
      <span *ngIf="data.rue2">{{ data.rue2 }}<br></span>
      <span *ngIf="data.rue3">{{ data.rue3 }}<br></span>
      <span>{{ data.codePostal }} {{ data.ville }}</span>
    </mat-card-content>
  </mat-card>

  <p>
    <b *ngIf="manyAddressesDisplay">{{'update_address.content_card_addresses' | trans}}</b>
    <b *ngIf="!manyAddressesDisplay">{{'update_address.content_card_only_address' | trans}}</b>
  </p>

  <div class="fx-direction-col" style="margin-bottom: 10px;">

    <mat-card appearance="outlined"
      *ngIf="dataUpdateAddress.familyAddress && !dataUpdateAddress.familyAddressIsSameUserAddress"
      style="margin-bottom: 3px;">
      <mat-card-content class="fx-direction-row fx-wrap fx-between-center">
        <div class="label-checkbox">
          <b>{{ foyerData.civilite }}
            {{ foyerData.nomFamille | uppercase }}
          </b>
          <div>
            <span>{{ dataUpdateAddress.familyAddress.numRue }} {{ dataUpdateAddress.familyAddress.rue }}<br></span>
            <span *ngIf="dataUpdateAddress.familyAddress.rue2">{{ dataUpdateAddress.familyAddress.rue2 }}<br></span>
            <span *ngIf="dataUpdateAddress.familyAddress.rue3">{{ dataUpdateAddress.familyAddress.rue3 }}<br></span>
            {{ dataUpdateAddress.familyAddress.codePostal }} {{ dataUpdateAddress.familyAddress.ville }}
          </div>
        </div>
        <mat-radio-group class="radio-group" [(ngModel)]="updateFamilyChecked">
          <mat-radio-button value='oui'>Oui</mat-radio-button>
          <mat-radio-button value='non'>Non</mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined"
      *ngIf="dataUpdateAddress.conjointAddress && !dataUpdateAddress.conjointAddressIsSameUserAddress">
      <mat-card-content class="fx-direction-row fx-wrap fx-between-center">
        <div class="label-checkbox">
          <b>
            {{ conjointData.civilite }}
            {{ conjointData.nom | uppercase }}
            {{ conjointData.prenom }}
          </b>
          <div>
            <span>{{ dataUpdateAddress.conjointAddress.numRue }} {{ dataUpdateAddress.conjointAddress.rue }}<br></span>
            <span *ngIf="dataUpdateAddress.conjointAddress.rue2">{{ dataUpdateAddress.conjointAddress.rue2 }}<br></span>
            <span *ngIf="dataUpdateAddress.conjointAddress.rue3">{{ dataUpdateAddress.conjointAddress.rue3 }}<br></span>
            {{ dataUpdateAddress.conjointAddress.codePostal }} {{ dataUpdateAddress.conjointAddress.ville }}
          </div>
        </div>
        <mat-radio-group class="radio-group" [(ngModel)]="updateConjointChecked">
          <mat-radio-button value='oui'>Oui</mat-radio-button>
          <mat-radio-button value='non'>Non</mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="fx-direction-row fx-center-center">
    <button mat-raised-button color="accent" (click)="$event.preventDefault(); validUpdate(); bottomSheet.dismiss();"
      [disabled]="!updateFamilyChecked && !updateConjointChecked">Valider</button>
  </div>
</ng-template>
