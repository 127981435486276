import { Injectable } from '@angular/core';
import { NgStyle } from '@angular/common';

export interface CustomStyle extends NgStyle { }

@Injectable({
  providedIn: 'root'
})
export class CustomStyleService {

  themeColorNames = [
    'primary', 'primary-lighter', 'primary-darker',
    'accent', 'accent-lighter', 'accent-darker',
    'warn', 'warn-lighter', 'warn-darker',
  ];

  colorAttrs = ['color', 'backgroundColor', 'borderColor'];

  constructor() { }

  parseCustomColor(color) {
    if (typeof color === 'string' && this.themeColorNames.includes(color.replace('-contrast', ''))) {
      return `var(--${color})`;
    }

    return color;
  }

  parseCustomStyle(customStyle: CustomStyle, include?: string[], exclude?: string[]) {
    const parsed = [];

    if (include?.includes('padding')) {
      include.push('paddingLeft', 'paddingTop', 'paddingRight', 'paddingBottom');
    }

    for (let styleKey in customStyle) {

      if (
        styleKey in customStyle &&
        customStyle[styleKey] &&
        (!exclude || !exclude.includes(styleKey)) &&
        (!include || include.includes(styleKey))
      ) {

        let styleValue = customStyle[styleKey];

        if (this.colorAttrs.includes(styleKey)) {
          styleValue = this.parseCustomColor(styleValue);
        }

        // Just translate camelCase ngStyle attributes to correct CSS style name case. ie: "borderColor" => "border-color"
        styleKey = styleKey.replace(/([a-z][A-Z])/g, m => m[0] + '-' + m[1].toLowerCase());

        parsed.push(styleKey + ': ' + styleValue);
      }
    }

    return parsed.join('; ');
  }

  // Based on this method https://martin.ankerl.com/2009/12/09/how-to-create-random-colors-programmatically/
  getRandomColors(count?: number, hexaFormat = false): number[][] | string[] {
    if (count === 0) {
      return [];
    }

    let h = Math.random();
    const goldenRatioConjugate = 0.618033988749895;
    const colors: any[][] = [];

    for (let i = 0; i < count; i++) {
      h += goldenRatioConjugate;
      h %= 1;

      colors.push(this.hsvToRgb(h, 0.7, 0.95));
    }

    if (hexaFormat) {
      return colors.map(color => '#' + color.map(t => Number(t).toString(16)).join(''));
    }

    return colors;
  }

  hsvToRgb(h, s, v): number[] {
    const hi = Math.floor(h * 6);
    const f = h * 6 - hi;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    const finalize = (r, g, b) => [Math.floor(r * 256), Math.floor(g * 256), Math.floor(b * 256)];

    switch (hi) {
      case 0: return finalize(v, t, p);
      case 1: return finalize(q, v, p);
      case 2: return finalize(p, v, t);
      case 3: return finalize(p, q, v);
      case 4: return finalize(t, p, v);
      case 5: return finalize(v, p, q);
    }

    return;
  }
}
