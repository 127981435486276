<div class="sticky-action-bar mat-elevation-z1" [style.left.px]="marginLeft" [class.loaded]="loaded">
  <div *ngIf="(platformService.isMobile$ | async) === false">
    <button class="reply" mat-icon-button color='primary' matTooltip="Retour" (click)="onBack()">
      <mat-icon color="primary">arrow_back</mat-icon>
    </button>
  </div>

  <h2 class="title">
    <mat-icon *ngIf="inAdmin">settings</mat-icon>
    <span [innerHTML]="title|trans "></span>
  </h2>

  <div class="buttons">
    <ng-content></ng-content>

    <button *ngIf="!noSave" [disabled]="saving || disabledSave" color="accent" mat-raised-button
      aria-label="Enregistrer" matTooltip="Enregistrer" (click)="save.emit()">
      <span class="fx-direction-row fx-align-item-center">
        <mat-icon *ngIf="!saving">save</mat-icon>
        <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24"
          class="icon-loader spinner">
        </mat-progress-spinner>
      </span>
    </button>
  </div>
</div>