import { Component, OnInit, Input, Inject, Optional } from '@angular/core';
import { News } from '@app/models/news';
import { NewsService } from '@app/services/news.service';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlatformService } from '@app/services';

@Component({
    selector: 'app-single-news',
    templateUrl: './single-news.component.html',
    styleUrls: ['./single-news.component.scss'],
    standalone: false
})
export class SingleNewsComponent implements OnInit {

  @Input() news: News;
  id: number;
  inDialog = false;

  constructor(
    private newsService: NewsService,
    private route: ActivatedRoute,
    public platformService: PlatformService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');

    if (this.data && this.data.newsId) {
      this.inDialog = true;
      this.newsService.get(this.data.newsId).subscribe(news => this.news = news);
    } else this.newsService.get(this.id).subscribe(news => this.news = news);

  }
}
