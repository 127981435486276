import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PlatformService, AuthenticationService, SnackbarService } from '@app/services';
import { FormBuilder, Validators } from '@angular/forms';
import { delay, take } from 'rxjs/operators';
import { User } from '@app/models/user';
import { AuthentificationConfig } from '@app/models/authentification-config';
import { TotpCodeInput } from '@app/components/_elements/totp-code-input/totp-code-input.component';
import { MatTooltip } from '@angular/material/tooltip';
import { QRCodeComponent } from 'angularx-qrcode';


type Step = 'waitingForKey' | 'waitingForPassword' | 'waitingForTotpCode' | 'totpActivation';

interface ActivationData {
  totpKey: string,
  totpURL: string,
  serviceName: string
  userLogin: string
}

@Component({
    selector: 'app-totp-manage',
    templateUrl: './totp-manage.component.html',
    styleUrls: ['./totp-manage.component.scss'],
    standalone: false,
})
export class TotpManageComponent implements OnInit {

  user: User;
  authConfig: AuthentificationConfig;

  activationData: ActivationData;

  step: Step = null;
  waitingMessage: string = 'Chargement ...';

  passwordForm = this.formBuilder.group({
    password: ['', [Validators.required]],
  });
  @ViewChild("passwordInput") passwordInput: ElementRef;
  passwordError: string = null;

  activationForm = this.formBuilder.group({
    activationCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
  });
  @ViewChild("codeIntput") activationCodeInput: ElementRef;
  activationError: string = null;

  // For reset, because we must check actual TOTP before ...
  totpChecked = false;
  mustRestart = false;
  fatalErrorMsg: string;

  @ViewChild("keyDisplayTooltip") keyDisplayTooltip: MatTooltip;

  constructor(
    private authService: AuthenticationService,
    public platformService: PlatformService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {

    this.authService.currentUser$.pipe(take(1)).subscribe(u => {
      this.user = u

    });
    this.authService.getConf().subscribe((conf: AuthentificationConfig) => this.authConfig = conf || ({} as AuthentificationConfig));

    this.activationForm.valueChanges.subscribe(_ => {
      this.activationForm.setErrors(null)
    })

  }

  getKey() {

    //  When already activated, we must first ask for TOTP code before let user reset a new TOTP
    if (this.user.totpEnrolled && !this.totpChecked) {
      this.authService.TotpInitVerify('resetTOTP').subscribe(res => {
        if (res.totpVerified) {
          this.totpChecked = true;
          this.getKey()
        } else {
          this.totpChecked = false;
        }
      },
        err => {
          this.totpChecked = false;
        })
      return
    }

    // Next, we ask user for his password, unless he comes from OIDC.
    if (!this.user.fromOidc && this.step != 'waitingForPassword') {
      this.step = 'waitingForPassword'
      return
    }

    // Now we can get the key
    this.activationError = null;
    this.passwordError = null;
    this.passwordForm.disable()
    const passwordInput = this.passwordForm.get('password')

    this.step = 'waitingForKey';
    this.waitingMessage = 'Récupération de la clé ...'

    this.authService.TotpGetKey(passwordInput.value).pipe(
      // delay(1000)
    ).subscribe(res => {
      this.passwordForm.enable()

      if (res.error) {
        if (res.mustRestart) {
          this.cancel()
          this.fatalErrorMsg = res.error
          this.mustRestart = true;


        } else {

          this.passwordError = res.error
          this.step = 'waitingForPassword';
          passwordInput.setErrors({ wrongPassword: true })
          passwordInput.markAsTouched()
          this.passwordInput.nativeElement.focus()

        }
      } else if (res.activationData) {
        this.activationData = {
          totpKey: res.activationData.key,
          totpURL: res.activationData.url || res.activationData.URL,
          serviceName: res.activationData.serviceName,
          userLogin: res.activationData.userLogin
        }
        this.step = 'waitingForTotpCode';
        console.log('activationData : ', this.activationData)
      }
    }, err => {
      console.error('Erreur lors de la récupération de la clé : ', err)
      this.cancel();
    })
  }

  activate() {
    this.activationError = null;

    // Activation
    this.step = 'totpActivation';
    this.waitingMessage = 'Activation en cours ...'
    this.activationForm.disable()
    const codeInput = this.activationForm.get('activationCode')
    this.authService.TotpActivate(codeInput.value).pipe(
      // delay(5000)
    ).subscribe(res => {
      this.activationForm.enable()

      if (res.errorMsg) {
        if (res.mustRestart) {
          this.cancel()
          this.fatalErrorMsg = res.errorMsg
          this.mustRestart = true;
        } else {
          this.activationError = res.errorMsg
          this.step = 'waitingForTotpCode';
          codeInput.setErrors({ wrongCode: true })
          codeInput.markAsTouched()
          this.activationCodeInput.nativeElement.focus()
        }

      } else if (res.ok) {
        this.user.totpEnrolled = true;
        this.user.totpVerified = true;
        this.authService.updateUser(this.user)
        this.cancel();
      }



    }, err => {
      console.error('Erreur lors de l\'activation : ', err)
      this.cancel();
      this.activationForm.enable()
    })


  }

  // deactivate() {
  //   this.user.totpEnrolled = false
  // }

  showCopiedTooltip(event) {
    console.log("copied : ", event)
    if (event) {
      this.keyDisplayTooltip.disabled = false;
      this.keyDisplayTooltip.show()
      setTimeout(() => {
        this.keyDisplayTooltip.hide()
        this.keyDisplayTooltip.disabled = true;
      }, 2000);
    }
  }

  cancel() {
    this.step = null;
    this.totpChecked = false;
    this.passwordForm.reset();
    this.activationForm.reset();
    this.mustRestart = false;
  }

}
