import { Injectable } from '@angular/core';
import { SnackbarService } from './';
import { FormConfigService } from './';
import { StepType } from '@app/forms/config/form-model';
import { FormViewerComponent } from '../components/_public/form-viewer/form-viewer.component';

@Injectable({ providedIn: 'root' })
export class FormHelperService {


  constructor(
    private formConfigService: FormConfigService,
    private snackbarService: SnackbarService,
  ) { }

  notifySuccess(defaultMessage: string, customMessages: string[] = null) {

    let snackOptions: any = { message: (defaultMessage && defaultMessage != '') ? defaultMessage : '' }

    if (customMessages && customMessages.length > 0) {
      snackOptions.textButton = 'Ok';
      snackOptions.message += (snackOptions.message !== '') ? '<hr>' : '';
      if (customMessages.length > 1) {
        customMessages.forEach(msg => snackOptions.message += `<li>${msg}</li>`);
      } else {
        snackOptions.message += customMessages[0];
      }
    }

    if (snackOptions.message !== '') {
      this.snackbarService.open(snackOptions)
    }
  }

  displayDebugTraces(traces: string[]) {
    if (traces) {
      traces.forEach(t => console.log(t));
    }
  }

  manageServerError(err, formViewer: FormViewerComponent): string {
    let errorMessage = '';
    let stepToGo: { index: number, step: StepType } = null;

    if (typeof (err) === 'object' && err.errors) {
      errorMessage = '';
      this.displayDebugTraces(err.traces);
      if (Array.isArray(err.errors)) {
        err.errors.forEach(e => errorMessage += `<li>${e}</li>`);
      } else {
        for (const fieldName in err.errors) {
          if (err.errors.hasOwnProperty(fieldName)) {
            let field = null;

            if (!stepToGo) {
              // Try to find the field and its step
              formViewer.formFields.find((step, index) => {
                field = this.formConfigService.findFieldByName(step.fields, fieldName);
                if (field) {
                  stepToGo = { index, step } // store the step of the first error
                  return true;
                }
                return false;
              })

            } else { // we have already found a stepToGo
              field = this.formConfigService.findFieldByName(formViewer.formFields, fieldName);
            }

            if (field) {
              field.formControl.setErrors({ apiError: err.errors[fieldName] });
              field.formControl.markAsTouched();
              errorMessage += `<li>${(field.templateOptions && field.templateOptions.label) ?
                field.templateOptions.label : fieldName} : ${err.errors[fieldName]}</li>`;
            } else {
              errorMessage += `<li>${err.errors[fieldName]}</li>`;
            }
          }
        }
      }
    } else if (typeof (err) === 'string') {
      errorMessage = err;
    }

    // Go to the first error step (if exist)
    formViewer.onStepChange({ selectedIndex: stepToGo?.index || 0 })

    formViewer.setErrorMessage(errorMessage)

    return errorMessage;
  }

}
