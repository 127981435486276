import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Reservation, ReservationPresence } from '@app/models/reservation';

@Component({
    selector: 'app-user-reservation-details',
    templateUrl: './details-reservation.component.html',
    styleUrls: ['./details-reservation.component.scss'],
    standalone: false
})
export class DetailsReservationComponent implements OnInit, AfterViewInit {

  @Input() reservation: Reservation;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['title', 'date', 'state'];
  dataSource = new MatTableDataSource<ReservationPresence>([]);

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.reservation.presences);
  }

  ngAfterViewInit(): void {
    this.bindMatTableStuff();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  bindMatTableStuff() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
