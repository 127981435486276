import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ExtendedFormlyTemplateOptions } from '@app/forms/config/form-model';

@Component({
    selector: 'app-formly-wrapper-panel',
    styles: [`
  .no-padding{padding:0 !important;}
  mat-icon{ margin-right:10px;}
  `],
    template: /*html*/ `
                    <div [ngClass]="{'bloc-form border': to.borderVisible===true}">
                      <div class="bloc-form-title-bar" *ngIf="(to.label && to.label !== '')">
                
                       <mat-icon>{{ (to.icon) ? to.icon : 'chevron_right'}}</mat-icon>
                       <span>{{ to.label }}</span>
                      </div>
                      <div class="bloc-form-content" [ngClass]="{'no-padding': to.nopadding===true}">
                        <div *ngIf="to.message" [innerHTML]="to.message | safe"></div>
                
                        <ng-container #fieldComponent></ng-container>
                      </div>
                    </div>
                  `,
    standalone: false
})
export class PanelWrapperComponent extends FieldWrapper {
  // to: ExtendedFormlyTemplateOptions;
  // get to(): ExtendedFormlyTemplateOptions { return super.to};

  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
}
