import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PlatformService, UserService, SnackbarService, AuthenticationService, LoginContext } from '@app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { NewPasswordComponent, newPasswordForm } from '@app/components/_elements/new-password/new-password.component';


@Component({
    selector: 'app-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss'],
    standalone: false
})
export class UpdatePasswordComponent implements OnInit {

  form: FormGroup<{
    oldPassword?: FormControl<string | null>;
    new?: FormGroup<newPasswordForm>
  }>


  isLoading = false;
  response = '';
  token: string;
  userLogin: string;
  title = 'Modification de votre mot de passe';

  showPass = false;

  fatalErrorMsg: string;

  @ViewChild(NewPasswordComponent) newPasswordComponent: NewPasswordComponent;

  constructor(
    public platformService: PlatformService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
    this.userLogin = this.route.snapshot.paramMap.get('userLogin') || this.authService.currentUserValue?.username || null
    const title = this.route.snapshot.paramMap.get('title');
    this.title = title ? title : this.title;

    this.form = new FormGroup({})

  }

  ngOnInit() {
    if (!this.token) {
      this.form.addControl('oldPassword', new FormControl<string | null>('', [Validators.required]));
    }

    // TOTP Check (if user enrolled)
    if (this.authService.isAuthenticated && this.authService.currentUserValue.totpEnrolled) {
      this.authService.TotpInitVerify('resetPassword').subscribe(res => {
        console.log('res', res)
        if (res.totpVerified) {
        } else if (res.canceled) {
          this.goBack();
        }
      },
        err => {
          console.error('Erreur lors de la vérification du TOTP', err)
          this.goBack();
        })
    }
  }

  ngAfterViewInit() {
    const newPassForm = this.newPasswordComponent.passForm;

    if (newPassForm) {
      this.form.addControl('new', newPassForm);
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      this.snackbarService.error('Formulaire invalide');
      return;
    }

    this.isLoading = true;

    const data: any = { password: this.form.value.new.password }; // do we need to send confirm too ?

    if (this.token) {
      data.token = this.token;
    } else {
      data.oldPassword = this.form.value.oldPassword;
    }

    this.userService.updatePassword(data).subscribe((result: any) => {
      this.isLoading = false;
      if (result.totp && result.totp.error) {
        this.fatalErrorMsg = result.totp.error
        return
      }

      if (result && result.error) {
        this.snackbarService.error(result.error);
        if (!!result.errorRef && result.errorRef !== '') {
          const fieldNameOnError = result.errorRef === 'oldPassword' ? this.form.get('oldPassword') : this.form.get('new.password')
          if (!!fieldNameOnError) {
            fieldNameOnError.setErrors(result.error);
          }
        }
      } else {
        this.snackbarService.info(result.message, 5000);

        if (this.authService.isAuthenticated) {
          // setTimeout( () => {this.router.navigate(['/account']); }, 200);
          this.goBack();
        } else if (this.userLogin && data.password) {
          const loginData: LoginContext = { username: this.userLogin, password: data.password, remember: true };
          this.authService.login(loginData)
            .subscribe(() => {
              setTimeout(() => { this.router.navigate(['/account']); }, 200);
            }, error => {
              console.error(error);
              setTimeout(() => { this.router.navigate(['/home']); }, 200);
            });
        } else {
          // history.back();
          setTimeout(() => { this.router.navigate(['/login']); }, 200);
          // setTimeout( () => {this.router.navigate(['/home']); }, 200);
        }

      }
    }, error => {
      this.snackbarService.error(error?.error || error);
      this.isLoading = false;
    });
  }

  goBack() {
    history.back()
  }
}
