<mat-card class="card-container" *ngIf="!isLoading; else loader">
  <mat-card-content>
    <ng-container *ngFor="let piece of piecesAFournir">
      <div class="piece-container">
        <div class="piece-content">
          <div class="piece-title">
            <b>{{ piece.name }}</b>
            <span *ngIf="piece.pieceJointeObligatoire" class="doc-obligatoire">
              <div class="doc-obligatoire">
                <mat-icon *ngIf="!getDocumentsFor(piece, entite)?.length" color="warn">error_outline</mat-icon>
                <span>(Document obligatoire)</span>
              </div>
            </span>
          </div>

          <div class="piece-description">
            <div [innerHtml]="piece.description | safe"></div>
          </div>

          <div class="documents-list">
            <ng-container *ngFor="let doc of getDocumentsFor(piece, entite)">
              <div class="file" [matMenuTriggerFor]="documentMenu" [matMenuTriggerData]="{doc: doc}">

                <div *ngIf="documentDownload[doc.idDocumentElectronique] as progress; else icon"
                  class="download-progress">
                  <mat-progress-spinner [value]="progress" diameter="32">
                  </mat-progress-spinner>
                  <span>{{ progress }}%</span>
                </div>

                <ng-template #icon>
                  <i *ngIf="getFileIcon(doc) as icon; else default" class="far fa-file-{{ icon }}"></i>
                  <ng-template #default><i class="far fa-file"></i></ng-template>
                </ng-template>

                <div class="name-file">
                  {{ doc.nomFichier }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="piece-buttons">
          <button *ngIf="piece?.accueils.length" mat-mini-fab matTooltip="Informations" color="accent"
            (click)="openPieceDetailsDialog(piece)">
            <app-icon type="fa" icon="info"></app-icon>
          </button>
          <button mat-mini-fab color="primary" matTooltip="Ajouter un fichier" (click)="inputFile.click()">
            <input #inputFile type="file" hidden (change)="onFileSelect($event.target.files, piece)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </mat-card-content>

  <mat-menu #documentMenu>
    <ng-template matMenuContent let-doc="doc">
      <button mat-menu-item (click)="openDocDetails(doc)">
        <mat-icon color="primary">visibility</mat-icon>
        <span>Détails</span>
      </button>
      <button mat-menu-item (click)="deleteDocument(doc)" [disabled]="doc.idDocumentElectronique > 0">
        <mat-icon color="warn">delete</mat-icon>
        <span>Supprimer</span>
      </button>
    </ng-template>
  </mat-menu>

  <mat-card-actions class="fx-center-center">
    <button mat-raised-button (click)="onValidate()" color="primary" [disabled]="!canValidate">Valider</button>
  </mat-card-actions>

</mat-card>

<ng-template #pieceDetailsDialog let-data>

  <app-dialog-header title="{{ data.piece.name }} : Informations" />

  <div mat-dialog-content class="thin-scrollbar">

    <ng-container *ngIf="data.mandatoryAccueils.length" [ngTemplateOutlet]="accueilsList"
      [ngTemplateOutletContext]="{accueils: data.mandatoryAccueils, mandatory: true}"></ng-container>

    <ng-container *ngIf="data.optionalAccueils.length" [ngTemplateOutlet]="accueilsList"
      [ngTemplateOutletContext]="{accueils: data.optionalAccueils, mandatory: false}"></ng-container>

  </div>
</ng-template>

<ng-template #accueilsList let-accueils="accueils" let-forced="mandatory">
  <div>
    <h4>
      <app-icon *ngIf="forced" type="fa" icon="exclamation-triangle" class="icon-exclamation"></app-icon>
      Ce document est {{ forced ? 'obligatoire' : 'demandé' }} dans
      {{ accueils.length > 1 ? 'les Établissements / Accueils suivants :' : 'l\'Établissement / Accueil suivant :' }}
    </h4>

    <div *ngFor="let accueil of accueils" class="accueil-list-item">
      <app-icon type="do" icon="accueil" class="icon"></app-icon>
      <span class="name">
        {{ (accueil.nameEtablissement + ' ' + accueil.name).trim() }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>