import { OnInit, Component } from '@angular/core';
import { AuthenticationService, SessionService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
    template: `
  <mat-card appearance="raised" *ngIf="errorMessage!==''" class="error-container">
    <mat-card-title class="mat-error">
      <mat-icon color="warn" style="float: left; margin-right: 10px;">error</mat-icon>
      <span>Erreur</span>
      <button mat-icon-button aria-label="dismiss" (click)="closeError()" style="float:right;">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-card-title>
    <mat-card-content>
      <pre [innerHTML]="errorMessage" class="mat-error" style="text-align: left;"></pre>
    </mat-card-content>
  </mat-card>
  <app-loader *ngIf="redirecting">
    <h3>{{ redirectingMessage|trans }}</h3>
  </app-loader>
  `,
    standalone: false
})
export class LogoutComponent implements OnInit {

  redirecting = false;
  redirectingMessage = '';
  errorMessage = '';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.redirecting = true;
    this.redirectingMessage = ' Redirection ';

    this.authService.logout().subscribe(res => {
      if (res && res.redirectTo) {
        window.location.href = res.redirectTo;
      } else {
        this.router.navigate(['home']); // or 'login' ?? 
      }
    })
  }

  closeError() {
    this.errorMessage = '';
    this.router.navigate(['home']); // or 'login' ?? 
  }
}
