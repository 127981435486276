<div *ngIf="demarche; else loader">
  <mat-stepper *ngIf="demarche.steps.length > 1 && !showMessageDemarcheCompleted" linear #stepper
    [orientation]="(stepperOrientation | async)" labelPosition="bottom"
    [ngClass]="{'hide-header': !demarche.showStepperHeader}">
    <mat-step *ngFor="let step of demarche.steps; let i = index;">
      <!-- [editable]="step.editable" [completed]="step.completed" -->
      <ng-template matStepLabel>{{step.label}}</ng-template>
      <ng-template matStepContent>
        <ng-container [ngTemplateOutlet]="typeSteps"
          [ngTemplateOutletContext]="{step: step, index: i, stepper:stepper}"></ng-container>
      </ng-template>
    </mat-step>
  </mat-stepper>

  <div *ngIf="demarche?.steps.length === 1 && !showMessageDemarcheCompleted">
    <ng-container [ngTemplateOutlet]="typeSteps" [ngTemplateOutletContext]="{step: demarche.steps[0]}"></ng-container>
  </div>

  <mat-card appearance="outlined" *ngIf="showMessageDemarcheCompleted" class="card-demarche-complete">
    <mat-card-content>
      <div [innerHtml]="demarche.messageCompletedDemarche | safe"></div>
    </mat-card-content>
    <mat-card-actions class="fx-center-center fx-gap-5" style="padding-bottom: 16px;">
      <button mat-raised-button color="primary" *ngIf="fromDemarchesList" routerLink="/account/demarches">
        <mat-icon style="margin-right: 5px;">add_box</mat-icon>{{'demarches.btn_nouvelle_demarche' | trans}}
      </button>
      <button mat-raised-button color="accent" *ngIf="fromDemarchesList" routerLink="/account">
        <mat-icon style="margin-right: 5px;">account_circle</mat-icon> {{'demarches.btn_mon_compte' | trans}}
      </button>
      <button mat-raised-button color="accent" *ngIf="!fromDemarchesList" (click)="location.back()">
        <mat-icon style="margin-right: 5px;">arrow_back</mat-icon> {{'demarches.btn_retour' | trans}}
      </button>
    </mat-card-actions>
  </mat-card>

</div>

<ng-template #typeSteps let-step="step" let-index="index" let-stepper="stepper">
  <div *ngIf="!demarche.showStepperHeader"
    style="display: flex; justify-content: space-between; align-items: center; align-content: center;">
    <span></span>
    <h2 style="text-align: center;" class="primary">{{step.label}}</h2>
    <span *ngIf="demarche?.steps.length > 1" style="margin-left: 5px; margin-right: 10px;"><small><b>Étape {{index +
          1}}
          /
          {{demarche?.steps.length}}</b></small>
    </span>
    <span *ngIf="demarche?.steps.length === 1"></span>
  </div>
  <div class="card-info-container">
    <mat-card appearance="outlined" *ngIf="step.infoMessage" [portailCustomStyle]="step.styleCardInfo"
      class="card-info">
      <div [innerHtml]="step.infoMessage | safe"></div>
    </mat-card>
  </div>

  <ng-container *ngIf="step.type === 'form'">

    <ng-container *ngIf="step?.formName === 'Famille'">
      <div *ngIf="permissionService.hasPermission('foyer_edit'); else permissionDisabled" style="padding: 5px;"
        [ngClass]="{'show-header-stepper': !demarche.showStepperHeader, 'mat-elevation-z2': demarche.showStepperHeader}">
        <app-foyer-edit [demarcheTitle]="demarche.label" fromDemarche="true"
          (onValidate)="onValidateStepForm($event, step)">
        </app-foyer-edit>
      </div>
    </ng-container>

    <ng-container *ngIf="step?.formName === 'Conjoint'">
      <div *ngIf="permissionService.hasPermission('foyer_edit'); else permissionDisabled" style="padding: 5px;"
        [ngClass]="{'show-header-stepper': !demarche.showStepperHeader, 'mat-elevation-z2': demarche.showStepperHeader}">
        <app-conjoint-edit [demarcheTitle]="demarche.label" fromDemarche="true"
          (onValidate)="onValidateStepForm($event, step)">
        </app-conjoint-edit>
      </div>
    </ng-container>

    <ng-container *ngIf="step?.formName === 'Enfant'">
      <div *ngIf="permissionService.hasPermission('child_edit'); else permissionDisabled" style="padding: 5px;"
        [ngClass]="{'show-header-stepper': !demarche.showStepperHeader, 'mat-elevation-z2': demarche.showStepperHeader}">
        <app-user-child-edit [demarcheTitle]="demarche.label" fromDemarche="true"
          (onValidate)="onValidateStepForm($event, step)">
        </app-user-child-edit>
      </div>
    </ng-container>



    <!-- <ng-container *ngIf="step?.formName === 'Utilisateur'">
      <app-user-edit *ngIf="permissionService.hasPermission('account_edit'); else permissionDisabled"
        [stepForm]="step?.formStep?.stepName" [demarcheTitle]="demarche.label" fromDemarche="true"
        (onValidate)="onValidateStepForm($event, step)">
      </app-user-edit>
    </ng-container> -->

    <ng-container *ngIf="step?.formName === 'Inscription Diabolo'">
      <app-edit-diabolo-inscriptions
        *ngIf="permissionService.hasPermission('diabolo_inscriptions_create'); else permissionDisabled"
        fromDemarche="true" [usager]="usager" (save)="onValidateStepForm($event, step)">
      </app-edit-diabolo-inscriptions>
    </ng-container>

    <ng-container *ngIf="step?.formName === 'Inscription Cogito'">
      <app-edit-cogito-inscription
        *ngIf="permissionService.hasPermission('cogito_inscriptions_create'); else permissionDisabled"
        fromDemarche="true" [usager]="usager" (save)="onValidateStepForm($event, step)">
      </app-edit-cogito-inscription>
    </ng-container>

    <ng-container *ngIf="step?.formName === 'Pré-inscription Mikado'">
      <div *ngIf="permissionService.hasPermission('preinscription_create'); else permissionDisabled"
        style="padding: 5px;"
        [ngClass]="{'show-header-stepper': !demarche.showStepperHeader, 'mat-elevation-z2': demarche.showStepperHeader}">
        <app-user-preinscription-edit [fromDemarche]="true" (save)="onValidateStepForm($event, step)">
        </app-user-preinscription-edit>
      </div>
    </ng-container>

    <ng-container *ngIf="step?.formName === 'Pré-inscription Lasido'">
      <app-lasido-edit *ngIf="permissionService.hasPermission('lasido_create'); else permissionDisabled"
        fromDemarche="true" [usager]="usager" (save)="onValidateStepForm($event, step)">
      </app-lasido-edit>
    </ng-container>

    <ng-container *ngIf="step?.formName === 'Pré-inscription Cogito'">
      <app-edit-cogito-preinscription *ngIf="permissionService.hasPermission('cogito_create'); else permissionDisabled"
        fromDemarche="true" [usager]="usager" (save)="onValidateStepForm($event, step)">
      </app-edit-cogito-preinscription>
    </ng-container>

  </ng-container>

  <app-demarche-question-step *ngIf="step.type === 'question'" [step]="step"
    (validate)="onValidateQuestion($event, step, index)">
  </app-demarche-question-step>

  <app-demarche-select-step *ngIf="step.type === 'select'" [demarche]="demarche" [stepper]="stepper" [step]="step"
    (onValidate)="getUsagerSelected($event)" (onCreate)="addStepToCreate($event,step,index)">
  </app-demarche-select-step>

  <app-demarche-ged *ngIf="step.type === 'piece'" [piecesAFournir]="step.docPiecesList" [entite]="gedEntite"
    (validate)="onValidatePieceAFournir($event,step)">
  </app-demarche-ged>

</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>

<ng-template #permissionDisabled>
  <h3 style="text-align: center;">{{ 'demarches.feature_disabled'|trans }}</h3>
</ng-template>