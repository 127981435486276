import { Component, OnInit, OnDestroy } from '@angular/core';
import { Child } from '@app/models/child';
import { ChildService } from '@app/services/child.service';
import { PermissionService } from '@app/services/permission.service';
import { FormConfigService } from '@app/services/form-config.service';
import { map, filter, takeUntil, switchMap, tap, find } from 'rxjs/operators';
import { PlatformService } from 'src/app/services/platform.service';
import { Router } from '@angular/router';
import { BaseConfigService, FamilyService, loadConf } from '@app/services';
import { forkJoin, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { EditPhotoComponent } from '../edit-photo/edit-photo.component';
import { BoardCard } from '@app/models/board-card';

@Component({
    selector: 'app-user-children',
    templateUrl: './children-list.component.html',
    styleUrls: ['./children-list.component.scss'],
    standalone: false
})
export class ChildrenListComponent implements OnInit, OnDestroy {

  children: Child[];
  photoChildren: any[];
  formParts: any[];
  readOnly: boolean;
  permToCreate: boolean;
  permToAddPhoto: boolean;
  overEditPhoto: boolean = false;
  addBtnUrl: string;
  onDestroy$ = new Subject<void>();

  constructor(
    private childService: ChildService,
    private familyService: FamilyService,
    private formConfigService: FormConfigService,
    public permService: PermissionService,
    public platformService: PlatformService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private baseConfigService: BaseConfigService
  ) {
  }

  ngOnInit() {

    this.baseConfigService.getFirstConf$("board-user")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(conf => {
        const cards = conf.content.cards;
        const idDemarche = cards.find((card: BoardCard) => card.component === 'children').addBtnDemarcheId;
        this.addBtnUrl = idDemarche ? `add/${idDemarche}` : 'add';
      })

    this.readOnly = !this.permService.hasPermission('child_edit') || !this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('child_create') && this.familyService.currentFamily.active;
    this.permToAddPhoto = this.permService.hasPermission('child_photo') && this.familyService.currentFamily.active;
    this.loadData();
  }

  loadData() {
    // Know which form sections are enabled
    this.loadFormSteps().subscribe(stepNames => this.formParts = stepNames);

    this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => this.children = null), // reset current data
      switchMap(f => this.loadChildren(f))
    ).subscribe(_ => {
      this.children.forEach(child => {
        let dataPhoto = this.photoChildren?.find(photo => child.id === photo.idEnfant).data;
        if (dataPhoto) {
          child.photo = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + dataPhoto)
        }
      });
    }, err => { // just catch the error because otherwise it stuck the load of children when we have an error on 'photoList' load 
      console.error('erreur', err)
    });
  }

  loadChildren(f) {
    let requests: any = {};
    requests.childrenList = this.childService.getFamilyChildren(f).pipe(tap(data => this.children = data));

    if (this.permToAddPhoto) {
      requests.photoList = this.childService.getAllChildPhotoByIdFamille(f).pipe(tap((data: any[]) => this.photoChildren = data));
    }
    return forkJoin(requests)
  }

  loadFormSteps() {
    const filteredSteps = ['validation']; // steps excluded in "read / edit" (not create) modes
    return this.formConfigService.getForm('form-enfant').pipe(
      map(steps => steps
        .filter(step => (!filteredSteps.includes(step.stepName)))
        .map(step => ({ stepName: step.stepName, label: step.label }))
      )
    );
  }

  openDialog(child: Child) {
    const dialRef = this.dialog.open(EditPhotoComponent, {
      data: child
    });

    this.platformService.adaptDialogToScreen(dialRef)

    dialRef.componentInstance.onSave.pipe(
      takeUntil(dialRef.afterClosed())
    ).subscribe(previewURL => {
      child.photo = previewURL;
      dialRef.close();
    });
  }

  openForm(childId, stepName) {
    this.router.navigate([`/account/children/edit/${childId}/${stepName}`]);
  }

  // onDeleteClick(child: Child) {
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     width: '400px',
  //     data: { message: `Voulez vous supprimer l'enregistrement de cet enfant ?` }
  //   });

  //   dialogRef.afterClosed().subscribe(res => res ? this.delete(child) : null);
  // }

  // delete(child: Child) {
  //   this.childService.delete(child).subscribe(_ => {
  //     const data = this.children;
  //     const index = data.findIndex(each => each.id === child.id);
  //     if (index !== -1) {
  //       data.splice(index, 1);
  //       this.children = data;
  //     }
  //   });
  // }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
