import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

/*
 * Directive for give focus to an input after component init
 */

@Directive({
    selector: '[appFocusOnShow]',
    standalone: false
})
export class FocusOnShowDirective implements AfterViewInit {

  @Input() appFocusOnShow: boolean;

  constructor(private el: ElementRef) {
    if (!el.nativeElement.focus) {
      throw new Error('Element does not accept focus.');
    }
  }

  ngAfterViewInit(): void {
    if (this.appFocusOnShow) {
      const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
      setTimeout(() => {
          return input.focus();
      }, 0 );
    }
  }
}
