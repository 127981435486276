import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Reservation, ReservationPresence } from '@app/models/reservation';
import { PlanningData } from '../planning/planning-data';
import { PlanningService } from '@app/services/planning.service';
import { Periode } from '@app/models/periode';

@Component({
    selector: 'app-reservation-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
    standalone: false
})
export class ReservationSummaryComponent implements OnInit {

  @Input() reservation: Reservation;
  @Input() planningData: PlanningData;

  @Output() attenteSelectionChange = new EventEmitter();

  @HostBinding('class.show-times') showTimes: boolean;

  byDays: { date: string, items: ReservationPresence[] }[];

  constructor(
    private planningService: PlanningService
  ) { }

  ngOnInit(): void {
    this.computeItems();
  }

  computeItems() {
    const days = {};
    let date: string;

    this.reservation.presences?.forEach(pr => {
      date = pr.date as string;
      if (!days.hasOwnProperty(date)) {
        days[date] = { date, items: [] };
      }

      days[date].items.push(pr);

      if (!this.showTimes && pr.showTimes) {
        this.showTimes = true;
      }
    });

    this.byDays = Object.values(days);
  }

  onToggleAttente(presence: ReservationPresence, event: MatCheckboxChange) {
    presence.attente = presence.attente !== 1 ? 1 : -1;

    const grouped = presence.group ? this.reservation.presences.filter(op => op.group === presence.group) : [];

    if (this.planningData.currentPeriode.rubriqueChains) {
      const chains = this.planningData.currentPeriode.rubriqueChains;

      [].concat(presence, ...grouped).forEach(op => {
        const sameDatePresences = this.reservation.presences.filter(pr => pr.date === op.date);
        if (presence.attente === -1) {
          grouped.push(...this.planningService.getDependantPresences(op, sameDatePresences, chains));
        } else {
          grouped.push(...this.planningService.getRequiredPresences(op, sameDatePresences, chains) || []);
        }
      });
    }

    grouped.forEach(gp => gp.attente = presence.attente);

    this.attenteSelectionChange.emit(null);
  }
}
