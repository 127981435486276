import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Route, UrlSegment, Router } from '@angular/router';
import { AuthenticationService, SnackbarService } from '@app/services';
import { Role } from '@app/models/user';

@Injectable()
export class VariousGuard  {

  constructor(
    private authService: AuthenticationService,
    private snackbarServive: SnackbarService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess(route, state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess(route, state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.checkAccess(route, segments.join('/'));
  }

  checkAccess(route: Route | ActivatedRouteSnapshot, redirectUrl?: string): boolean {


    if (route.data) {
      // Some routes cannot be accessed if user come from OIDC
      if (route.data.notFromOIDC && this.authService.currentUserValue.fromOidc) {
        return false;
      }

      // Prevent admin access to user parts
      if (route.data.notForManager && this.authService.currentUserValue.role !== Role.User) {
        this.snackbarServive.info('Réservé aux usagers')
        if (this.authService.hasRole(Role.Manager)) {
          this.router.navigate(['/admin']);
        }
        return false;
      }

    }



    return true;
  }

}


