import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
    name: 'apiUrl',
    standalone: false
})
export class ApiUrlPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return environment.apiUrl + value;
  }
}
