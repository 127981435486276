<mat-card style="max-width: 500px; margin-inline: auto; margin-top: 20px; margin-bottom: 10px;">

  <h3 style="text-align: center;">{{step.labelQuestion}}</h3>

  <mat-card-content *ngIf="step.reponses;else loader">
    <mat-radio-group [(ngModel)]="responseIdValue" class="radio-group">
      <mat-radio-button *ngFor="let reponse of step.reponses" [value]="reponse.id" class="radio-button">
        <span class="wrap-mat-radio-label">{{reponse.label}}</span>
      </mat-radio-button>
    </mat-radio-group>
  </mat-card-content>

  <mat-card-actions class="fx-center-center">
    <button mat-raised-button (click)="onValidate()" color="primary" [disabled]="!responseIdValue">Valider</button>
  </mat-card-actions>
</mat-card>

<ng-template #loader>
  <app-loader [message]="'Chargement...'" class="margin"></app-loader>
</ng-template>