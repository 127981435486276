import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlatformService } from '@app/services';
import { HeaderService } from '@app/services/header.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-subheader',
    templateUrl: './subheader.component.html',
    styleUrls: ['./subheader.component.scss'],
    standalone: false
})
export class SubheaderComponent implements OnInit, OnDestroy {

  showActions: boolean;
  onDestroy$ = new Subject<void>();
  isMobile: boolean;

  constructor(
    private headerService: HeaderService,
    public platformService: PlatformService
  ) { }

  ngOnInit(): void {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => this.isMobile = value);

    this.headerService.topbarActionsTemplate$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(x => this.showActions = !!x);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
