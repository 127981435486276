<ng-container *ngIf="dialogMode">
  <mat-toolbar mat-dialog-title class="primary-bg">
    <button class="close-dialog" mat-icon-button matTooltip="Fermer" (click)="onClickClose()">
      <mat-icon>close</mat-icon>
    </button>
    <span *ngIf="!displayPreview" class="title">{{ 'facture.detail.title'|trans:{num: facture.num} }}</span>
    <span *ngIf="displayPreview" class="title">{{ 'facture.detail.previewTitle'|trans:{num: facture.num} }}</span>
  </mat-toolbar>

  <mat-dialog-content class="thin-scrollbar">
    <ng-container *ngTemplateOutlet="displayPreview ? pdfPreview : detailContent"></ng-container>
  </mat-dialog-content>

  <mat-dialog-actions align=end *ngIf="!displayPreview">
    <button style="margin: 10px;" mat-raised-button color="accent-lighter" (click)="dowloadPDF('preview')"
      [disabled]="loadingPdfDownload.progress" [matTooltip]="'facture.action.preview_tooltip'|trans">

      <div *ngIf="loadingPdfDownload.progress !== null && loadingPdfDownload.action ==='preview'; else previewIcon"
        class="download-progress">
        <mat-progress-spinner [mode]="loadingPdfDownload.progress>0 ? 'determinate' : 'indeterminate'"
          [value]="loadingPdfDownload.progress" diameter="32"></mat-progress-spinner>
        <span *ngIf="loadingPdfDownload.progress>0">{{ loadingPdfDownload.progress }}%</span>
      </div>
      <ng-template #previewIcon><span class="download-icon">
          <mat-icon>visibility</mat-icon>
        </span></ng-template>
      <span class="download-label">{{ 'facture.action.preview'|trans }}</span>
    </button>

    <button style="margin: 10px;" mat-raised-button color="accent-lighter" (click)="dowloadPDF('download')"
      [disabled]="loadingPdfDownload.progress" [matTooltip]="'facture.action.download_tooltip'|trans">

      <div *ngIf="loadingPdfDownload.progress !== null && loadingPdfDownload.action ==='download'; else downloadIcon"
        class="download-progress">
        <mat-progress-spinner [mode]="loadingPdfDownload.progress>0 ? 'determinate' : 'indeterminate'"
          [value]="loadingPdfDownload.progress" diameter="32"></mat-progress-spinner>
        <span *ngIf="loadingPdfDownload.progress>0">{{ loadingPdfDownload.progress }}%</span>
      </div>
      <ng-template #downloadIcon><span class="download-icon">
          <mat-icon>get_app</mat-icon>
        </span></ng-template>
      <span class="download-label">{{ 'facture.action.download'|trans }}</span>
    </button>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="!dialogMode">
  <ng-container *ngTemplateOutlet="detailContent"></ng-container>
</ng-container>

<ng-template #detailContent>
  <div class="facture-header-line">
    <div>
      <label>{{ 'facture.data.num'|trans }} :</label>
      <span>{{ facture.num }}</span>
    </div>
    <span class="space-filler"></span>
    <div>
      <label>{{ 'facture.data.date'|trans }} :</label>
      <span>{{ facture.date | date:'shortDate' }}</span>
    </div>
  </div>

  <div class="facture-header-line">
    <div>
      <label>{{ 'facture.data.montant'|trans }} :</label>
      <span>{{ facture.montant|currency:'EUR' }}</span>
    </div>

    <ng-container *ngIf="regie.afficheEtatFacture">
      <span class="space-filler"></span>
      <div>
        <label>{{ 'facture.detail.restant_du'|trans }} :</label>
        <span>{{ facture.resteAPayer|currency:'EUR' }}</span>
      </div>

      <div *ngIf="facture.status" class="state-label">
        <label>{{ 'facture.data.etat'|trans }} :</label>
        <span>{{ ('facture.status.' + facture.status)|trans }}</span>

        <mat-icon *ngIf="facture.status === 'Paye'" class="status-icon success-color">check</mat-icon>
        <mat-icon *ngIf="facture.status === 'EnAttenteDePaiement'" class="status-icon" color="primary">hourglass_empty
        </mat-icon>
        <mat-icon *ngIf="facture.status === 'ValidationPaiement'" class="status-icon" color="accent">hourglass_top
        </mat-icon>
        <mat-icon *ngIf="facture.status === 'Contentieux'" class="status-icon" color="warn">warning</mat-icon>
      </div>
    </ng-container>
  </div>

  <div *ngIf="facture.paidBy && regie.afficheReglements">
    <label>{{ 'facture.detail.reglee_par'|trans }} :</label>
    <ul>
      <li *ngFor="let p of facture.paidBy">
        {{ 'facture.detail.reglee_par_ligne'|trans:{mode: p.mode, num: p.numero, date: p.date|date:'shortDate'} }}
      </li>
    </ul>
  </div>

  <div class="facture-header-line">
    <label>{{ 'facture.detail.periode'|trans }} :</label>
    <span>du {{ facture.dateDebut | date: 'dd/MM/yyyy'}} au {{ facture.dateFin | date: 'dd/MM/yyyy'}}</span>
  </div>

  <div class="facture-header-line">
    <label>{{ 'facture.detail.referent'|trans }} :</label>
    <span>{{ facture.civilite }} {{ facture.nom }}</span>
  </div>

  <div class="facture-header-line">
    <label>{{ 'facture.detail.accueil_info'|trans }} :</label>
    <ul>
      <li *ngFor="let factureLineGroup of groupedFactureLines">
        {{ factureLineGroup.details }}
      </li>
    </ul>
  </div>

  <table *ngIf="linesReady; else loader" class="facture-line-table">
    <thead>
      <tr class="primary-darker-bg">
        <th>{{ 'facture.line.designation'|trans }}</th>
        <th>{{ 'facture.line.quantite'|trans }}</th>
        <th>{{ 'facture.line.tarif'|trans }}</th>
        <th>{{ 'facture.line.montant'|trans }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let factureLineGroup of groupedFactureLines">
        <tr class="primary-lighter-bg">
          <td class="line-group-title" colspan="4">
            <b>{{ factureLineGroup.referent }}</b> - {{ factureLineGroup.details }}
          </td>
        </tr>
        <tr class="details-facture" *ngFor="let factureLine of factureLineGroup.lines">
          <td>{{ factureLine.designation }}</td>
          <td class="text-center">{{ factureLine.quantite }}</td>
          <td class="text-center">{{ factureLine.prixUnitaire|currency:'EUR' }}</td>
          <td class="text-center">{{ factureLine.montant|currency:'EUR' }}</td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="primary-darker-bg">
        <td colspan="3">{{ 'facture.line.total'|trans }}</td>
        <td class="text-center">{{ facture.montant|currency:'EUR' }}</td>
      </tr>
    </tfoot>
  </table>
</ng-template>

<ng-template #pdfPreview let-data>
  <mat-dialog-content class="document-details thin-scrollbar">
    <pdf-viewer [src]="pdfBuffer" [render-text]="false" [original-size]="false" [autoresize]="true"></pdf-viewer>
  </mat-dialog-content>
</ng-template>

<ng-template #loader>
  <app-loader style="margin: 30px 0;"></app-loader>
</ng-template>