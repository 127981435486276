import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FamilyService, PlatformService } from '@app/services';
import { TicketService } from '@app/services/ticket.service';
import moment from 'moment';

export interface ReleveTicket {
  dateDeb: string,
  dateFin: string,
  dateSolde: string,
  typeTicket: TypeTicket[]
}

export interface TypeTicket {
  idTypeRubrique: number,
  libelleTypeRubrique: string,
  operations: OperationsTicket[]
}

export interface OperationsTicket {
  date: string,
  solde: number,
  quantite: number,
  soldeAvant: boolean,
  type: string,
  detail: DetailTicket,
}

export interface DetailTicket {
  ligneFacture?: any[],
  presences?: any[]
}

@Component({
    selector: 'app-ticket-demat',
    templateUrl: './ticket-demat.component.html',
    styleUrls: ['./ticket-demat.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ],
    standalone: false
})
export class TicketDematComponent implements OnInit {

  releveTicketsData: ReleveTicket;
  loading: boolean;
  startOfMonth: Date | string;
  endOfMonth: Date | string;
  listOfMonth: string[];
  monthSelected: string;
  showSelectMonth: boolean;
  // soldePrev: number;
  // soldeReel: number;
  disabledNext: boolean;
  disabledPrev: boolean;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['type', 'date', 'achat', 'consommation', 'solde'];
  expandedOperation: any;
  dataSource: MatTableDataSource<OperationsTicket>;

  constructor(
    public platformService: PlatformService,
    private familyService: FamilyService,
    private ticketService: TicketService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.startOfMonth = moment().startOf('month').format('Y-MM-DD');
    this.endOfMonth = moment().endOf('month').format('Y-MM-DD');
    this.monthSelected = moment().format('MMMM YYYY');

    const idFamille = this.familyService.currentFamily.id;

    this.ticketService.getReleveTicket(idFamille)
      .subscribe((data: ReleveTicket) => {
        this.releveTicketsData = data;
        if (data.typeTicket.length) {
          this.dataSource = new MatTableDataSource(data.typeTicket[0].operations);
          this.dataSource.sort = this.sort;

          this.dataSource.filterPredicate = (data, filtersJSON) => {
            const filter = JSON.parse(filtersJSON);
            return this.operationInMonth(data.date, filter.startOfMonth, filter.endOfMonth);
          };

          // this.getSoldePrev();
          // this.getSoldeReel();
          this.setDisabledNextPrev();
          this.applyFilter();
          this.setListOfMonthInPeriode();

        }

        this.loading = false;
      })
  }


  operationInMonth(dateOperation: string, startOfMonth: Date | string, endOfMonth: Date | string) {
    if (moment(dateOperation) >= moment(startOfMonth) && moment(dateOperation) <= moment(endOfMonth)) {
      return true;
    }
    return false;
  }

  setDisabledNextPrev() {
    this.disabledPrev = moment(this.releveTicketsData.dateDeb).isBefore(this.startOfMonth);
    this.disabledNext = moment(this.releveTicketsData.dateFin).isAfter(this.endOfMonth);
  }

  changeSelectedMonth(event: string) {
    const monthSelected = moment(event, 'MMMM Y');
    this.startOfMonth = monthSelected.startOf('month').format('Y-MM-DD');
    this.endOfMonth = monthSelected.endOf('month').format('Y-MM-DD');

    this.applyFilter();
    this.setDisabledNextPrev();
  }

  onClickSelectedMonth() {
    this.showSelectMonth = true;
  }

  setListOfMonthInPeriode() {
    let dateStart = moment(this.releveTicketsData.dateDeb);
    let dateEnd = moment(this.releveTicketsData.dateFin);
    let listOfMonth = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      listOfMonth.push(dateStart.format('MMMM Y'));
      dateStart.add(1, 'month');
    }
    this.listOfMonth = listOfMonth;
  }

  onClickNextPrev(type: string) {

    if (type === 'next') {
      this.startOfMonth = moment(this.startOfMonth).add(1, 'month').startOf('month').format('Y-MM-DD');
      this.endOfMonth = moment(this.endOfMonth).add(1, 'month').endOf('month').format('Y-MM-DD');
    }

    if (type === 'prev') {
      this.startOfMonth = moment(this.startOfMonth).subtract(1, 'month').startOf('month').format('Y-MM-DD');
      this.endOfMonth = moment(this.endOfMonth).subtract(1, 'month').endOf('month').format('Y-MM-DD');
    }

    this.monthSelected = moment(this.startOfMonth).format('MMMM Y');
    this.showSelectMonth = false;

    this.applyFilter();
    this.setDisabledNextPrev();

  }

  applyFilter() {
    const filter = { startOfMonth: this.startOfMonth, endOfMonth: this.endOfMonth };
    this.dataSource.filter = JSON.stringify(filter);
  }

  // getSoldePrev() {
  //   const listDate = this.getListDateOperation();
  //   const soldePrev = this.getSolde(listDate);
  //   this.soldePrev = soldePrev[0];
  // }

  // getSoldeReel() {
  //   const listDate = this.getListDateOperationReel();
  //   const soldeReel = this.getSolde(listDate);
  //   this.soldeReel = soldeReel[0];
  // }

  // getSolde(listDate){
  //   const dateSolde = moment.max(listDate).format('Y-MM-DD');
  //   const solde = this.dataSource.data.filter(operation => operation.date === dateSolde).map(op => op.solde);
  //   return solde;
  // }

  // getListDateOperation() {
  //   const listOfDate = this.dataSource.data.map(operation => moment(operation.date));
  //   return listOfDate;
  // }

  // getListDateOperationReel() {
  //   const listDateReel = this.dataSource.data.map(op => moment(op.date))
  //     .filter(date => date.diff(moment(new Date), 'days') < 0)
  //     return listDateReel;
  // }

  switchTypeRub(event) {
    this.loading = true;
    this.dataSource.data = this.releveTicketsData.typeTicket[event].operations;
    // this.getSoldePrev();
    // this.getSoldeReel();
    this.loading = false;
  }

}
