<div [formGroup]="passForm" class="fx-direction-row fx-wrap fx-start-start fx-gap-10">
  <div style="flex: 1;">
    <mat-form-field>
      <mat-label>{{ label|trans }}</mat-label>
      <input matInput [type]="showPass ? 'text' : 'password'" formControlName="password">

      <button mat-icon-button matSuffix (click)="showPass = !showPass" type="button" tabindex="-1">
        <mat-icon>{{ showPass ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>

      <mat-hint *ngIf="!passForm.get('password').invalid">{{ strengthLabel }}</mat-hint>
      <mat-error *ngIf="passForm.get('password').invalid">{{ getPasswordErrorMessage() }}</mat-error>
    </mat-form-field>

    <mat-progress-bar mode="determinate" [color]="strengthColor" [value]="strength * 25"></mat-progress-bar>
  </div>

  <mat-form-field class="confirm-password" style="flex: 1">
    <mat-label>
      Confirmer le nouveau mot de passe
      <span class="mat-form-field-required-marker">*</span>
    </mat-label>
    <input type="password" matInput formControlName="confirmPassword" [errorStateMatcher]="matcher">

    <mat-error *ngIf="passForm.get('confirmPassword').touched && passForm.hasError('match')">
      Erreur de confirmation
    </mat-error>
  </mat-form-field>

</div>