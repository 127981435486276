<mat-drawer-container autosize>
  <mat-drawer-content style="width:100%;">

    <ng-template appTopbarActions>
      <button mat-mini-fab color="accent" (click)="toggleOptions()" matTooltip="Options d'affichage">
        <mat-icon>settings</mat-icon>
      </button>
    </ng-template>

    <div class="conteneur thin-scrollbar" *ngIf="inscriptions; else loader">

      <mat-card appearance="outlined" *ngIf="config.infoMessageList" class="info-card"
        [portailCustomStyle]="config.styleCardInfoList">
        <div [innerHtml]="config.infoMessageList | safe"></div>
      </mat-card>

      <div class="inscription-list" *ngIf="isLoading">
        <mat-card appearance="raised" class="inscription-card" *ngFor="let inscription of inscriptionsFiltered">
          <mat-card-header class="card-header fx-direction-row fx-between-start">
            <mat-card-title class="fx-direction-row fx-start-center">
              <app-icon *ngIf="inscription.idEnfant" style="margin-right: 5px;" type="do" icon="inscription">
              </app-icon>
              <span>{{ inscription.nom }} {{ inscription.prenom }}</span>
            </mat-card-title>
            <button *ngIf="permToEdit && inscription.editableByAnnee && inscription?.editable" mat-mini-fab
              color="primary" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-card-header>

          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="onClickEdit(inscription)">
              <mat-icon color="primary">edit
              </mat-icon>
              {{ 'action.edit'|trans }}
            </button>

          </mat-menu>

          <mat-card-content>
            <div>
              <label>{{ 'cogito.annee_scolaire'|trans }} : </label>
              <span>{{ inscription.anneeScolaire.designation }}</span>
            </div>

            <div>
              <label>{{ 'cogito.niveau_scolaire'|trans }} : </label>
              <span>{{ inscription.niveauScolaireName }}</span>
            </div>

            <div>
              <label>{{ 'cogito.secteur_scolaire'|trans }} : </label>
              <span>{{ inscription.secteurScolaireName }}</span>
            </div>

            <div>
              <label>{{ 'cogito.ecole'|trans }} : </label>
              <span>{{ inscription.ecoleName }}</span>
            </div>

            <div *ngIf="inscription.classeName">
              <label>{{ 'cogito.classe'|trans}} : </label>
              <span>{{inscription.classeName}}</span>
            </div>

            <div *ngIf="inscription.idInscription <= 0" class="fx-direction-row fx-end-center"
              style="margin-top: 15px;">
              <em>En attente de validation.</em>
            </div>
          </mat-card-content>
        </mat-card>

      </div>

      <div *ngIf="!inscriptions.length && !permToCreate">
        <h2 style="text-align: center;">{{ 'cogito.no_inscription'|trans }}</h2>
      </div>

      <mat-card appearance="outlined" *ngIf="permToCreate && inscriptionsActive && isLoading"
        class="actions-card card-add">
        <mat-card-header class="card-header fx-direction-row fx-center-center">
          <mat-card-title class="fx-direction-row fx-center-center">
            {{ 'cogito.new_inscription'|trans }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="flex: 1; margin-top: 16px;" class="fx-direction-row fx-center-center">
          <button mat-fab color="primary" matTooltip="{{ 'cogito.new_inscription'|trans }}" [routerLink]="addBtnUrl"
            [disabled]="!addBtnUrl">
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-content>
      </mat-card>

    </div>

  </mat-drawer-content>

  <!-- Options d'affichage -->
  <mat-drawer id="previewDrawer" class="mat-elevation-z4 thin-scrollbar" position="end" [(opened)]="drawerOpened">
    <mat-toolbar class="preview-header fx-direction-row fx-between-center" color='primary'>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
      <span>Options d'affichage</span>
      <span></span>
    </mat-toolbar>

    <div style="margin: 5px;">
      <mat-form-field style="width: 100%;">
        <mat-label>Filtrer par enfant</mat-label>
        <mat-select [(ngModel)]="filterUsagers" (ngModelChange)="changeSelectChildren($event)" multiple>
          <mat-option *ngFor="let child of listUsagers" [value]="child">
            {{child.nom}} {{child.prenom}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-drawer>
</mat-drawer-container>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>