<div class="thin-scrollbar container-table">
  <form [formGroup]="formFilterTable">
    <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortActive" matSortDirection="desc"
      [multiTemplateDataRows]="adminMode">

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'reglement.data.etat'|trans }}</th>
        <td mat-cell *matCellDef="let reglement">
          <div class="row items-center content-center">
            <span *ngIf="reglement.status" [matTooltip]="('reglement.status.' + reglement.status)|trans"
              class="items-center" style="display: inline-flex;">
              <mat-icon *ngIf="reglement.status === 'Valide'" class="accent">check</mat-icon>
              <mat-icon *ngIf="reglement.status === 'EnAttente'" class="primary">hourglass_empty</mat-icon>
              <mat-icon *ngIf="reglement.status === 'Annule'" class="warn">highlight_off</mat-icon>
            </span>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="date">{{ 'reglement.data.date'|trans }}</th>
        <td mat-cell *matCellDef="let reglement">{{ reglement.date|date:'shortDate' }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ 'reglement.table.total'|trans }}</td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="numero">{{ 'reglement.table.details'|trans }}</th>
        <td mat-cell *matCellDef="let reglement">
          <span *ngIf="reglement.mode">{{ reglement.mode|titlecase }} {{ reglement.numero ? ' - ' : '' }}</span>
          <span *ngIf="reglement.numero">Ref. {{ reglement.numero }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef>{{ getTotalCount() }}</td>
      </ng-container>

      <ng-container matColumnDef="montant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="montant">
          <span *ngIf="!adminMode">{{ 'reglement.data.montant'|trans }}</span>
          <mat-form-field *ngIf="adminMode" subscriptSizing="dynamic">
            <mat-label>
              <mat-icon>search</mat-icon> {{ 'reglement.data.montant'|trans }}
            </mat-label>
            <input matInput formControlName="montant" placeholder="Rechercher" (click)="$event.stopPropagation()">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let reglement">{{ reglement.montant|currency:'EUR' }}</td>
        <td mat-footer-cell *matFooterCellDef>
          <span>{{ getTotalMontant()|currency:'EUR' }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleSelect(row)"
        [ngClass]="{'selectable-row': adminMode, 'expanded': reglement === selectedReglement, 'is-test': row.isTest}">
      </tr>

      <ng-container *ngIf="afficheTotaux">
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </ng-container>

      <ng-container *ngIf="adminMode">

        <ng-container matColumnDef="numPiece">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="numPiece">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>
                <mat-icon>search</mat-icon> {{ 'reglement.data.numPiece'|trans }}
              </mat-label>
              <input matInput formControlName="numPiece" placeholder="Rechercher" (click)="$event.stopPropagation()">
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let reglement">{{ reglement.numPiece }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="owner">

            <mat-form-field subscriptSizing="dynamic">
              <mat-label>
                <mat-icon>search</mat-icon>{{ 'reglement.data.famille_adulte'|trans }}
              </mat-label>
              <input matInput formControlName="debiteur" placeholder="Rechercher" (click)="$event.stopPropagation()"
                (keydown)="$event.stopPropagation()">
            </mat-form-field>

          </th>
          <td mat-cell *matCellDef="let reglement">{{ reglement.debiteur }} ({{ reglement.login }})</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="mode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="mode">

            <mat-form-field subscriptSizing="dynamic">
              <mat-label>
                <mat-icon>search</mat-icon>{{ 'reglement.data.mode'|trans }}
              </mat-label>
              <input matInput formControlName="mode" placeholder="Rechercher" (click)="$event.stopPropagation()"
                (keydown)="$event.stopPropagation()">
            </mat-form-field>

          </th>
          <td mat-cell *matCellDef="let reglement">{{ reglement.mode|titlecase }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="regie">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="regie">
            {{ 'reglement.data.regie'|trans }}
          </th>
          <td mat-cell *matCellDef="let reglement">{{ reglement.regie }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let reglement" [attr.colspan]="displayedColumns.length">
            <div class="detail-row-inner"
              [@detailExpand]="selectedReglement && reglement.id === selectedReglement.id ? 'expanded' : 'collapsed'">
              <app-reglement-details [reglement]="reglement" (validate)="validate.emit($event)"
                (cancel)="cancel.emit($event)">
              </app-reglement-details>
            </div>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"
          [class.expanded]="reglement === selectedReglement"></tr>

      </ng-container>
      <!-- feature disponible seulement a partir de la version 10 -->
      <!-- <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Aucun règlements.</td>
      </tr> -->
    </table>
  </form>

</div>
<mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" [pageSize]="paginatorSize"
  showFirstLastButtons></mat-paginator>