<ng-template appTopbarActions>
  <button mat-raised-button color="accent" [matTooltip]="'reservation.action.add_tooltip'|trans"
    routerLink="/account/reservations/new">
    <mat-icon>add_box</mat-icon>
    <span>{{ 'reservation.action.add'|trans }}</span>
  </button>

  <button mat-raised-button color="accent-lighter" (click)="openHistory()">
    <mat-icon>history</mat-icon>
    <span *ngIf="(platformService.isMobile$ | async) === false">{{ 'reservation.view_mode.history'|trans }}</span>
  </button>

  <button mat-raised-button color="accent-lighter" [matTooltip]="'reservation.planning.options_button'|trans"
    (click)="togglePlanningOptions()">
    <span class="fx-direction-row fx-align-item-center">
      <mat-icon>settings</mat-icon>
    </span>
  </button>
</ng-template>

<mat-drawer-container>
  <mat-drawer-content>
    <app-reservation-planning *ngIf="!!data" [data]="data" editMode="readonly" [optionsPanelContainer]="optionsPanel">
    </app-reservation-planning>
  </mat-drawer-content>

  <mat-drawer position="end" [(opened)]="drawerOpened">
    <ng-container #optionsPanelContainer></ng-container>
  </mat-drawer>
</mat-drawer-container>

<ng-template #loader>
  <app-loader class="margin" [message]="'reservation.loader.data'|trans"></app-loader>
</ng-template>

<ng-template #history>

  <app-dialog-header title="Historique des réservations" />

  <mat-dialog-content>
    <app-user-reservations-list [data]="data"></app-user-reservations-list>
  </mat-dialog-content>
</ng-template>