import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatterService {

  constructor() { }

  getFormatFunction(format: string) {
    const formatters = {
      phonenumber: this.formatPhoneNumber,
      uppercase: this.formatUppercase,
      titlecase: this.formatTitlecase,
      email: this.formatEmail
    };

    return format in formatters ? formatters[format] : null;
  }

  formatPhoneNumber(value: string, options?: { separator?: string }) {
    const separator = options.separator || '.';

    if (value.endsWith(separator)) {
      return value;
    }

    let parts = value.match(/[\d]{1,2}/g);

    if (!(parts && parts.length)) {
      return value;
    }

    return parts.filter((part, i) => i < parts.length - 1 ? part.length > 1 : true).join(separator);
  }

  formatUppercase(value: string) {
    return value.toUpperCase();
  }

  formatEmail(value: string) {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  formatTitlecase(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
