import { Periode, PeriodeActivite } from '@app/models/periode';
import { Inscription } from '@app/models/inscription';
import { Consumer } from '@app/models/consumer';
import { ReservationPresence, Reservation } from '@app/models/reservation';
import { BehaviorSubject, Subject } from 'rxjs';
import moment from 'moment';
import { DATE_FORMAT } from '@app/services/planning.service';
import { computePresenceStatus } from '@app/services/reservation.service';
import { ConsumerAdapter } from '@app/services/adapter/consumer-adapter.service';

export interface DateData {
  date?: Date;
  dateStr?: string;

  startTime?: string;
  endTime?: string;
}

export interface ReservationConsumer extends Consumer {
  selected?: boolean;
  color?: string;
  initial?: string;

  // show?: boolean; // Could replace showChildren ...
}

export interface ApiPlanningData {
  presences?: ReservationPresence[];
  reservations?: Reservation[];
  activities?: ActivityDetails[];
  feries?: JourFerie[];
  usagers?: Consumer[];
  inscriptions?: Inscription[];
}

export interface ActivityDetails {
  id: number;
  periode: number;
  name: string;
  description: string;
  listeAttente?: boolean;
}

interface JourFerie {
  date: string;
  detail: string;
}

export interface DatesLoaded { start: string, end: string }

export class PlanningData {
  // periodes: Periode[] = [];
  currentPeriode: Periode = null;
  // plages?: { [id: number]: PeriodePlage };

  consumers: ReservationConsumer[];

  reservations: Reservation[] = [];
  inscriptions: Inscription[];

  currentReservation: Reservation = null;

  enabledActivities: PeriodeActivite[];
  activityDetails: ActivityDetails[] = [];

  firstEditableDate: string;
  firstCancelableDate: string;
  lastSaisieDate: string;
  lastCancelDate: string;

  onChangeUsager$ = new Subject<void>();
  onChangePeriode$ = new Subject<void>();

  feries: JourFerie[];

  // Triggered when current reservation presences are updated (we never update other reservations data)
  onPresenceUpdate$ = new Subject<void|'all'>();

  // For Optimizations
  loadedDates$: BehaviorSubject<DatesLoaded[]> = new BehaviorSubject([]);
  allDatesLoaded$: BehaviorSubject<Date> = new BehaviorSubject(null);
  // for reload some dates after a new reservation, because when user quit the edit component, 
  // as we don't know when he will need the "new" planning data, we store some dates here, and when he will need it, we will relaod only this dates
  datesToReload: DatesLoaded[] = [];
  loadingPeriode: boolean;

  getCurrentRubriques() {
    return this.currentPeriode.rubriques;
  }

  getCurrentActivities() {
    return this.currentPeriode.activites;
  }


  isCurrentConsumer(idConsumer: string) {
    // Probably faster & more reliable than checking currentChild
    return this.currentReservation?.idConsumer === idConsumer;
  }

  isCurrentInscription(id: number) {
    return this.currentReservation?.idInscription === id;
  }

  isCurrentPeriode(id: number, idPortail?: number) {
    return this.currentReservation && (this.currentReservation.idPeriode ?
      this.currentReservation.idPeriode === id :
      this.currentReservation.idPortailPeriode === idPortail);
  }

  isCurrentReservation(id) {
    return id && this.currentReservation?.id === id;
  }

  setCurrentPeriode(periode: Periode) {
    this.currentPeriode = periode;

    if (this.currentReservation) {
      this.currentReservation.idPeriode = periode ? periode.id : null;
      this.currentReservation.idPortailPeriode = periode ? periode.idPortail : null;

      if (periode) {
        this.currentReservation.etablissement = periode.nameEtablissement;
        this.currentReservation.accueil = periode.nameAccueil;
        this.currentReservation.periode = periode.label || periode.name;
      }
    }

    // if (periode) {
    //   this.plages = {};

    //   // need to do it for now, but instead server should send this "plages" as an array, and rubriques use only IDs
    //   this.currentPeriode.rubriques.forEach(rub => {
    //     if (rub.plages?.length) {
    //       rub.plages.forEach(pl => {
    //         if (!this.plages[pl.id]) {
    //           this.plages[pl.id] = pl;
    //         }
    //       });
    //     }
    //   });
    // }

    this.onChangePeriode$.next();

    return this;
  }

  setCurrentReservation(reservation: Reservation | null) {
    if (!reservation) { // remove this.currentReservation
      let found = this.reservations.findIndex(r => r.id === this.currentReservation?.id)
      if (found !== -1) {
        this.reservations.splice(found, 1)
      }
      this.currentReservation = null
    } else {
      this.currentReservation = reservation;

      let found = this.reservations.findIndex(r => r.id === reservation.id)
      if (found === -1) {
        this.reservations.push(reservation);
      } else {
        this.reservations[found] = reservation
      }

      return this;
    }
  }

  // No argument = get from all reservations
  getReservationsPresences(reservations?: Reservation[]): ReservationPresence[] {
    return [].concat(...(reservations || this.reservations).map(r => r.presences));
  }

  getConsumerPresences(idConsumer: string): ReservationPresence[] {
    return [].concat(...this.reservations.filter(r => r.idConsumer === idConsumer).map(r => r.presences));
  }

  getCurrentConsumerPresences() {
    return this.getConsumerPresences(this.currentReservation.idConsumer);
  }

  getInscriptionPresences(idInscription: number): ReservationPresence[] {
    return [].concat(...this.reservations.filter(r => r.idInscription === idInscription).map(r => r.presences));
  }

  getCurrentInscriptionPresences() {
    return this.getInscriptionPresences(this.currentReservation.idInscription);
  }

  getCurrentConsumerAndPeriodePresences() {
    const reservations = this.reservations.filter(r => {
      return this.isCurrentConsumer(r.idConsumer) && this.isCurrentPeriode(r.idPeriode, r.idPortailPeriode);
    });

    return [].concat(...reservations.map(r => r.presences)) as ReservationPresence[];
  }

  getReplacedPresences(): ReservationPresence[] {
    return [].concat(...this.reservations.map(r => r.presences.filter(pr => pr.replacedBy)));
  }

  findConsumer(id: string) {
    return this.consumers ? this.consumers.find(c => c.id === id) : null;
  }

  findInscription(id: number) {
    return this.inscriptions.find(insc => insc.id === id);
  }

  findReservation(id: number | string) {
    return this.reservations.find(r => r.id === id);
  }

  findPresence(id: number | string, reservation: Reservation | number | string) {
    reservation = (reservation && typeof reservation !== 'object' ? this.findReservation(reservation) : reservation) as Reservation;
    const presences = reservation ? reservation.presences : this.getReservationsPresences();

    return presences.find(pr => (pr.id || pr.tmpId) === id);
  }

  findActivity(id: number, idPeriode: number): ActivityDetails {
    if (this.currentPeriode && (this.currentPeriode.id === idPeriode)) {
      const act = this.currentPeriode.activites.find(a => a.id === id);

      if (act) {
        return { id, name: act.label || act.name, description: act.description, periode: idPeriode };
      }
    }

    return this.activityDetails.find(a => a.id === id && a.periode === idPeriode);
  }

  addPresence(pr: ReservationPresence, triggerUpdate = true) {
    pr.reservation = this.currentReservation.id;
    this.currentReservation.presences.push(pr);

    if (triggerUpdate) {
      this.onPresenceUpdate$.next();
    }
  }

  addPresences(prs: ReservationPresence[], triggerUpdate = true) {
    prs.forEach(pr => this.addPresence(pr, false));

    if (triggerUpdate) {
      this.onPresenceUpdate$.next();
    }
  }

  removePresence(presence: ReservationPresence, triggerUpdate = true) {
    this.currentReservation.presences = this.currentReservation.presences.filter(pr => pr.tmpId !== presence.tmpId);

    if (triggerUpdate) {
      this.onPresenceUpdate$.next();
    }
  }

  resetPresences() {
    this.currentReservation.presences = [];
    this.onPresenceUpdate$.next();
  }

  isEditableDate(date: string) {
    return this.currentPeriode && this.currentPeriode.days.some(d => d.date === date) && this.firstEditableDate <= date;
  }

  // only mikado
  isEditableDateBySlot(date: string) {
    if (this.currentPeriode.modeSelection && this.currentPeriode.modeSelection !== "free") {
      return this.currentPeriode.dispoPlanning.some(d => d.date === date);
    } else {
      return this.isEditableDate(date);
    }
  }

  isCancelableDate(date: string) {
    return this.currentPeriode && this.currentPeriode.allowCancel && this.firstCancelableDate <= date;
  }

  getRealFirstEditableDate() {
    let firstPeriodeDay = null;

    this.currentPeriode.days.forEach(d => {
      if (this.isEditableDate(d.date) && (!firstPeriodeDay || d.date < firstPeriodeDay)) {
        firstPeriodeDay = d.date;
      }
    });

    return firstPeriodeDay > this.firstEditableDate ? firstPeriodeDay : this.firstEditableDate;
  }

  isLoadedDate(startDate: string, endDate: string) {
    // const formattedMonth = month.length < 6 ? month + '-00' : month;
    if (!!this.allDatesLoaded$.value) {
      return true
    }

    if (this.loadedDates$.value.some(ld => ld.start <= startDate && ld.end >= startDate && ld.start <= endDate && ld.end >= endDate)) {
      return true
    } else {
      return false
    }
  }

  setLoadedDates(dates: { start: string, end: string } | 'all') {
    if (dates === 'all') {
      this.allDatesLoaded$.next(new Date());
    } else {
      let loadedDates = this.loadedDates$.value
      loadedDates.push(dates);
      this.loadedDates$.next(loadedDates)
    }
  }

  addPlanningData(data: ApiPlanningData) {
    if (!data) {
      return;
    }

    data.reservations?.forEach(resa => {
      if (!this.reservations.some(r => r.id === resa.id)) {
        if (!resa.presences) {
          resa.presences = [];
        }
        this.reservations.push(resa);
      }
    });

    data.presences?.forEach(pr => {
      const resa = this.findReservation(pr.reservation);
      if (!resa.presences.some(p => p.id === pr.id)) {
        resa.presences.push(pr);
        pr.status = pr.status || computePresenceStatus(pr, resa);
      }
    });

    data.activities?.forEach(act => this.activityDetails.push(act));

    if (data.feries) {
      this.feries = data.feries;
    }

    this.clearReplacedByExpired();
  }

  clearReplacedByExpired() {
    const replacedPresences = this.getReplacedPresences();

    this.reservations.forEach(resa => {
      if (resa.state === 'Expiree') {
        resa.presences.forEach(expired => {
          const replacedByExpired = replacedPresences.find(pr => pr.replacedBy === expired.id);

          if (replacedByExpired) {
            replacedByExpired.replacedBy = null;
          }
        });
      }
    });
  }

  getCurrentMonthBounds() {
    // we add some days to cover previous / next month first or last week, shown by FullCalendar ...
    const start = moment().startOf('month').subtract(10, 'd');
    const end = moment().endOf('month').add(10, 'd');

    return { start: start.format(DATE_FORMAT), end: end.format(DATE_FORMAT) };
  }

  triggerAllChanges(usagersHaveChanged?: boolean) {
    if (usagersHaveChanged) this.onChangeUsager$.next();
    // this.onChangePeriode.next(); // => not a good idea as it will call 'resetPresences' when the user may have already entered something (in edit mode)
    this.onPresenceUpdate$.next('all');
  }

  setUsagers(usagers: Consumer[], inscriptions: Inscription[]): boolean {
    const consumersNew = this.adapatUsagers(usagers)
      .map(c => { c.inscriptions = inscriptions.filter(i => (i.idEnfant === c.idEnfant && i.idAdulte === c.idAdulte)); return c })
      .filter(c => c.inscriptions?.length);

    const buildFingerPrint = (cons: Consumer[], insc: Inscription[]) => ((cons.map(c => c.id).sort().join(';')) + '#' + (insc.map(i => i.id).sort().join(';')).trim())
    const fingerPrintNew = buildFingerPrint(consumersNew, inscriptions)
    const fingerPrintKnown = buildFingerPrint(this.consumers || [], this.inscriptions || [])

    if (!this.consumers?.length || fingerPrintNew !== fingerPrintKnown) { // be sure we do that only if needed
      this.consumers = consumersNew//.filter(c => c.inscriptions?.length);
      this.inscriptions = inscriptions;
      return true
    } else {
      return false
    }
  }

  // Because we are in simple Class, we can't use dependency injection from Angular
  // so we instanciate our own ConsumerAdapter
  private _consumerAdapter: ConsumerAdapter
  get consumerAdapter() {
    if (!this._consumerAdapter) {
      this._consumerAdapter = new ConsumerAdapter()
    }
    return this._consumerAdapter
  }

  adapatUsagers(usagers: Consumer[]) {
    return usagers
      // Adapt
      .map(usager => this.consumerAdapter.adapt(usager))
      // Tri des Usagers par Age, puis par nom / prenom
      .sort((c1, c2) => {
        if (c1.age.years < c2.age.years) {
          return -1;
        } else if (c1.age.years > c2.age.years) {
          return 1;
        } else {
          if (c1.prenom + c1.nom < c2.prenom + c2.nom) {
            return -1;
          }
        }
        return 0;
      })
  }
}
