import { Component, ViewChild, OnInit } from '@angular/core';
import { FieldType,FieldTypeConfig } from '@ngx-formly/core';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'formly-autocomplete-type',
    template: `
    <input matInput
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let value of filter | async" [value]="value">
        {{ value }}
      </mat-option>
    </mat-autocomplete>
    `,
    standalone: false
})
export class AutocompleteTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild(MatInput) formFieldControl: MatInput;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  filter: Observable<any>;

  ngOnInit() {

    if (this.props.activeSuggestionList) {
      this.props.filter = (term) => of(term ? this.filterList(term, this.to.suggestionList) : this.to.suggestionList.slice());
    }

    this.filter = this.formControl.valueChanges
      .pipe(
        startWith(''),
        switchMap(term => this.props.filter(term)),
      );

  }

  filterList(name: string, suggestionList: string[]) {
    return suggestionList.filter(state =>
      state.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

}
