import { Component, OnInit, NgZone, ElementRef, AfterViewInit, forwardRef, OnDestroy, ViewChild, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { monacoWLangageDef, monacoWLangageConfig, monacoWLangageTheme, completionItemProvider } from './monaco-wlangage.js';

let loadedMonaco = false;
let loadPromise: Promise<void>;

@Component({
    selector: 'app-code-editor',
    template: '<div class="editor-container" #editorContainer></div>',
    styles: [`
    .editor-container {
      height: 100%;
      width: 100%;
    }
  `],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CodeEditorComponent),
            multi: true
        }],
    standalone: false
})
export class CodeEditorComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {

  @Input() options: any;

  @ViewChild('editorContainer', { static: true }) editorContainer: ElementRef;

  protected editor: monaco.editor.ICodeEditor;

  config = {} as any;

  private onChange: (_: any) => {};
  private onTouch: () => {};

  private bufferValue = '';

  constructor(
    private ngZone: NgZone
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadMonaco();
  }

  loadMonaco() {
    if (loadedMonaco) {
      // Wait until monaco editor is available
      loadPromise.then(() => {
        this.initMonaco(this.options);
      });
    } else {
      loadedMonaco = true;
      loadPromise = new Promise<void>((resolve: any) => {
        const baseUrl = this.config.baseUrl || './assets';
        const safeWindow = window as any;

        if (typeof safeWindow.monaco === 'object') {
          resolve();
          return;
        }

        const onGotAmdLoader: any = () => {
          // Set french locale, for Monaco UI
          safeWindow.require.config({
            paths: { vs: `${baseUrl}/monaco/vs` },
            'vs/nls': { availableLanguages: { '*': 'fr' } }
          });

          // Load monaco
          safeWindow.require(['vs/editor/editor.main'], () => {
            if (typeof this.config.onMonacoLoad === 'function') {
              this.config.onMonacoLoad();
            }

            if (this.options?.language === 'wlangage') {
              this.addWLangage();
            }

            this.initMonaco(this.options);
            resolve();
          });
        };

        // Load AMD loader if necessary
        if (!safeWindow.require) {
          const loaderScript: HTMLScriptElement = document.createElement('script');
          loaderScript.type = 'text/javascript';
          loaderScript.src = `${baseUrl}/monaco/vs/loader.js`;
          loaderScript.addEventListener('load', onGotAmdLoader);
          document.body.appendChild(loaderScript);
        } else {
          onGotAmdLoader();
        }
      });
    }
  }

  addWLangage() {
    // Our own wlangage plugin :)
    monaco.languages.register({ id: 'wlangage' });

    // Syntax highlight only for now, PC Soft Doc. website has no kind of API we could link to ...
    monaco.languages.setMonarchTokensProvider('wlangage', monacoWLangageDef as monaco.languages.IMonarchLanguage);
    monaco.languages.setLanguageConfiguration("wlangage", monacoWLangageConfig as monaco.languages.LanguageConfiguration);

    // Register a completion item provider for the WLanguage
    //  but don't register it for specific 'wlangage' because we lose native autocompletion (textual suggestions)
    monaco.languages.registerCompletionItemProvider("*", completionItemProvider as monaco.languages.CompletionItemProvider)
    // monaco.languages.registerCompletionItemProvider("wlangage", completionItemProvider)

    monaco.editor.defineTheme("WlCustomTheme", monacoWLangageTheme as monaco.editor.IStandaloneThemeData);

    this.options = this.options || {};
    this.options.theme = "WlCustomTheme";

  }

  protected initMonaco(options: monaco.editor.IEditorConstructionOptions): void {
    options = options || {}
    // options.quickSuggestions = false;
    options.bracketPairColorization = { enabled: true, independentColorPoolPerBracketType: true }


    this.editor = monaco.editor.create(this.editorContainer.nativeElement, options as monaco.editor.IEditorConstructionOptions);

    this.editor.setValue(this.bufferValue);

    this.editor.onDidChangeModelContent((e: any) => {
      const value = this.editor.getValue();

      // value is not propagated to parent when executing outside zone.
      this.ngZone.run(() => {
        this.onChange(value);
        this.bufferValue = value;
      });
    });

    this.editor.onDidBlurEditorWidget(() => {
      this.onTouch();
    });

    // this.onInit.emit(this.editor);
  }

  getEditor() {
    return this.editor;
  }

  writeValue(value: any): void {
    this.bufferValue = value || '';
    // Fix for value change while dispose in process.
    setTimeout(() => {
      if (this.editor && !this.options.model) {
        this.editor.setValue(this.bufferValue);
      }
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.editor) {
      // this.editor.editorIsActive = !isDisabled;
    }
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.dispose();
    }
  }
}
