import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormlyModule } from '@ngx-formly/core';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiPrefixInterceptor, AuthInterceptor, ErrorInterceptor, SessionInterceptor, WaitingRoomInterceptor } from './services/interceptors';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared.module';
import { PublicModule } from './modules/public.module';
import { UserModule } from './modules/user.module';

import { GlobalConfigService } from './services/global-config.service';
import { AuthenticationService } from './services';
import { SessionService } from './services';
import { AuthenticationGuard } from './guards/authentication.guard';
import { PermissionGuard } from './guards/permission.guard';
import { VariousGuard } from './guards/various.guard';
import { TotpGuard } from './guards/totp.guard';
import { WaitingRoomGuard } from './guards/waiting-room.guard';

import { fieldMatchValidator } from './validators/field-match-validator';
import { EmailValidator, EmailValidatorMessage } from './validators/email.validator';
import { ZipCodeValidator, ZipCodeValidatorMessage } from './validators/zipcode.validator';
import { PhoneNumberValidator, PhoneNumberValidatorMessage } from './validators/phone-number.validator';
import { CountValidator, CountValidatorMessage } from './validators/count.validator';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/_common/header/header.component';
import { FooterComponent } from './components/_common/footer/footer.component';
import { MainComponent } from './components/_common/main/main.component';
import { MenuItemComponent } from './components/_common/menu/item/item.component';
import { AppUpdateBandComponent } from './components/_common/app-update-band/app-update-band.component';

import { PanelWrapperComponent } from './components/_elements/panel-wrapper/panel-wrapper.component';
import { RepeatTypeComponent } from './components/_elements/repeat-section.type.component';
import { DataTableTypeComponent, DataTableTypeDialogComponent } from './components/_elements/datatable-type';
import { ArrayFromDefaultValueComponent } from './components/_elements/array-from-default-value.type.component';
import { AdresseAutocompleteComponent } from './components/_elements/adresse-autocomplete/adresse-autocomplete.component';
import { FormOrderListComponent } from './components/_elements/form-order-list/form-order-list.component';
import { FormOuiNonTypeComponent } from './components/_elements/form-oui-non-type/form-oui-non-type.component';
import { EditorTypeComponent } from './components/_elements/editor-type/editor-type.component';
import { ChampHeureComponent } from './components/_elements/champ-heure/champ-heure.component';
import { FormMessageComponent } from './components/_elements/form-message.type.component';
import { FormHiddenFieldComponent } from './components/_elements/form-hidden.type.component';
// import { NewPasswordComponent } from './components/_elements/new-password/new-password.component';
import { NewPasswordTypeComponent } from './components/_elements/new-password/new-password-type.component';
import { WeekProgrammationTypeComponent } from './components/_elements/week-programmation/week-programmation.component';
import { SingleNewsComponent } from './components/_public/news/single-news/single-news.component';
import { AutocompleteTypeComponent } from './components/_elements/autocomplete.type.component';

// For standard pipe in french
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { LOCALE_ID } from '@angular/core';
import { LengthValidator } from './validators/length.validator';
import { PortailTransLoader } from './services/translate-loader.service';
import { SuggestionTypeComponent } from './components/_elements/suggestion-type/suggestion-type.component';
import { SuggestionDialogComponent } from './components/_elements/suggestion-type/suggestion-dialog/suggestion-dialog.component';
import { SuggestionValidator, SuggestionValidatorMessage } from './validators/suggestion.validator';
import { SubheaderComponent } from './components/_common/subheader/subheader.component';
import { FileUploadComponent } from './components/_admin/file/file-upload/file-upload.component';
import { DragAndDropFileDirective } from './directives/drag-and-drop-file.directive';
import { EtablissementListComponent } from './components/_elements/etablissement-list/etablissement-list.component';
import { MessagerieComponent } from './components/_user/messagerie/messagerie.component';
import { NewMessageComponent } from './components/_user/messagerie/new-message/new-message.component';
import { PersonalizedContentComponent } from './components/_admin/personalized-content/personalized-content.component';
import { ContentEditComponent } from './components/_admin/personalized-content/content-edit/content-edit.component';
import { WaitingRoomComponent } from '@app/components/_user/reservation/waiting-room/waiting-room.component';
import { WaitingRoomErrorComponent } from '@app/components/_user/reservation/waiting-room/error/waiting-room-error.component';
import { WaitingRoomNotifierComponent } from '@app/components/_user/reservation/waiting-room/notifier/waiting-room-notifier.component';
import { ChatBubbleComponent } from './components/_elements/chat-bubble/chat-bubble.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { MessagerieBoardComponent } from './components/_admin/messagerie-board/messagerie-board.component';
import { HighlightPipe } from './highlight.pipe';
import { ResponseMessageComponent } from './components/_admin/messagerie-board/response-message/response-message.component';
import { SingleContentComponent } from './components/_common/single-personalized-content/single-content.component';
import { PageNotFoundComponent } from './components/_common/page-not-found/page-not-found.component';
import { WaitingRoomConfirmComponent } from './components/_user/reservation/waiting-room/confirm/waiting-room-confirm.component';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SubheaderComponent,
    FooterComponent,
    MainComponent,
    MenuItemComponent,
    PanelWrapperComponent,
    RepeatTypeComponent,
    DataTableTypeComponent,
    DataTableTypeDialogComponent,
    ArrayFromDefaultValueComponent,
    AdresseAutocompleteComponent,
    FormOrderListComponent,
    ChampHeureComponent,
    FormMessageComponent,
    AutocompleteTypeComponent,
    FormHiddenFieldComponent,
    SingleNewsComponent,
    EditorTypeComponent,
    FormOuiNonTypeComponent,
    AppUpdateBandComponent,
    // NewPasswordComponent,
    NewPasswordTypeComponent,
    WeekProgrammationTypeComponent,
    SuggestionTypeComponent,
    SuggestionDialogComponent,
    FileUploadComponent,
    DragAndDropFileDirective,
    EtablissementListComponent,
    MessagerieComponent,
    NewMessageComponent,
    EtablissementListComponent,
    ChatBubbleComponent,
    DateAgoPipe,
    MessagerieBoardComponent,
    HighlightPipe,
    ResponseMessageComponent,
    PersonalizedContentComponent,
    ContentEditComponent,
    SingleContentComponent,
    PageNotFoundComponent,
    WaitingRoomComponent,
    WaitingRoomErrorComponent,
    WaitingRoomNotifierComponent,
    WaitingRoomConfirmComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ScrollingModule,
    TranslateModule.forRoot(),
    NgcCookieConsentModule.forRoot({
      cookie: { domain: environment.domain }, autoOpen: false
    }),
    FormlyModule.forRoot({
      extras: {
        // checkExpressionOn: 'changeDetectionCheck',
        // lazyRender: false,
        // resetFieldOnHide: false
      },
      types: [
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'datatable', component: DataTableTypeComponent },
        {
          name: 'suggestion', component: SuggestionTypeComponent, defaultOptions: {
            defaultValue: {}
          }
        },
        { name: 'arrayFromDefaultValue', component: ArrayFromDefaultValueComponent },
        { name: 'orderList', component: FormOrderListComponent },
        { name: 'adresseAutocomplete', component: AdresseAutocompleteComponent },
        { name: 'heure', component: ChampHeureComponent },
        { name: 'editor', component: EditorTypeComponent },
        { name: 'ouiNon', component: FormOuiNonTypeComponent },
        { name: 'message', component: FormMessageComponent },
        { name: 'hidden', component: FormHiddenFieldComponent },
        { name: 'new_password', component: NewPasswordTypeComponent },
        { name: 'inputAutocomplete', component: AutocompleteTypeComponent, wrappers: ['form-field'] },
        { name: 'week_programmation', component: WeekProgrammationTypeComponent },
      ],
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent }
      ],
      validators: [
        { name: 'fieldMatch', validation: fieldMatchValidator },
        { name: 'email', validation: EmailValidator },
        { name: 'zipCode', validation: ZipCodeValidator },
        { name: 'phoneNumber', validation: PhoneNumberValidator },
        { name: 'count', validation: CountValidator },
        { name: 'suggestion', validation: SuggestionValidator }
      ],
      validationMessages: [
        { name: 'required', message: 'Obligatoire' },
        { name: 'minLength', message: LengthValidator.min },
        { name: 'maxLength', message: LengthValidator.max },
        { name: 'fieldMatch', message: 'Erreur de confirmation' },
        { name: 'email', message: EmailValidatorMessage },
        { name: 'zipCode', message: ZipCodeValidatorMessage },
        { name: 'phoneNumber', message: PhoneNumberValidatorMessage },
        { name: 'count', message: CountValidatorMessage },
        { name: 'suggestion', message: SuggestionValidatorMessage }
      ]
    }),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: PortailTransLoader }
    }),
    SharedModule,
    AppRoutingModule,
    PublicModule,
    UserModule,
    // AdminModule, <= disabled for lazy loading
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    AuthenticationService,
    SessionService,
    AuthenticationGuard,
    PermissionGuard,
    VariousGuard,
    TotpGuard,
    WaitingRoomGuard,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WaitingRoomInterceptor, multi: true },
    GlobalConfigService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
