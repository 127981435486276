import { Injectable } from '@angular/core';
import { ApiCrudService } from './api-crud.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormHelperService } from './form-helper.service';
import { SnackbarService } from './snackbar.service';
import { FamilyService } from './family.service';

export interface ProgrammePerso {
  id: number;
  title: string;
  description: string;
  code: string;
  category: string;
  usedBy: string[];
}

export interface ProgrammePersoCategory {
  key: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProgrammePersoService extends ApiCrudService<ProgrammePerso> {

  url = 'programme-perso';
  categoriesUrl = 'programme-perso-categories';

  cachedCategories$ = new BehaviorSubject<ProgrammePersoCategory[]>([]);
  cachedCategoriesValue: ProgrammePersoCategory[] = [];

  constructor(
    protected http: HttpClient,
    private helperService: FormHelperService,
    private snackbar: SnackbarService,
    private familyService: FamilyService
  ) {
    super();
  }

  getAllByCategories(categories: string[]): Observable<ProgrammePerso[]> {
    const caterogiesList = categories.toString();
    return this.http.get<ProgrammePerso[]>(this.url + '?categories=' + caterogiesList);
  }

  getCategories(): Observable<ProgrammePersoCategory[]> {
    if (this.cachedCategoriesValue.length === 0) {
      return this.http.get<ProgrammePersoCategory[]>(this.categoriesUrl, this.httpOptions).pipe(
        tap(categories => {
          this.cachedCategoriesValue = categories.sort((c1, c2) => (c1.title < c2.title ? -1 : 1));
          this.cachedCategories$.next(categories);
        })
      );
    } else {
      return of(this.cachedCategoriesValue);
    }
  }

  executeProgrammePersoForm(data: any, stepName: string, typeForm: string, idFamille: number, idAssmat: number) {
    return this.http.post("programme-perso/form", { data, stepName, typeForm, idFamille, idAssmat });
  }

  executeProgrammePersoInscDia(data: any, idFamille: number) {
    return this.http.post("programme-perso/inscription/diabolo", { data, idFamille });
  }

  executeProgrammePersoLasidoPreinscription(data: any) {
    data.idFamille = this.familyService.currentFamily.id;
    return this.http.post("programme-perso/preinscription/lasido", { data });
  }

  executeProgrammePersoLasidoInscription(data: any) {
    return this.http.post("programme-perso/inscription/lasido", { data });
  }

  displayErrorsTracesMessagesProgram(result) {
    // Get errors & traces from programs
    this.helperService.displayDebugTraces(result.traces);

    if (result.messages && !result?.errors) {
      this.helperService.notifySuccess("", result.messages);
    }

    if (result.errors && result.errors.length > 0) {
      let errorMessage = '';
      if (result.errors.length > 1) {
        result.errors.forEach(e => errorMessage += `<li>${e}</li>`);
      } else {
        errorMessage = result.errors[0];
      }
      this.snackbar.error(errorMessage);
      throw new Error(errorMessage);
    }
  }

}
