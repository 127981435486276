import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnimationReunion, AnimationReunionAll } from '@app/models/animationReunion';
import { ContratAccueilMentalo, EnfantMentalo } from '@app/models/contrat-accueil';
import { PlatformService, SnackbarService, UserService } from '@app/services';
import { AssmatService } from '@app/services/assmat.service';
import moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-inscription-reunions',
    templateUrl: './inscription-reunions.component.html',
    styleUrls: ['./inscription-reunions.component.scss'],
    standalone: false
})
export class InscriptionReunionsComponent implements OnInit {

  idAssmat: number;
  listContrats: ContratAccueilMentalo[];
  listChild: EnfantMentalo[];
  listReunions: AnimationReunion[];
  listReunionsFiltered: AnimationReunion[];
  saving = false;
  drawerOpened = false;
  RegisterChildDialogRef: MatDialogRef<any>;
  registerChildList: number[];
  filters = {
    search: "",
    etablissement: 0,
    fromDate: "",
    toDate: ""
  };

  @ViewChild('RegisterChildDialog', { static: true }) RegisterChildDialog: TemplateRef<any>;

  constructor(
    private assmatService: AssmatService,
    private userService: UserService,
    private matDialog: MatDialog,
    private platformService: PlatformService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.idAssmat = this.userService.currentUser.idAssmat || null;
    this.loadReunions();
  }

  loadReunions() {
    forkJoin([
      this.assmatService.getReunionsByIdAssmat(this.idAssmat),
      this.assmatService.getEnfantsMentaloByIdAssMat(this.idAssmat),
      this.assmatService.getContratsAccueilByIdAssMat(this.idAssmat)
    ]).subscribe(([listReu, childList, contrats]: [AnimationReunionAll, EnfantMentalo[], ContratAccueilMentalo[]]) => {
      this.listContrats = contrats;
      this.listChild = childList;
      this.listReunions = this.assmatService.filtrerAnimationsReunionAvailables(listReu.tabAvailable);
      this.listReunions = this.assmatService.sortByDate(this.listReunions);
      this.listReunionsFiltered = this.listReunions;
    })
  }

  openRegisterChildDialog(reunion) {
    let listChild = this.assmatService.getChildListWithContratEnable(this.listContrats, this.listChild, reunion.date);
    this.RegisterChildDialogRef = this.matDialog.open(this.RegisterChildDialog, {
      data: { listChild, reunion },
      maxWidth: '700px',
      minWidth: 'min(500px, 100%)'
    });

    this.platformService.adaptDialogToScreen(this.RegisterChildDialogRef);

    this.RegisterChildDialogRef.afterClosed().subscribe(_ => {
      this.registerChildList = [];
    });
  }

  clickToConfirm(animationReunion: AnimationReunion) {
    let animationReunionPortail = {
      idReunion: animationReunion.idReunion,
      idAssmat: this.idAssmat,
    }

    this.saving = true;
    this.assmatService.inscriptionAnimationReunion(
      this.idAssmat, {
      idPortailAnimationReunionAssmat: animationReunion.portailInscription?.idPortailAnimationReunionAssmat,
      selectedChild: this.registerChildList,
      animationReunionPortail
    }).subscribe(_ => {
      animationReunion.saving = true;
      this.loadReunions();
      this.RegisterChildDialogRef.close();
      this.registerChildList = [];
      this.saving = false;
      this.snackbar.info('Inscription Réussie');
    }, err => this.snackbar.error('Erreur lors de l\'inscription'))
  }

  cancel() {
    this.RegisterChildDialogRef.close();
    this.registerChildList = [];
  }

  closeDrawer() {
    this.drawerOpened = false;
  }

  toggleReunionsOptions() {
    this.drawerOpened = !this.drawerOpened;
  }

  changeFilter(event) {
    if (event) {
      this.listReunionsFiltered = this.listReunions.filter(reunion => {
        return (reunion.titre.toLowerCase().includes(this.filters.search.toLowerCase()) || this.filters.search === '') &&
          (moment(reunion.date).isSameOrAfter(this.filters.fromDate, 'day') || !moment(this.filters.fromDate).isValid()) &&
          (moment(reunion.date).isSameOrBefore(this.filters.toDate, 'day') || !moment(this.filters.toDate).isValid());
      })
    }
  }

}
