<app-dialog-header title="Modifier le document '{{title}}'" />
<div mat-dialog-content>
  <div class="section-admin-subtitle">
    <app-icon type="mat" icon="arrow_right" color="primary"></app-icon>
    Renommer en :
  </div>
  <mat-form-field style="width: 80%; margin: auto; display: block;" subscriptSizing="dynamic">
    <input matInput [(ngModel)]="file.name">
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="file" cdkFocusInitial color="primary">Valider</button>
</div>