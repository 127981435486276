import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { Usager } from '@app/models/consumer';
import { FamilyService, FormHelperService, MenuService, PlatformService, SnackbarService } from '@app/services';
import { LasidoService } from '@app/services/lasido.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AccueilTreeItem, LasidoChoiceGroup, LasidoPreInscriptionConfig, LasidoCourse, LasidoDisciplineTree, LasidoPreinscription, Departement, AlreadyTaggedNiveauCours } from '@app/models/lasido';
import { DemarcheFormReponse } from '@app/models/demarche';
import { ProgrammePersoService } from '@app/services/programme-perso.service';

@Component({
  selector: 'app-lasido-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  standalone: false
})
export class LasidoPreinscriptionEditComponent implements OnInit, OnDestroy {

  idFromUrl: number;
  title = 'Enregistrement d\'une pré-inscription'; // @TODO: set something more accurate like "update" / "create" + annee scolaire ?
  isMobile: boolean;

  config: LasidoPreInscriptionConfig;
  options: { usagers: Usager[], etablissements: AccueilTreeItem[] };
  selectedUsager: Usager;
  selectedEtab: AccueilTreeItem;
  selectedAccueil: AccueilTreeItem;

  hideEtablissement: boolean;
  hideAccueil: boolean;

  disciplineTree: LasidoDisciplineTree;
  alreadyTagged: AlreadyTaggedNiveauCours[];

  preinscription: LasidoPreinscription;

  isPreinscriptionValid = false;
  unsavedChanges = false;
  isEdit = false;
  saving = false;

  // Choice group dialog data
  editChoiceGroup: LasidoChoiceGroup;
  disciplineChoices: any[];
  niveauChoices: any[];
  selectedDisc: any; // LasidoDiscipline
  disciplinesAvailable: boolean;
  nbDisciplineMaxDepartementSelected: number;
  libelleDepartementSelected: string;

  // Cours dialog data
  coursChoices: any[];
  coursMaxSelectLeft: number;

  errorMessageProgPerso: boolean;

  private onDestroy$ = new Subject<void>();

  @Input() fromDemarche: boolean;
  @Input() usager: any;
  @Output() save = new EventEmitter<DemarcheFormReponse>();

  @ViewChild('editChoiceGroupDialog') editChoiceGroupDialog;
  @ViewChild('coursSelectDialog') coursSelectDialog;

  coursSelectDialogRef: MatDialogRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private familyService: FamilyService,
    private lasidoService: LasidoService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private menuService: MenuService,
    private programmePersoService: ProgrammePersoService,
    private helperService: FormHelperService,
    private platformService: PlatformService
  ) { }

  ngOnInit(): void {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe((value) => {
      this.isMobile = value;
    });

    if (!this.fromDemarche) {
      this.idFromUrl = this.route.snapshot.paramMap.has('id') ? +this.route.snapshot.paramMap.get('id') : null;
    }

    this.loadData().subscribe(_ => {
      this.resetErrors();

      // already exists
      if (this.isEdit) {
        this.reloadDisciplineTree().pipe(filter(res => !!res)).subscribe(() => {
          this.preinscription.choiceGroups.forEach(cg => {
            this.lasidoService.setGroupAttributes(this.disciplineTree, cg, this.config);
          });

          this.updateValidStatus();
        });
      } else {
        // init preinscription with right anneeScolaire
        this.preinscription.anneeScolaire = this.config.anneeScolaire;

        if (this.config.idAccueil) {
          this.hideAccueil = true;
          this.hideEtablissement = true;
          this.preinscription.idAccueil = this.config.idAccueil;
        } else {
          let presetEtab;

          if (this.config.idEtablissement) {
            presetEtab = this.options.etablissements.find(e => e.id === this.config.idEtablissement);
          } else if (this.options.etablissements.length === 1) {
            presetEtab = this.options.etablissements[0];
          }

          this.selectedEtab = presetEtab;
          this.hideEtablissement = !!presetEtab;
        }

        if (this.fromDemarche && this.usager) {
          this.selectedUsager = this.options.usagers.find(usager => usager.id === this.usager.id && usager.type === this.usager.type);
          this.preinscription.idAdulte = this.selectedUsager.type === 'adulte' ? this.selectedUsager.id : null;
          this.preinscription.idEnfant = this.selectedUsager.type === 'enfant' ? this.selectedUsager.id : null;
          this.reloadDisciplineTree().subscribe();
        }
      }
    });
  }

  loadData() {
    // we need a family context
    return this.familyService.currentFamilyReadyOnce$.pipe(
      switchMap(family => {

        const loaders = [];

        if (this.idFromUrl) {
          this.isEdit = true;
          // load edit preinscription
          loaders.push(this.lasidoService.getPreinscription(family, this.idFromUrl).pipe(tap(result => {
            this.preinscription = result.preinscription;
            this.config = result.conf;
            this.programmePersoService.displayErrorsTracesMessagesProgram(result);
            this.preinscription.choiceGroups.forEach((group) => {
              group.cours.sort((a, b) => a.ordre - b.ordre)
            });
          })));
        } else {
          this.preinscription = { choiceGroups: [] } as LasidoPreinscription;
          loaders.push(this.lasidoService.getConfigPreinscriptionForUser().pipe(tap(c => this.config = c)));
          loaders.push(this.lasidoService.getOptions(family).pipe(tap(o => this.options = o)));
        }

        return forkJoin(loaders);
      })
    );
  }

  askForConfirm(message: string) {
    if (this.unsavedChanges && this.preinscription.choiceGroups?.length) {
      const ref = this.dialog.open(ConfirmDialogComponent, {
        data: { message: this.translate.instant(message) }
      });

      this.platformService.adaptDialogToScreen(ref);

      return ref.afterClosed();
    } else {
      return of(true);
    }
  }

  onChangeUsager(event: MatSelectChange) {
    const usager = event.value as Usager;
    const select = event.source;

    this.askForConfirm('lasido.edit.confirm_change_usager').subscribe(res => {
      if (res) {
        this.selectedUsager = usager;

        this.preinscription.idAdulte = usager.type === 'adulte' ? usager.id : null;
        this.preinscription.idEnfant = usager.type === 'enfant' ? usager.id : null;

        this.preinscription.choiceGroups = [];

        this.unsavedChanges = false;
        this.updateValidStatus();

        this.reloadDisciplineTree().subscribe();
      } else {
        select.writeValue(this.selectedUsager);
      }
      this.runProgramsOnChangeUser();
    });
  }

  onChangeEtab(event: MatSelectChange) {
    this.askForConfirm('lasido.edit.confirm_change_etab').subscribe(resp => {
      if (resp) {
        this.selectedEtab = event.value as AccueilTreeItem;

        this.preinscription.idAccueil = null;
        this.preinscription.choiceGroups = [];
        this.disciplineTree = null;

        this.unsavedChanges = false;
        this.updateValidStatus();
      } else {
        event.source.writeValue(this.selectedEtab);
      }
    });
  }

  onChangeAccueil(event: MatSelectChange) {
    this.askForConfirm('lasido.edit.confirm_change_accueil').subscribe(resp => {
      if (resp) {
        this.selectedAccueil = event.value as AccueilTreeItem;

        this.preinscription.idAccueil = this.selectedAccueil.id;
        this.preinscription.choiceGroups = [];

        this.unsavedChanges = false;
        this.updateValidStatus();
        this.reloadDisciplineTree().subscribe();
      } else {
        event.source.writeValue(this.selectedAccueil);
      }
    });
  }

  reloadDisciplineTree() {
    this.disciplineTree = null; // reset (loader while loading)

    const idFamily = this.familyService.currentFamily?.id;
    const usagerType = this.preinscription.idAdulte ? 'adulte' : 'enfant';
    const usager = this.preinscription.idAdulte || this.preinscription.idEnfant;
    const annee = this.preinscription.anneeScolaire;
    const accueil = this.preinscription.idAccueil;

    if (!usager || !annee || !accueil) {
      return of(null);
    }

    // load departements / disciplines / niveaux available for usager
    return this.lasidoService.getAvailableLevels(idFamily, usagerType, usager, annee, accueil).pipe(
      tap(tree => {
        this.disciplineTree = tree.departements;
        this.alreadyTagged = tree.alreadyTagged;
      })
    );
  }

  addChoiceGroup() {
    this.editChoiceGroup = {} as LasidoChoiceGroup;

    if (!this.config.afficherDepartement) {
      this.disciplineChoices = [].concat(...this.disciplineTree.map(dep => dep.disciplines));
      this.onChangeDiscipline(null);
    } else {
      this.onChangeDepartment(null);
    }

    const ref = this.dialog.open(this.editChoiceGroupDialog);

    this.platformService.adaptDialogToScreen(ref);

    ref.afterClosed().pipe(
      filter(r => !!r)
    ).subscribe(() => {
      this.addNiveau(this.editChoiceGroup.niveau);
      this.onGroupUpdate();
    });
  }

  removeChoiceGroup(group: LasidoChoiceGroup, indexGroup: number) {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: { message: this.translate.instant('lasido.edit.confirm_remove_choice') }
    });

    this.platformService.adaptDialogToScreen(ref);

    ref.afterClosed().pipe(
      filter(r => !!r)
    ).subscribe(_ => {
      this.preinscription.choiceGroups.splice(indexGroup, 1);

      this.onGroupUpdate();
    });
  }

  findTaggedNiveauIndexById(alreadyTagged: AlreadyTaggedNiveauCours[], idNiveau: number) {
    for (let i = 0; i < alreadyTagged.length; i++) {
      if (alreadyTagged[i].idNiveauDiscipline === idNiveau) {
        return i;
      }
    }
    return -1; // Retourne -1 si l'ID du niveau n'est pas trouvé
  }

  addCours(group: LasidoChoiceGroup) {
    this.coursChoices = this.getCoursFor(group.discipline, group.niveau).filter(c => !this.isCoursAlreadySelected(c, group.niveau));
    this.coursMaxSelectLeft = Math.max(0, group.maxCours - group.cours.length);

    this.coursSelectDialogRef = this.dialog.open(this.coursSelectDialog);

    this.platformService.adaptDialogToScreen(this.coursSelectDialogRef);

    this.coursSelectDialogRef.afterClosed().pipe(
      filter(r => !!r)
    ).subscribe((selection: LasidoCourse[]) => {
      for (const sel of selection) {
        if (group.cours.length < this.config.coursMax && !group.cours.some(c => c.id === sel.id)) {
          group.cours.push(sel);
        } else {
          // error ?
        }
      }

      this.onGroupUpdate(group);
    });
  }

  onRemoveCourse(group: LasidoChoiceGroup, course: LasidoCourse) {
    const index = group.cours.findIndex(c => c.id === course.id);

    if (index > -1) {
      group.cours.splice(index, 1);
    }

    const tabCoursAlreadyTagged = this.alreadyTagged.find(n => n.idNiveauDiscipline === group.niveau);
    if (tabCoursAlreadyTagged) {
      const index = tabCoursAlreadyTagged.tabCours.findIndex(c => c === course.id);
      if (index !== -1) {
        tabCoursAlreadyTagged.tabCours.splice(index, 1);
      }
    }

    this.onGroupUpdate(group);
  }

  onGroupUpdate(group?: LasidoChoiceGroup) {
    if (group) {
      this.lasidoService.setGroupAttributes(this.disciplineTree, group, this.config);
    }

    this.unsavedChanges = true;
    this.updateValidStatus();
  }

  getCoursFor(discipline: number, niveau: number) {
    for (const dep of this.disciplineTree) {
      const disc = dep.disciplines.find(d => d.id === discipline);

      if (disc) {
        const lvl = disc.niveaux.find(n => n.id === niveau);

        if (lvl) {
          return lvl.cours;
        }
      }
    }

    return [];
  }

  isNiveauAlreadySelected(niveau: number): boolean {
    return this.preinscription.choiceGroups.some(cg => cg.niveau === niveau) || this.alreadyTagged.some(n => n.idNiveauDiscipline === niveau);
  }

  isCoursAlreadySelected(cours: LasidoCourse, niveau: number): boolean {
    return this.preinscription.choiceGroups.some(cg => cg.cours.some(c => c.id === cours.id)) || this.alreadyTagged.find(n => n.idNiveauDiscipline === niveau)?.tabCours.includes(cours.id);
  }

  autoriseSelectDisciplinesByDepartement(idDepartement: number): boolean {

    const departementSelected = this.config.listDepartements.find(dep => dep.id === idDepartement);

    this.nbDisciplineMaxDepartementSelected = departementSelected.disciplinesMax;
    this.libelleDepartementSelected = departementSelected.libelle;

    const nbDisciplineByDepartement = this.preinscription.choiceGroups.filter(disc => disc.idDepartement === idDepartement).length;

    if (this.nbDisciplineMaxDepartementSelected && !(this.nbDisciplineMaxDepartementSelected > nbDisciplineByDepartement)) {
      return false;
    }
    return true;
  }

  // For Dialog
  onChangeDepartment(id: number) {
    const dep = this.disciplineTree.find(d => d.id === id);

    this.disciplineChoices = dep?.disciplines || [];

    if (id) {
      this.disciplinesAvailable = this.autoriseSelectDisciplinesByDepartement(id);
    } else this.disciplinesAvailable = true;

    this.onChangeDiscipline(null);
  }

  onChangeDiscipline(id: number) {
    const disc = this.disciplineChoices.find(d => d.id === id);
    this.selectedDisc = disc;
    this.niveauChoices = disc?.niveaux.filter(n => !this.preinscription.choiceGroups.some(cg => cg.niveau === n.id)) || [];
    this.onChangeNiveau(null);
  }

  onChangeNiveau(id: number) {
    this.editChoiceGroup.niveau = id;

    const niv = this.niveauChoices.find(n => n.id === id);

    this.coursChoices = niv?.cours || [];
  }

  onCoursSelectChange(event: MatSelectionListChange) {
    event.options.forEach(opt => {
      if (opt.selected) {
        this.coursMaxSelectLeft--;
      } else {
        this.coursMaxSelectLeft++;
      }
    });

    if (this.coursMaxSelectLeft < 1) {
      event.source.options.filter(opt => !opt.selected).forEach(opt => opt.disabled = true);
    } else {
      event.source.options.forEach(opt => opt.disabled = false);
    }
  }

  onValidateCoursSelection(list: MatSelectionList) {
    const selectedCourses = list.selectedOptions.selected.map(x => x.value);

    this.coursSelectDialogRef.close(selectedCourses);
  }

  onDropCourse(event: CdkDragDrop<LasidoCourse>, group: LasidoChoiceGroup) {
    moveItemInArray(group.cours, event.previousIndex, event.currentIndex);
  }

  onDropChoiceGroups(event: CdkDragDrop<LasidoCourse>, preinsciption: LasidoPreinscription) {
    moveItemInArray(preinsciption.choiceGroups, event.previousIndex, event.currentIndex);
  }

  // Should be called whenever a course is added / removed, or a ChoiceGroup removed
  updateValidStatus() {
    this.isPreinscriptionValid = this.checkValid();
  }

  viewReglementInterieur() {
    if (this.isMobile) {
      this.menuService.sidenav.close();
    }

    if (this.config.urlApiDoc && this.config.urlApiDoc !== "0") {
      window.open(environment.apiUrl + this.config.urlApiDoc, '_blank');
    } else {
      window.open(this.config.url, '_blank');
    }
  }

  checkValid() {
    if (!this.preinscription.choiceGroups?.length) {
      return false;
    }

    for (const group of this.preinscription.choiceGroups) {
      if (group.hasCours) {
        if (group.minCours > group.cours?.length) {
          return false;
        } else if (group.maxCours && group.maxCours < group.cours?.length) {
          return false;
        }
      }
    }

    return true;
  }

  resetErrors() {
    this.preinscription.errors = [];
    this.preinscription.choiceGroups.forEach(cg => cg.errors = []);
  }

  onValidatePreinscription() {
    if (!this.isPreinscriptionValid) {
      return;
    }

    this.resetErrors();

    if (this.isPreinscriptionValid) {
      const family = this.familyService.currentFamily;

      this.saving = true;

      // maybe map course and other objects to IDs ? => these are plain interfaces, so it works ..
      this.lasidoService.savePreinscription(this.preinscription, family?.id).pipe(
        finalize(() => this.saving = false)
      ).subscribe((res: any) => {
        if (!res.errors) {
          this.snackbar.info('info.success.saving');
          if (this.fromDemarche) {
            let preInscriptionData = { id: res.id, type: 'Preinscription', usager: this.usager };
            this.save.emit(preInscriptionData);
          } else {
            this.router.navigate(['/account/lasido-preinscriptions']);
          }
        } else {
          res.errors.forEach((err: { message: string, niveau?: number, cours?: number }) => {
            this.snackbar.error('lasido.edit.errors_detected');
            if (err.niveau) {
              const choice = this.preinscription.choiceGroups.find(n => n.niveau === err.niveau);

              if (choice) {
                let message = err.message as string;

                if (message === 'lasido.error.group.min_course' || message === 'lasido.error.group.max_course') {
                  message = this.translate.instant(message, { max: this.config.coursMax, min: this.config.coursMin });
                }

                if (message === 'lasido.error.group.cours_unavailable') {
                  message = this.translate.instant(message, { cours: err.cours });
                }

                choice.errors.push(message);
              } else if (err.message === 'lasido.error.group.cant_edit_treated') {
                // special case where choice no longer exists because it was deleted while not allowed
                const removedChoice = this.addNiveau(err.niveau);
                removedChoice.errors.push(err.message);
              }
            } else {
              this.preinscription.errors.push(err.message);
            }
          });
        }
      });
    }
  }

  addNiveau(lvl: number) {
    const data = this.lasidoService.getLevelData(this.disciplineTree, lvl);

    const newGroup = { discipline: data.discipline, niveau: data.niveau, errors: [], cours: [], isNew: true } as LasidoChoiceGroup;
    this.lasidoService.setGroupAttributes(this.disciplineTree, newGroup, this.config);

    this.preinscription.choiceGroups.push(newGroup);

    return newGroup;
  }

  runProgramsOnChangeUser() {
    this.programmePersoService.executeProgrammePersoLasidoPreinscription(this.preinscription).subscribe((result: any) => {

      this.errorMessageProgPerso = result?.errors ? true : false;

      if (result.conf && !result?.errors) {
        this.config = result.conf;
      }

      this.programmePersoService.displayErrorsTracesMessagesProgram(result);
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
