import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FileService, UploadedFile } from '@app/services/file.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { DocumentEditComponent } from './edit/edit.component';
import { filter, map, takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PlatformService, SnackbarService } from '@app/services';
import { FileUploadComponent } from './file-upload/file-upload.component';

@Component({
    selector: 'admin-file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['./file-manager.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ]),
    ],
    standalone: false
})
export class FileManagerComponent implements OnInit {

  @Input() readOnly: string | boolean = false; // pass 'add' to allow upload, but disable delete
  @Input() selectMode: string | boolean; // false / true or 'multi' (single by default)
  @Input() type: string;
  @Input() documentPicker = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayMode: 'list' | 'grid' = 'list';
  files: UploadedFile[];
  dataSource: MatTableDataSource<UploadedFile>;
  displayedColumns = ['type', 'title', 'taille', 'date', 'actions'];
  expandedElement: any;


  selection: SelectionModel<UploadedFile>;
  preview: UploadedFile;
  loading = false;

  constructor(
    private fileService: FileService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    public platformService: PlatformService

  ) { }

  ngOnInit() {

    if (this.selectMode) {
      this.selection = new SelectionModel(this.selectMode === 'multi');
      this.displayedColumns.unshift('select');
    }

    this.refreshTableData();
  }

  refreshTableData() {
    this.loading = true;

    this.fileService.getAll().pipe(map(files => {
      return this.type ? files.filter(f => f.type && f.type.includes(this.type)) : files;
    })).subscribe(files => {
      this.dataSource = new MatTableDataSource(files);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }
  getIcon(file: string): string {
    return this.fileService.getFileIcon(file)
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openFile(file: UploadedFile) {
    window.open(environment.apiUrl + 'files/' + file.id, '_blank');
  }

  onSelect(file: UploadedFile) {
    if (this.expandedElement === file) {
      this.expandedElement = null;
    } else if (file.type.includes('image')) {
      this.expandedElement = file;

    }
    if (this.selectMode) {
      this.selection.toggle(file);
    }
  }

  onClickEdit(file: UploadedFile) {
    const dialRef = this.dialog.open(DocumentEditComponent, {
      data: { file: Object.assign({}, file) },
      maxWidth: '600px'
    });

    dialRef.afterClosed().pipe(filter(x => x)).subscribe(res => {
      this.fileService.update(res).subscribe(_ => {
        this.refreshTableData();
        this.snackbarService.info('Fichier modifié !');
      });
    });
  }

  onClickDelete(file: UploadedFile) {
    const id = file.id;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Êtes vous sûr de vouloir supprimer le fichier "' + file.name + '" ?' }
    });

    dialogRef.afterClosed().subscribe(result => result ? this.deleteFile(file) : null);
  }

  deleteFile(file: UploadedFile) {
    if (this.preview === file) {
      this.preview = null;
    }

    this.fileService.delete(file).subscribe(_ => {
      this.refreshTableData();
      this.snackbarService.info('Fichier supprimé !');
    });
  }

  canPreview() {
    return this.preview && this.preview.type.includes('image');
  }

  // @NB: Copied this part from Mat Table doc
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  switchDisplayMode() {
    this.displayMode = this.displayMode === 'list' ? 'grid' : 'list';
  }

  OpenUploadFileDialog() {
    const dialogRef = this.dialog.open(FileUploadComponent, { disableClose: true });
    dialogRef.componentInstance.onSend.pipe(
      takeUntil(dialogRef.afterClosed())
    ).subscribe(_ => {
      this.refreshTableData();
    });
  }
}
