import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContratAccueilMentalo } from '@app/models/contrat-accueil';
import { FormConfigService, PermissionService, PlatformService, UserService } from '@app/services';
import { AssmatService } from '@app/services/assmat.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-contrat-accueil-mentalo-list',
    templateUrl: './contrat-accueil-mentalo-list.component.html',
    styleUrls: ['./contrat-accueil-mentalo-list.component.scss'],
    standalone: false
})
export class ContratAccueilMentaloListComponent implements OnInit {

  idAssmat: number;
  contrats: ContratAccueilMentalo[];
  readOnly: boolean;
  permToCreate: boolean;
  formStepContratAccueil: any[];
  showExpiredContrats: boolean;
  contratsFiltered: ContratAccueilMentalo[];
  loading = false;
  drawerOpened = false;
  typeSortContrats: string;
  onDestroy$ = new Subject<void>();

  constructor(
    private assmatService: AssmatService,
    private userService: UserService,
    private formConfigService: FormConfigService,
    public platformService: PlatformService,
    public permService: PermissionService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.idAssmat = this.userService.currentUser.idAssmat || null;
    this.readOnly = !this.permService.hasPermission('contrat_accueil_edit');
    this.permToCreate = this.permService.hasPermission('contrat_accueil_create');
    this.loadFormSteps().subscribe(stepNames => this.formStepContratAccueil = stepNames);
    this.assmatService.getContratsAccueilByIdAssMat(this.idAssmat).subscribe((contratsAcceuil: ContratAccueilMentalo[]) => {
      this.contrats = this.sortByDateDemande(contratsAcceuil);
      this.contrats.forEach(contrat => {
        contrat.currentContrat = this.assmatService.dateInPeriode(contrat.dateDebut, contrat.dateFin, new Date) ? true : false;
        contrat.expiredContrat = contrat.dateFin && (moment(contrat.dateFin) < moment(new Date)) ? true : false;
      });
      this.contratsFiltered = this.contrats.filter(contrat => !contrat.expiredContrat);
    })
  }

  loadFormSteps() {
    return this.formConfigService.getForm('form-contrat-accueil-mentalo').pipe(
      map(steps => steps.map(step => ({ stepName: step.stepName, label: step.label })))
    );
  }

  openForm(contratId, stepName) {
    this.router.navigate([`/account/contrat-accueil-mentalo/edit/${contratId}/${stepName}`]);
  }

  toggleShowExpiredContrats(event) {
    if (event === true) {
      this.contratsFiltered = [...this.contrats];
    } else {
      this.contratsFiltered = this.contrats.filter(contrat => !contrat.expiredContrat);
    }
    // this.sortContrats(this.typeSortContrats);
  }

  // sortContrats(typeSortContrats) {

  //   if (typeSortContrats === "byStartDate") {
  //     this.contratsFiltered = this.contratsFiltered.sort((a, b) => {
  //       if (moment(a.dateDebut) > moment(b.dateDebut)) return -1;
  //       if (moment(a.dateDebut) < moment(b.dateDebut)) return 1;
  //       return 0;
  //     });
  //   }

  //   if (typeSortContrats === "byEndDate") {
  //     this.contratsFiltered = this.contratsFiltered.sort((a, b) => {
  //       if ((a.dateFin || b.dateFin) === undefined) return -1;

  //       if (moment(a.dateFin) > moment(b.dateFin)) return -1;
  //       if (moment(a.dateFin) < moment(b.dateFin)) return 1;
  //       return 0;
  //     });
  //   }

  //   if (typeSortContrats === "" || !typeSortContrats) {
  //     this.contratsFiltered = this.contratsFiltered.sort((a, b) => {
  //       if (moment(a.dateDemande) > moment(b.dateDemande)) return -1;
  //       if (moment(a.dateDemande) < moment(b.dateDemande)) return 1;
  //       return 0;
  //     })
  //   }
  // }

  sortByDateDemande(tabContrats) {
    return tabContrats.sort((a, b) => {
      if (moment(a.dateDemande) > moment(b.dateDemande)) return -1;
      if (moment(a.dateDemande) < moment(b.dateDemande)) return 1;
      return 0;
    })
  }

  closeDrawer() {
    this.drawerOpened = false;
  }

  toggleContratsOptions() {
    this.drawerOpened = !this.drawerOpened;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


}

