<ng-template appTopbarActions>
  <button mat-mini-fab color="accent" (click)="toggleOptions()" matTooltip="Options d'affichage">
    <mat-icon>settings</mat-icon>
  </button>
</ng-template>

<mat-drawer-container>

  <mat-drawer-content>

    <div id="list-conteneur" class="thin-scrollbar" *ngIf="list; else loader">

      <div *ngIf="permToAdd" class="fx-direction-row fx-center-center">
        <button mat-stroked-button *ngIf="type === 'animation'" color="primary"
          routerLink="/account/animations/inscriptions" class="btn-add">
          <mat-icon style="margin-right:10px ;">add_circle</mat-icon>
          <span *ngIf="type ==='animation'">{{ 'animation_reunion.animation.add_animation_cardTitle'|trans }}</span>
        </button>
        <button mat-stroked-button *ngIf="type === 'reunion'" color="primary"
          routerLink="/account/reunions/inscriptions" class="btn-add">
          <mat-icon style="margin-right:10px ;">add_circle</mat-icon>
          <span *ngIf="type ==='reunion'">{{ 'animation_reunion.reunion.add_reunion_cardTitle'|trans }}</span>
        </button>
      </div>

      <mat-accordion class="headers-align">
        <ng-container *ngFor="let animationReunion of listFiltered">
          <app-loader-overlay *ngIf="animationReunion.saving"></app-loader-overlay>
          <mat-expansion-panel [ngStyle]="{'opacity': animationReunion.saving || !animationReunion.active ? 0.7 : 1}"
            [expanded]="animationReunion.saving">
            <mat-expansion-panel-header style="height: auto;">
              <mat-panel-title style="font-weight:bold; align-items: center;">
                <mat-icon *ngIf="animationReunion.assmatIsRegistered &&
                   animationReunion.active &&
                    (getAssmatStatut(animationReunion) === decision.accepted ||
                     (getAssmatStatut(animationReunion) === decision.refused &&
                      animationReunion.portailInscription?.typeDemande === typeDemande.desinscription))"
                  matTooltip="Inscrit" color="primary" #tooltip="matTooltip" (click)="tooltip.toggle()"
                  style="cursor: pointer; min-width: 24px;">
                  check_circle
                </mat-icon>
                <mat-icon *ngIf="animationReunion.assmatIsRegistered &&
                   animationReunion.active &&
                    getAssmatStatut(animationReunion) === decision.enAttente" matTooltip="En attente" color="accent"
                  #tooltip="matTooltip" (click)="tooltip.toggle()" style="cursor: pointer; min-width: 24px;">
                  help</mat-icon>
                <mat-icon *ngIf="animationReunion.assmatIsRegistered &&
                   animationReunion.active &&
                   (getAssmatStatut(animationReunion) === decision.refused &&
                    animationReunion.portailInscription?.typeDemande === typeDemande.inscription)"
                  matTooltip="Inscription refusée" color="warn" #tooltip="matTooltip" (click)="tooltip.toggle()"
                  style="cursor: pointer; min-width: 24px;">
                  remove_circle</mat-icon>
                <span style="margin-left: 10px;">{{animationReunion.titre}}</span>
              </mat-panel-title>
              <mat-panel-description>
                <div style="padding: 8px;">
                  <div class="fx-direction-row fx-start-center fx-gap-3" style="margin-bottom: 6px;">
                    <mat-icon>event</mat-icon> Le {{animationReunion.date | date: 'fullDate'}} de
                    {{animationReunion.heureDebut}} à
                    {{animationReunion.heureFin}}
                  </div>
                  <div class="fx-direction-row fx-start-center fx-gap-3">
                    <mat-icon>location_on</mat-icon>
                    <div style="font-size: smaller;">
                      <span *ngIf="animationReunion.lieu">{{animationReunion.lieu}}<br></span>
                      <span *ngIf="animationReunion.rue1">{{ animationReunion.rue1 }}<br></span>
                      <span *ngIf="animationReunion.rue2">{{ animationReunion.rue2 }}<br></span>
                      <span>{{ animationReunion.codePostal }} {{ animationReunion.ville }}</span>
                    </div>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div>
              <div *ngIf="animationReunion.portailInscription?.decision === decision.refused"
                class="message message-refused fx-direction-row fx-center-center">
                <mat-icon style="margin-right: 5px;" color="warn">remove_circle</mat-icon>
                <span *ngIf="animationReunion.portailInscription?.typeDemande === typeDemande.desinscription">
                  <b style="margin-right: 5px;">Désinscription Refusée</b><b
                    *ngIf="animationReunion.portailInscription?.motifRefus">:</b>
                  {{animationReunion.portailInscription?.motifRefus}}
                </span>
                <span *ngIf="animationReunion.portailInscription?.typeDemande === typeDemande.inscription">
                  <b style="margin-right: 5px;">Inscription Refusée</b><b
                    *ngIf="animationReunion.portailInscription?.motifRefus">:</b>
                  {{animationReunion.portailInscription?.motifRefus}}
                </span>
              </div>
              <div
                *ngIf="animationReunion.assmatIsRegistered && animationReunion.active && getAssmatStatut(animationReunion) === decision.enAttente"
                class="message message-attente fx-direction-row fx-center-center">
                <mat-icon style="margin-right: 5px;" color="accent">help</mat-icon>
                <span *ngIf="animationReunion.portailInscription?.typeDemande === typeDemande.inscription">
                  <!--  && !unsubcribeChildExist(animationReunion.portailInscription?._EnfantsMentalo) -->
                  <b>{{'animation_reunion.msg_inscription_en_attente' | trans}}</b>
                </span>
                <span *ngIf="animationReunion.portailInscription?.typeDemande === typeDemande.desinscription">
                  <b>{{'animation_reunion.msg_desinscription_en_attente' | trans}}</b></span>
              </div>
            </div>
            <div *ngIf="animationReunion.description" class="description-html"><span
                [innerHTML]="animationReunion.description | safe"></span>
            </div>
            <div>
              <div style="margin-top: 10px;">
                <app-icon type="do" icon="etablissement"></app-icon>
                <span style="margin-bottom: 5px; margin-left: 3px;"><b>{{'animation_reunion.etablissement_organisateur'
                    | trans}}
                    :</b></span>
                <div style="margin-left:30px;">
                  <span
                    *ngIf="animationReunion.etablissement.designation">{{animationReunion.etablissement.designation}}<br></span>
                  <span *ngIf="animationReunion.etablissement.rue1">{{ animationReunion.etablissement.rue1 }}<br></span>
                  <span *ngIf="animationReunion.etablissement.rue2">{{ animationReunion.etablissement.rue2 }}<br></span>
                  <span>{{ animationReunion.etablissement.codePostal }}
                    {{ animationReunion.etablissement.ville }}</span>
                </div>
              </div>
              <div style="margin-top: 10px;">
                <div class="fx-direction-row fx-start-center fx-gap-3">
                  <mat-icon>location_on</mat-icon>
                  <span *ngIf="type === 'animation'" style="margin-bottom: 5px;">
                    <b>Lieu de l'animation : </b></span>
                  <span *ngIf="type === 'reunion'" style="margin-bottom: 5px;">
                    <b>Lieu de la réunion : </b></span>
                </div>
                <div style="margin-left:30px;">
                  <span *ngIf="animationReunion.lieu">{{animationReunion.lieu}}<br></span>
                  <span *ngIf="animationReunion.rue1">{{ animationReunion.rue1 }}<br></span>
                  <span *ngIf="animationReunion.rue2">{{ animationReunion.rue2 }}<br></span>
                  <span>{{ animationReunion.codePostal }} {{ animationReunion.ville }}</span>
                </div>
              </div>
            </div>
            <div *ngIf="animationReunion.listEnfantMentaloRegisteredByAssmat.length" style="margin-top: 10px;">
              <span style="margin-bottom: 5px;">
                <app-icon type="do" icon="enfant" style="margin-right: 3px;" />
                <b>Enfants :</b></span><br>
              <div *ngFor="let child of animationReunion.listEnfantMentaloRegisteredByAssmat; let i = index">
                <div class="fx-direction-row fx-between-center">
                  <span style="padding: 10px 0;" class="fx-direction-row fx-start-center">

                    <mat-icon *ngIf="child.decision === decision.enAttente" color="accent" matTooltip="En attente"
                      #tooltip="matTooltip" (click)="tooltip.toggle()" style="cursor: pointer;">help
                    </mat-icon>

                    <mat-icon *ngIf="(child.decision === decision.accepted && child.typeDemande === typeDemande.inscription) ||
                       (child.decision === decision.refused && child.typeDemande === typeDemande.desinscription) ||
                        animationReunion.tabEnfantsMentalo" color="primary" matTooltip="Inscrit" #tooltip="matTooltip"
                      (click)="tooltip.toggle()" style="cursor: pointer;">check_circle
                    </mat-icon>

                    <mat-icon
                      *ngIf="child.decision === decision.refused && child.typeDemande === typeDemande.inscription"
                      color="warn" matTooltip="Inscription refusée" #tooltip="matTooltip" (click)="tooltip.toggle()"
                      style="cursor: pointer;">remove_circle
                    </mat-icon>

                    <span style="margin-left: 5px; margin-right: 5px;">{{child.child.nom}}
                      {{child.child.prenom}}</span>
                  </span>
                  <span
                    *ngIf="child.decision === decision.refused && animationReunion.portailInscription?.decision !== decision.refused"
                    style="font-style: italic;">
                    <b>
                      {{child.typeDemande === typeDemande.desinscription ? 'Désinscription refusée' : 'Inscription
                      refusée'}}
                    </b>
                    <b *ngIf="child.motifRefus">:</b>
                    {{child.motifRefus}}</span>
                  <span *ngIf="child.decision === decision.enAttente &&
                     child.typeDemande === typeDemande.desinscription" style="font-style: italic;">
                    <!-- && animationReunion.portailInscription?.decision === decision.accepted -->
                    {{'animation_reunion.msg_desinscription_en_attente' | trans}}</span>
                  <span *ngIf="child.decision === decision.enAttente &&
                     child.typeDemande === typeDemande.inscription " style="font-style: italic;">
                    <!-- && animationReunion.portailInscription?.decision === decision.accepted" -->
                    {{'animation_reunion.msg_inscription_en_attente' | trans}}</span>

                  <!-- condition à tester si utile pour le btn desinscrire un enfant un enfant
                     && (animationReunion.portailInscription?.typeDemande !== typeDemande.desinscription || !animationReunion.portailInscription)
                  -->
                  <button
                    *ngIf="animationReunion.autoriserInscription &&
                      animationReunion.active &&
                      permToAdd &&
                      !((child.decision === decision.refused) && child.typeDemande === typeDemande.desinscription) &&
                      !(child.decision === decision.refused && child.typeDemande === typeDemande.inscription)&&
                      !(animationReunion.portailInscription?.typeDemande === typeDemande.inscription && animationReunion.portailInscription?.decision === decision.refused)"
                    [disabled]="animationReunion.saving || !animationReunion.active || child.typeDemande === 'Desinscription'"
                    mat-icon-button color="warn" (click)="openUnsubscribeChildDialog(animationReunion,child)"
                    matTooltip="Désinscription">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <!-- <span
                    *ngIf="(child.decision === decision.enAttente && child.typeDemande === typeDemande.desinscription)"></span> -->
                </div>
                <mat-divider class="child-divider"
                  *ngIf="animationReunion.listEnfantMentaloRegisteredByAssmat.length !== i+1">
                </mat-divider>
              </div>
            </div>
            <div class="fx-direction-row fx-center-center"
              *ngIf="animationReunion.isBeforeLimiteDateInscription &&
              animationReunion.autoriserInscription &&
                  animationReunion.active &&
                  !(animationReunion.portailInscription?.typeDemande === typeDemande.inscription && animationReunion.portailInscription?.decision === decision.refused) &&
                  !(animationReunion.portailInscription?.typeDemande === typeDemande.desinscription && animationReunion.portailInscription?.decision === decision.enAttente)"
              style="margin-top: 10px;">
              <button mat-stroked-button color="primary" (click)="openRegisterChildDialog(animationReunion)">
                <mat-icon>add_circle</mat-icon>
                Ajouter un enfant
              </button>
            </div>

            <mat-action-row>
              <button
                *ngIf="animationReunion.autoriserInscription &&
                    animationReunion.active &&
                    permToAdd &&
                    (animationReunion.portailInscription?.typeDemande !== typeDemande.desinscription || !animationReunion.portailInscription) &&
                    !(animationReunion.portailInscription?.typeDemande === typeDemande.inscription && animationReunion.portailInscription?.decision === decision.refused)"
                (click)="openUnsubscribeDialog(animationReunion)" mat-raised-button color="warn"
                [disabled]="animationReunion.saving || !animationReunion.active">
                Se désinscrire
              </button>
              <div *ngIf="!animationReunion.autoriserInscription" class="fx-direction-row fx-center-center">
                <mat-icon style="margin-right: 5px;">info</mat-icon>
                <span>{{'animation_reunion.no_editable' | trans}}</span>
              </div>
            </mat-action-row>

          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>

    </div>
  </mat-drawer-content>

  <!-- Options d'affichage -->
  <mat-drawer id="previewDrawer" class="mat-elevation-z4 thin-scrollbar" position="end" [(opened)]="drawerOpened">
    <mat-toolbar class="preview-header fx-direction-row fx-between-center" color='primary'>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
      <span>Options d'affichage</span>
      <span></span>
    </mat-toolbar>

    <div class="fx-direction-col fx-gap-5" style="margin: 5px; padding: 5px;">
      <mat-slide-toggle [(ngModel)]="showAccepted" (ngModelChange)="toggleShowAccepted($event)" class="slideToggle">
        <div class="fx-direction-row fx-start-center">
          <span>Afficher les inscriptions acceptées
          </span>
          (<mat-icon color="primary">check_circle</mat-icon>).
        </div>
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showRefused" (ngModelChange)="toggleShowRefused($event)" class="slideToggle">
        <div class="fx-direction-row fx-start-center">
          <span>Afficher les inscriptions refusées
          </span>
          (<mat-icon color="warn">remove_circle</mat-icon>).
        </div>

      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showWaiting" (ngModelChange)="toggleShowWaiting($event)" class="slideToggle">
        <div class="fx-direction-row fx-start-center">
          <span>Afficher les inscriptions en attente
          </span>
          (<mat-icon color="accent">help</mat-icon>).
        </div>

      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showAll" (ngModelChange)="toggleShowAll($event)" class="slideToggle">
        <span *ngIf="type ==='reunion'">Afficher les anciennes inscriptions aux réunions.</span>
        <span *ngIf="type ==='animation'">Afficher les anciennes inscriptions aux animations.</span>
      </mat-slide-toggle>
    </div>

  </mat-drawer>
</mat-drawer-container>


<!-- Désinscription assmat + enfants dialog -->
<ng-template #UnsubscribeDialog let-data>
  <app-dialog-header title="Désinscription"></app-dialog-header>
  <div>
    <app-loader-overlay *ngIf="saving"></app-loader-overlay>
    <div mat-dialog-content class="thin-scrollbar">
      <h3 *ngIf="type === 'animation'" style="text-align: center;">
        {{ 'animation_reunion.animation.remove'|trans:{titre :data.animationReunion.titre } }}
      </h3>
      <h3 *ngIf="type === 'reunion'" style="text-align: center;">
        {{ 'animation_reunion.reunion.remove'|trans:{titre :data.animationReunion.titre } }}
      </h3>
    </div>
  </div>
  <div mat-dialog-actions align="center">
    <button mat-raised-button color="warn" [mat-dialog-close] [disabled]="saving">Annuler</button>
    <button mat-raised-button color="primary" (click)="unsubscribe(data.animationReunion)"
      [disabled]="saving">confirmer</button>
  </div>
</ng-template>

<!-- désinscription enfant dialog -->
<ng-template #UnsubscribeChildDialog let-data>
  <app-dialog-header title="Désinscription"></app-dialog-header>
  <div>
    <app-loader-overlay *ngIf="saving"></app-loader-overlay>
    <div mat-dialog-content class="thin-scrollbar">
      <h3 *ngIf="type === 'animation'" style="text-align: center;">
        {{ 'animation_reunion.animation.remove_child'|trans:{titre :data.animationReunion.titre,
        nom:data.child.child.nom, prenom:data.child.child.prenom } }}
      </h3>
      <h3 *ngIf="type === 'reunion'" style="text-align: center;">
        {{ 'animation_reunion.reunion.remove_child'|trans:{titre :data.animationReunion.titre, nom:data.child.child.nom,
        prenom:data.child.child.prenom } }}
      </h3>
    </div>
  </div>
  <div mat-dialog-actions align="center">
    <button mat-raised-button color="warn" [mat-dialog-close] [disabled]="saving">Annuler</button>
    <button mat-raised-button color="primary" (click)="unsubscribeChild(data.animationReunion, data.child)"
      [disabled]="saving">confirmer</button>
  </div>
</ng-template>

<!-- Inscription enfant dialog -->
<ng-template #RegisterChildDialog let-data>
  <app-dialog-header title="Inscription"></app-dialog-header>
  <div>
    <app-loader-overlay *ngIf="saving"></app-loader-overlay>
    <div mat-dialog-content class="thin-scrollbar">

      <h3 *ngIf="type === 'animation'" style="text-align: center;">
        {{ 'animation_reunion.animation.add'|trans:{titre : data.animationReunion.titre} }}</h3>

      <h3 *ngIf="type === 'reunion'" style="text-align: center;">
        {{ 'animation_reunion.reunion.add'|trans:{titre : data.animationReunion.titre} }}</h3>

      <p *ngIf="data.listChildNotRegistered.length && type === 'animation'">
        {{'animation_reunion.add_child' | trans }}
      </p>

      <p *ngIf="data.listChildNotRegistered.length && type === 'reunion'">
        {{'animation_reunion.add_child' | trans }}
      </p>

      <p *ngIf="!data.listChildNotRegistered.length" style="text-align: center;">
        {{'animation_reunion.no_child_available' | trans }}
      </p>
      <div>
        <mat-selection-list [(ngModel)]="registerChildList" style="margin-bottom: 10px;" [disabled]="saving">
          <mat-list-option *ngFor="let child of data.listChildNotRegistered" [value]="child.id"
            [disabled]="child.id <= 0">
            {{child.nom}} {{child.prenom}}
            <div *ngIf="child.id <= 0" style="font-style:italic; font-size:small"
              class="fx-direction-row fx-start-center">
              <mat-icon color="warn" style="margin-right:5px;">warning</mat-icon><span>
                {{'animation_reunion.contrat_accueil_en_attente' | trans }}</span>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="center">
    <button mat-raised-button color="warn" [mat-dialog-close] [disabled]="saving">Annuler</button>
    <button mat-raised-button color="primary" (click)="registerChild(data.animationReunion)"
      [disabled]="saving || !registerChildList.length">confirmer</button>
  </div>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>