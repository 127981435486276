import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService, SnackbarService } from '@app/services';
import { AuthentificationConfig } from '@app/models/authentification-config'
import { PermissionService } from '@app/services/permission.service';
import { Observable, of, pipe } from 'rxjs';
import { map, tap, filter, switchMap } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TotpRestrictInfoComponent } from '@app/components/_elements/totp-restrict-info/totp-restrict-info.component';

@Injectable()
export class TotpGuard  {

  constructor(
    private authService: AuthenticationService,
    private snackbarServive: SnackbarService,
    private bottomSheet: MatBottomSheet
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkAccess(route)
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkAccess(route)
  }

  checkAccess(route: ActivatedRouteSnapshot): Observable<boolean> {

    // need data to know what we must check
    if (!route.data || !route.data.totpRestrict) {
      return of(true)
    } else {
      return this.authService.getConf().pipe(
        switchMap((authConf: AuthentificationConfig): Observable<boolean> => { // result mean : should test if user is TOTP enrolled ?
          if (!authConf) return of(false); // no conf => let's go (dont'check)
          if (!authConf.doubleAuth.isActive) return of(false); // not active => let's go (dont'check)

          const restrictArea = route.data.totpRestrict // 'data.totpRestrict' must be a member of authConf.doubleAuth
          if (authConf.doubleAuth[restrictArea]) {
            return of(true); // (next step must check if user is TOTP enrolled) 
          }
          return of(false); // (dont'check)
        }),
        switchMap((mustCheckUser: boolean): Observable<boolean> => {// result mean : user is authorized ?
          if (!mustCheckUser) {
            return of(true);
          } else {
            return this.authService.currentUser$.pipe(
              map(user => {
                if (user.totpEnrolled && user.totpVerified) {
                  return true;
                } else {
                  this.bottomSheet.open(TotpRestrictInfoComponent);
                  return false;
                }
              })
            )
          }
        })
      )
    }
  }
}
