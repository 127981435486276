import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetailedPresence } from '../details-dialog/details-dialog.component';

@Component({
    selector: 'app-presence-list',
    templateUrl: './presence-list.component.html',
    styleUrls: ['./presence-list.component.scss'],
    standalone: false
})
export class PresenceListComponent implements OnInit {

  @Input() presences: DetailedPresence[];
  @Output() clickPresence = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
