import { Component, OnInit, TemplateRef, ViewChild, ElementRef, Inject, Optional, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { PlatformService, SnackbarService, FamilyService, UserService, ChildService, PermissionService, PreinscriptionService } from '@app/services';
import { GedService } from '@app/services/ged.service';
import { GedDocument, GedPiece, GedConfig } from '@app/models/ged';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { forkJoin, Subscription, Subject, of, Observable } from 'rxjs';
import { Family } from '@app/models/family';
import { tap, switchMap, takeUntil, filter } from 'rxjs/operators';
import { Adulte } from '@app/models/adulte';
import { DomSanitizer } from '@angular/platform-browser';
import { Child } from '@app/models/child';
import { TranslateService } from '@ngx-translate/core';
import { AccountType } from '@app/models/user';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { DocDetailsComponent } from './doc-details/doc-details.component';


interface GedEntity {
  id: number;
  name: string;
  type: string;

  typeStructure?: string;
  details?: string;
  error?: string;
  documents?: GedDocument[];
}

interface EntitiesByTypeStructure {
  show?: boolean;
  Cogito?: { label: string; entities: GedEntity[]; pieces: GedPiece[] };
  Lasido?: { label: string; entities: GedEntity[]; pieces: GedPiece[] };
  Mikado?: { label: string; entities: GedEntity[]; pieces: GedPiece[] };
  Diabolo?: { label: string; entities: GedEntity[]; pieces: GedPiece[] };
}

interface GedData {
  pieces: GedPiece[];
  entities?: GedEntity[];
  documents: GedDocument[];
  entitiesByTypeStructure?: EntitiesByTypeStructure;
}

// const iconClasses = {
//   image: ['jpg', 'jpeg', 'png'],
//   pdf: ['pdf'],
//   word: ['doc', 'docx']
// };

@Component({
  selector: 'app-ged',
  templateUrl: './ged.component.html',
  styleUrls: ['./ged.component.scss'],
  standalone: false
})
export class GedComponent implements OnInit, OnDestroy {

  config: GedConfig;
  idAssmat: number;
  accountType: AccountType;

  acceptedExtensions: string;

  knownTypes = ['famille', 'enfant', 'adulte', 'preinscription', 'assistant maternel', 'inscription']; // order matters
  knownTypesStructures = ['Cogito', 'Mikado', 'Lasido', 'Diabolo'];
  dataByType: { [type: string]: GedData } = {};
  firstType: string;

  showInvalideFile = false;

  previewURL: any;
  downloadFile: any;

  uploadingFile: File;
  uploadDialogRef: MatDialogRef<any>;
  errorMessage: string;

  documentDownload: { [doc: number]: number } = {};

  loaded = false;
  loadingPreview = false;

  onDestroy$ = new Subject<void>();

  @ViewChild('pieceDetailsDialog', { static: true }) pieceDetailsDialog: TemplateRef<any>;

  @ViewChildren('inputFile') inputFile: QueryList<ElementRef>;

  constructor(
    public platformService: PlatformService,
    private gedService: GedService,
    private matDialog: MatDialog,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private familyService: FamilyService,
    private sanitizer: DomSanitizer,
    private childService: ChildService,
    private translateService: TranslateService,
    public permService: PermissionService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.idAssmat = this.userService.currentUser.idAssmat;
    this.accountType = this.userService.currentUser.accountType;

    this.gedService.getConfig().subscribe(conf => {
      this.config = conf
      this.acceptedExtensions = this.config.extensions.split(' ').map(ext => '.' + ext).join(", ")

      if (this.idAssmat && this.accountType === "assmat") {
        this.loadGedDataAssmat().pipe(tap(() => this.loaded = false)).subscribe(() => this.loaded = true);
      } else {
        this.familyService.currentFamily$.pipe(
          filter(f => !!f),
          takeUntil(this.onDestroy$),
          tap(() => this.loaded = false),
          switchMap(f => this.loadGedDataFamily(f.id))
        ).subscribe(() => this.loaded = true);

      }
    })

  }

  loadGedDataAssmat() {
    return this.gedService.getAssmatDocuments(this.idAssmat).pipe(
      tap(assmatDocuments => {
        const assmatDocs = assmatDocuments;
        const pieces = this.config.listePiecesAFournir.filter(piece => piece.obj.toLowerCase() === 'assistant maternel')

        if (pieces && pieces.length) {
          const entities = [this.buildEntityAssmat()];
          const documents = this.parseDocuments('assistant maternel', assmatDocs);
          this.dataByType['assistant maternel'] = { pieces, entities, documents };
          this.firstType = this.firstType || 'assistant maternel';
        }

        this.refreshErrors()
      }))

  }

  loadGedDataFamily(idFamille: number) {

    return this.makeAddRequests(idFamille).pipe(tap(([familyDocuments, adults, children, familyPreInscriptions, familyInscriptions]) => {
      const familyDocs = familyDocuments;
      const inscriptions = familyInscriptions;
      const preinscriptions = familyPreInscriptions;
      const family = this.familyService.currentFamily;

      this.knownTypes.filter(type => type !== "assistant maternel").forEach(type => {
        this.gedService.sortByOrder(this.config.listePiecesAFournir);
        const pieces = this.config.listePiecesAFournir.filter(piece => piece.obj.toLowerCase() === type);
        if (pieces && pieces.length) {

          let entities: GedEntity[];
          let entitiesByTypeStructure: EntitiesByTypeStructure;

          if (type === 'famille' || type === 'adulte' || type === 'enfant') {
            entities = this.parseEntities(type, { family, adults, children });
          }

          if (type === 'inscription' || type === 'preinscription') {
            entitiesByTypeStructure = this.parseEntitiesByTypeStructure(type, pieces, { preinscriptions, inscriptions });
            entitiesByTypeStructure.show = !this.checkShowEntityPreinscInsc(entitiesByTypeStructure);

          }
          const documents = this.parseDocuments(type, familyDocs);
          this.dataByType[type] = { pieces, entities, documents, entitiesByTypeStructure };
          this.firstType = this.firstType || type;
        }
      });

      this.refreshErrors();
    }))
  }

  makeAddRequests(idFamille: number): Observable<any> {
    let requests = [];
    requests.push(this.gedService.getCurrentFamilyDocuments());

    if (this.config.listePiecesAFournir.some(piece => piece.obj.toLowerCase() == 'adulte')) {
      requests.push(this.userService.getCurrentFamilyAdults());
    } else requests.push(of([]));

    if (this.config.listePiecesAFournir.some(piece => piece.obj.toLowerCase() == 'enfant')) {
      requests.push(this.childService.getCurrentFamilyChildren(true));
    } else requests.push(of([]));

    if (this.config.listePiecesAFournir.some(piece => piece.obj.toLowerCase() == 'preinscription')) {
      requests.push(this.familyService.getFamilyPreInscriptions(idFamille, "Ged"));
    } else requests.push(of([]));

    if (this.config.listePiecesAFournir.some(piece => piece.obj.toLowerCase() == 'inscription')) {
      requests.push(this.familyService.getFamilyInscriptions(idFamille, "Ged"));
    } else requests.push(of([]));

    return forkJoin(requests);
  }

  buildEntityAssmat(): GedEntity {
    const adulte: Adulte = this.userService.currentAdulte
    return { id: adulte.assistantMaternel.idAssistantMaternel, name: adulte.nom + ' ' + adulte.prenom, type: 'assistant maternel', typeStructure: 'Domino' }
  }

  parseEntitiesByTypeStructure(type: string, pieces: GedPiece[], data: { preinscriptions: any, inscriptions: any }): EntitiesByTypeStructure {
    if (type === 'preinscription') {

      let entitiesByStructure: EntitiesByTypeStructure = {
        Cogito: { label: '', entities: [], pieces: [] },
        Lasido: { label: '', entities: [], pieces: [] },
        Mikado: { label: '', entities: [], pieces: [] }
      };

      //COGITO
      let cogitoParsed = data.preinscriptions?.cogito?.map(p => ({
        id: p.idPreInscriptionScolaire,
        name: p.prenom + ' ' + p.nom,
        type,
        typeStructure: 'Cogito',
        details: this.translateService.instant('ged.entite.details.preinscription_cogito'),
        dateDemande: p.dateDemande
      }
      ));
      if (cogitoParsed?.length) {
        entitiesByStructure.Cogito.entities.push(...cogitoParsed);
        entitiesByStructure.Cogito.label = this.translateService.instant('ged.entite.preinscription_cogito');
        entitiesByStructure.Cogito.pieces = pieces.filter(p => p.typeStructure === 'Cogito');
      }


      //LASIDO
      let lasidoParsed = data.preinscriptions?.lasido?.map(p => ({
        id: p.idPreInscriptionLasido,
        name: p.prenom + ' ' + p.nom,
        type,
        typeStructure: 'Lasido',
        details: this.translateService.instant('ged.entite.details.preinscription_lasido'),
        dateDemande: p.dateDemande
      }
      ));
      if (lasidoParsed?.length) {
        entitiesByStructure.Lasido.entities.push(...lasidoParsed);
        entitiesByStructure.Lasido.label = this.translateService.instant('ged.entite.preinscription_lasido');
        entitiesByStructure.Lasido.pieces = pieces.filter(p => p.typeStructure === 'Lasido');
      }


      //MIKADO
      let mikadoParsed = data.preinscriptions?.mikado?.map(p => ({
        id: p.idPreInscription,
        name: p.prenom + ' ' + p.nom,
        type,
        typeStructure: 'Mikado',
        details: this.translateService.instant('ged.entite.details.preinscription_mikado'),
        dateDemande: p.dateDemande
      }
      ));
      if (mikadoParsed?.length) {
        entitiesByStructure.Mikado.entities.push(...mikadoParsed);
        entitiesByStructure.Mikado.label = this.translateService.instant('ged.entite.preinscription_mikado');
        entitiesByStructure.Mikado.pieces = pieces.filter(p => p.typeStructure === 'Mikado');
      }


      return entitiesByStructure;
    };

    if (type === 'inscription') {

      let entitiesByStructure: EntitiesByTypeStructure = {
        Cogito: { label: '', entities: [], pieces: [] },
        Diabolo: { label: '', entities: [], pieces: [] },
        Mikado: { label: '', entities: [], pieces: [] }
      }

      //MIKADO
      let mikadoParsed = data.inscriptions?.mikado?.map(p => ({
        id: p.id,
        name: p.prenom + ' ' + p.nom,
        type,
        typeStructure: 'Mikado',
        details: this.translateService.instant('ged.entite.details.inscription_mikado'),
        dateDebut: p.dateDebut,
        dateFin: p.dateFin,
      }
      ));
      if (mikadoParsed?.length) {
        entitiesByStructure.Mikado.entities.push(...mikadoParsed);
        entitiesByStructure.Mikado.label = this.translateService.instant('ged.entite.inscription_mikado');
        entitiesByStructure.Mikado.pieces = pieces.filter(p => p.typeStructure === 'Mikado');
      }

      //COGITO
      let cogitoParsed = data.inscriptions?.cogito?.map(p => ({
        id: p.idInscription || p.idInscriptionCogito,
        name: p.prenom + ' ' + p.nom,
        type,
        typeStructure: 'Cogito',
        details: this.translateService.instant('ged.entite.details.inscription_cogito'),
        anneeScolaire: p.anneeScolaire.designation
      }
      ));
      if (cogitoParsed?.length) {
        entitiesByStructure.Cogito.entities.push(...cogitoParsed);
        entitiesByStructure.Cogito.label = this.translateService.instant('ged.entite.inscription_cogito');
        entitiesByStructure.Cogito.pieces = pieces.filter(p => p.typeStructure === 'Cogito');
      }


      // DIABOLO
      let diaboloParsed = data.inscriptions?.diabolo?.map(p => ({
        id: p.idInscription || p.idInscriptionDiabolo,
        name: p.nomReferent,
        type,
        typeStructure: 'Diabolo',
        details: this.translateService.instant('ged.entite.details.inscription_diabolo'),
        dateDebut: p.dateDebut,
        dateFin: p.dateFin,
      }
      ));
      if (diaboloParsed?.length) {
        entitiesByStructure.Diabolo.entities.push(...diaboloParsed);
        entitiesByStructure.Diabolo.label = this.translateService.instant('ged.entite.inscription_diabolo');
        entitiesByStructure.Diabolo.pieces = pieces.filter(p => p.typeStructure === 'Diabolo');
      }

      return entitiesByStructure;
    }
  }

  checkShowEntityPreinscInsc(entitiesByStructure: EntitiesByTypeStructure): boolean {
    let all = [];
    for (const key in entitiesByStructure) {
      if (Object.prototype.hasOwnProperty.call(entitiesByStructure, key)) {
        if (key !== 'show') {
          all.push(entitiesByStructure[key]);
        }
      }
    }
    return all.every(x => !x.pieces.length || !x.entities.length);
  }

  parseEntities(type, data: { family: Family, adults: Adulte[], children: Child[] }): GedEntity[] {
    if (type === 'enfant') {
      return data.children.map(c => ({ id: c.id, name: c.prenom + ' ' + c.nom, type, typeStructure: 'Domino' }));
    }

    if (type === 'famille') {
      return [{ id: data.family.id, name: data.family.civilite + ' ' + data.family.nom, type, typeStructure: 'Domino' }];
    }

    if (type === 'adulte') {
      return data.adults.map(a => ({ id: a.id, name: a.prenom + ' ' + a.nom, type, typeStructure: 'Domino' }));
    }
  }

  parseDocuments(type: string, documents: GedDocument[]) {
    let idKey: string;
    if (this.idAssmat && this.accountType === 'assmat') {
      idKey = 'idAssmat';
    } else {
      idKey = 'id' + type.charAt(0).toUpperCase() + type.slice(1);
    }
    const now = new Date().getTime();
    return documents.filter(doc => doc[idKey]).map(doc => ({ ...doc, idEntite: doc[idKey], valid: now < new Date(doc.dateFinValidite).getTime() }));
  }

  isPieceMandatory(piece: GedPiece) {
    return piece.pieceJointeObligatoire || piece.accueils.find(pacc => pacc.pieceJointeObligatoire);
  }

  getDocumentsFor(documents: GedDocument[], piece: GedPiece, entity: GedEntity, onlyValid: boolean = false) {
    let idKey: string;
    if (this.idAssmat && this.accountType === 'assmat') {
      idKey = 'idAssmat';
    } else {
      idKey = entity.type === 'famille' ? 'idFamille' : 'id' + entity.type.charAt(0).toUpperCase() + entity.type.slice(1);
    }

    if (onlyValid) {
      return documents.filter(doc => doc.idPieceAFournir === piece.id && doc[idKey] === entity.id && doc.valid);
    }

    return documents.filter(doc => doc.idPieceAFournir === piece.id && doc[idKey] === entity.id);
  }

  getEntityErrors(entity: GedEntity) {
    const documents = this.dataByType[entity.type].documents;
    const mandatoryPieces = this.dataByType[entity.type].pieces.filter(p => this.isPieceMandatory(p));
    const errorPieces = mandatoryPieces.filter(p => !this.getDocumentsFor(documents, p, entity, true).length && p.typeStructure === entity.typeStructure);
    return errorPieces.length ? 'Documents manquants pour les pièces suivantes : \n' + errorPieces.map(p => p.name).join(',') : null;
  }

  refreshDocuments(documents: GedDocument[]) {
    this.knownTypes.forEach(type => {
      if (this.dataByType.hasOwnProperty(type)) {
        this.dataByType[type].documents = this.parseDocuments(type, documents);
      }
    });
  }

  refreshDocumentsAssmat(documents: GedDocument[]) {
    if (this.dataByType.hasOwnProperty('assistant maternel')) {
      this.dataByType['assistant maternel'].documents = this.parseDocuments('assistant maternel', documents);
    }
  }

  refreshErrors() {
    Object.keys(this.dataByType).forEach(type => {
      if (type === 'inscription' || type === 'preinscription') {
        for (const key in this.dataByType[type].entitiesByTypeStructure) {
          if (Object.prototype.hasOwnProperty.call(this.dataByType[type].entitiesByTypeStructure, key)) {
            if (key !== 'show') {
              const structure = this.dataByType[type].entitiesByTypeStructure[key];
              structure.entities.forEach(e => e.error = this.getEntityErrors(e));
            }
          }
        }
      } else {
        this.dataByType[type].entities.forEach(e => e.error = this.getEntityErrors(e));
      }
    });
  }

  onFileSelect(fileList: FileList, entite: GedEntity, piece: GedPiece) {
    if (fileList.length) {
      this.uploadingFile = fileList[0];
      this.errorMessage = this.gedService.checkDocuments(fileList, this.config, this.uploadingFile)
      this.openFileUploadDialog(entite, piece);
    }
  }

  onToggleInvalideFile() {
    this.showInvalideFile = !this.showInvalideFile;
  }

  openPieceDetailsDialog(piece: GedPiece) {
    const data = {
      piece,
      mandatoryAccueils: piece.accueils.filter(acc => acc.obligatoire),
      optionalAccueils: piece.accueils.filter(acc => !acc.obligatoire)
    };

    const dial = this.matDialog.open(this.pieceDetailsDialog, { data, maxWidth: 600 });

    this.platformService.adaptDialogToScreen(dial);
  }

  openFileUploadDialog(entite, piece) {
    this.uploadDialogRef = this.matDialog.open(FileUploadDialogComponent, {
      data: {
        entite,
        piece,
        errorMessage: this.errorMessage,
        uploadingFile: this.uploadingFile
      },
      maxWidth: 600
    });

    this.platformService.adaptDialogToScreen(this.uploadDialogRef);

    // CANCEL
    this.uploadDialogRef.componentInstance.onCancel.pipe(
      takeUntil(this.uploadDialogRef.afterClosed())
    ).subscribe(result => {
      if (result) {
        this.uploadDialogRef.close();
      }
    })

    // SUBMIT
    this.uploadDialogRef.componentInstance.onSubmit.pipe(
      takeUntil(this.uploadDialogRef.afterClosed())
    ).subscribe(result => {
      if (result) {
        this.uploadDialogRef.close();
        if (this.idAssmat && this.accountType === "assmat") {
          //Reload assmat documents & refresh stuff
          this.gedService.getAssmatDocuments(this.idAssmat).subscribe(data => {
            this.refreshDocumentsAssmat(data);
            this.refreshErrors();
          })
        } else {
          // Reload family documents & refresh stuff
          this.gedService.getCurrentFamilyDocuments().subscribe(data => {
            this.refreshDocuments(data);
            this.refreshErrors();
          });
        }
      }
    });

    this.uploadDialogRef.afterClosed().subscribe(_ => {
      this.previewURL = null;
      this.errorMessage = null;
      this.inputFile.map(input => input.nativeElement.value = null);
    });
  }

  getExtension(fileName: string) {
    return this.gedService.getExtension(fileName);
  }

  getFileIcon(doc: GedDocument) {
    return this.gedService.getFileIcon(doc);
  }

  checkExtensionStartsWith(fileName: string, nameStart) {
    return this.getExtension(fileName).startsWith(nameStart);
  }

  openDocDetails(doc: GedDocument) {
    const piece = this.config.listePiecesAFournir.find(p => p.id === doc.idPieceAFournir);

    const dialog = this.matDialog.open(DocDetailsComponent, {
      data: { doc, piece, documentDownload: this.documentDownload }
    });

    this.platformService.adaptDialogToScreen(dialog);

    dialog.afterClosed().subscribe(_ => {
      window.URL.revokeObjectURL(this.previewURL);
      this.previewURL = null;
    });
  }

  downloadDocument(doc: GedDocument) {
    this.gedService.downloadDocument(doc, this.documentDownload);
  }

  deleteDocument(doc: GedDocument) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: { message: `Voulez vous supprimer le fichier : ${doc.nomFichier} ?` },
      maxWidth: 500
    });

    this.platformService.adaptDialogToScreen(dialogRef);

    dialogRef.afterClosed().subscribe(closeCode => {

      if (closeCode) {
        this.gedService.delete(doc.idDocumentElectronique).subscribe(res => {
          this.snackbarService.info('Document supprimé');

          // delete locally
          this.deleteLocalDoc(doc.idDocumentElectronique);

        });
      }
    });
  }

  deleteLocalDoc(id: number) {
    this.knownTypes.forEach(type => {
      this.dataByType[type]?.documents.forEach((x, index) => {
        if (x.idDocumentElectronique === id) {
          this.dataByType[type]?.documents.splice(index, 1);
          this.refreshErrors();
        }
      });
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
