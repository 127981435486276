import { Component, OnInit, Input } from '@angular/core';
import { GedService } from '@app/services/ged.service';

@Component({
    selector: 'app-file-preview',
    templateUrl: './file-preview.component.html',
    styleUrls: ['./file-preview.component.scss'],
    standalone: false
})
export class FilePreviewComponent implements OnInit {

  @Input() previewURL: string;
  @Input() fileName: string;
  extension: string;

  constructor(
    private gedService: GedService
  ) { }

  ngOnInit(): void {
    this.extension = this.gedService.getExtension(this.fileName);
  }
}
