import { Directive, Input, HostBinding, KeyValueDiffers, KeyValueDiffer, DoCheck } from '@angular/core';
import { CustomStyleService } from '@app/services/custom-style.service';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
    selector: '[portailCustomStyle]',
    exportAs: 'portailCustomStyle',
    standalone: false
})
export class CustomStyleDirective implements DoCheck {

  private currentStyle;
  private differ: KeyValueDiffer<string, string | number>;

  @Input('portailCustomStyle') set styleObject(value) { this.setStyle(value || {}); }

  @Input() include: string[];
  @Input() exclude: string[];

  @HostBinding('style') styleAttr;

  constructor(
    private customStyleService: CustomStyleService,
    private sanitizer: DomSanitizer,
    private differs: KeyValueDiffers
  ) {
  }

  setStyle(value) {
    this.currentStyle = value;
    if (!this.differ) {
      this.differ = this.differs.find(value).create();
    }
  }

  ngDoCheck(): void {
    // Try to optimize handling of custom style using KeyValueDiffers (NgStyle way)
    if (this.differ && this.differ.diff(this.currentStyle)) {
      // Apply changes only if some of style object has changed
      const parsed = this.customStyleService.parseCustomStyle(this.currentStyle, this.include, this.exclude);
      this.styleAttr = this.sanitizer.bypassSecurityTrustStyle(parsed);
    }
  }

}
