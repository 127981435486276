import { Component, OnInit, Input } from '@angular/core';
import { Facture } from '@app/models/facturation';
import { FactureLineGroup, FacturationService } from '@app/services';

@Component({
    selector: 'app-facture-line-table',
    templateUrl: './line-table.component.html',
    styleUrls: ['./line-table.component.scss'],
    standalone: false
})
export class FactureLineTableComponent implements OnInit {

  @Input() facture: Facture;
  @Input() total: number;

  lineGroups: FactureLineGroup[];

  constructor(
    private factureService: FacturationService
  ) { }

  ngOnInit(): void {
    // Maybe set it on the facture directly
    this.lineGroups = this.factureService.getIndexedLines(this.facture.lines || []);
  }
}
