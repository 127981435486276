import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { UserService } from '@app/services/user.service';
import { PlatformService, FormConfigService, AuthenticationService, FamilyService } from '@app/services';
import { PermissionService } from '@app/services/permission.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StepType } from '@app/forms/config/form-model';
import { Adulte } from '@app/models/adulte';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '@app/models/user';
import { AuthentificationConfig } from '@app/models/authentification-config';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
    standalone: false
})
export class UserInfoComponent implements OnInit {

  @ViewChild('dialogInformations') dialogInformations: TemplateRef<any>;

  user: User;

  @Input() userData: Adulte;

  adulteFormSteps: StepType[];
  listKeysFieldForm: string[];

  authConfig: AuthentificationConfig;

  editable: boolean;

  @ViewChild('updateEmailDialog') updateEmailDialog: TemplateRef<any>;
  updateEmailDialogRef: MatDialogRef<any>;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    public permService: PermissionService,
    public platformService: PlatformService,
    private formConfigService: FormConfigService,
    private familyService: FamilyService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    if (this.familyService.currentFamily) {
      this.editable = this.permService.hasPermission('account_edit') && this.familyService.currentFamily.active;
    } else {
      this.editable = this.permService.hasPermission('account_edit');
    }

    this.authService.currentUser$.pipe(take(1)).subscribe(u => this.user = u);
    this.authService.getConf().subscribe((conf: AuthentificationConfig) => this.authConfig = conf || ({} as AuthentificationConfig));

    if (!this.userData) {
      // could use userService.currentAdulte, but as we want to refresh data from backend 
      this.userService.getCurrentUserData().subscribe((ad: Adulte) => this.userData = ad || {} as Adulte);
    }

    this.loadAdulteForm().subscribe(steps => {
      this.adulteFormSteps = steps;
      this.listKeysFieldForm = this.formConfigService.getKeysListOfFormSteps(steps);
    });
  }

  loadAdulteForm() {
    const filteredSteps = ['connexion', 'validation'];
    return this.formConfigService.getForm('form-adulte').pipe(map(steps => steps.filter(step => !filteredSteps.includes(step.stepName))));
  }



  onClickChangeEmail() {
    this.updateEmailDialogRef = this.dialog.open(this.updateEmailDialog, {
      maxWidth: 500,
      ariaLabel: 'Modification de l\'adresse email',
      ariaModal: true,
    });
    this.platformService.adaptDialogToScreen(this.updateEmailDialogRef);
  }

  closeUpdateEmailDialog() {
    if (this.updateEmailDialogRef) {
      this.updateEmailDialogRef.close();
      this.updateEmailDialogRef = null;
    }
  }
}
