<div class="conteneur" *ngIf="config && preinscription && preInscriptionsActive; else loader">

  <mat-card appearance="outlined" class="info-card" *ngIf="config.infoMessage"
    [portailCustomStyle]="config.styleCardInfo">
    <div [innerHtml]="config.infoMessage | safe"></div>
  </mat-card>

  <mat-card appearance="raised" *ngIf="options; else loader"
    [ngClass]="{'form-container': !isMobile, 'form-container-mobile': isMobile}">
    <mat-card-header class="card-header fx-direction-row fx-center-center">

      <mat-card-title class="fx-direction-row fx-center-center">
        <span *ngIf="!isEdit">{{ 'cogito.new_preinscription'|trans }}</span>
        <span *ngIf="isEdit">{{ preinscription.prenom }} {{ preinscription.nom }}</span>
      </mat-card-title>
      <mat-card-subtitle *ngIf="isEdit">
        Année scolaire : {{preinscription.anneeScolaire.designation}}
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <ng-container *ngIf="!isEdit">

        <p>
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Enfant</mat-label>
            <mat-select [(ngModel)]="preinscription.idEnfant" (ngModelChange)="childChanged($event)"
              [disabled]="fromDemarche && usager" required>
              <mat-option *ngFor="let child of options.childList" [value]="child.id">
                {{ child.prenom }} {{ child.nom }}
                <span *ngIf="child.age && preinscription.anneeScolaire">({{ child.age.years + ' ans au ' +
                  setDisplayDateToCalculate(dateToCalculate) }})</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </p>

        <p>
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Année scolaire</mat-label>
            <mat-select [(ngModel)]="preinscription.anneeScolaire"
              [disabled]="!config.enabledSelectAnneeScolaire || !preinscription.idEnfant"
              (ngModelChange)=" anneeScolaireChanged($event)" [compareWith]="compareAnneeFn" required>
              <mat-option *ngFor="let anneeScolaire of config.listAnneesScolaires" [value]="anneeScolaire">
                {{ anneeScolaire.designation }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </p>

      </ng-container>

      <p>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Niveau scolaire</mat-label>
          <mat-select [(ngModel)]="preinscription.niveauScolaire" (ngModelChange)=" niveauScolaireChanged($event)"
            [disabled]="!config.enabledSelectNiveauScolaire || !preinscription.idEnfant || !preinscription.anneeScolaire"
            required>
            <mat-option *ngFor="let niveauScolaire of listNiveauxScolairesAvailable"
              [value]="niveauScolaire.idNiveauScolaire">
              {{ niveauScolaire.designation }}
            </mat-option>
          </mat-select>
          <mat-hint
            *ngIf="config.enabledSelectNiveauScolaire && preinscription.idEnfant && preinscription.anneeScolaire && config.limitSelectNiveauScolaire !== 'NoLimit'">
            <mat-icon color="primary" style="margin-right: 5px;">info</mat-icon>
            <span>{{ 'cogito.edit.message_niveau_scolaire'|trans }}</span>
          </mat-hint>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field
          *ngIf="demandeDerogation && config.enabledDerogationObligatoire && libelleSecteurScolaireOrigine"
          subscriptSizing="dynamic">
          <mat-label>Secteur scolaire d'origine</mat-label>
          <input matInput [value]="libelleSecteurScolaireOrigine" readonly disabled>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field *ngIf="demandeDerogation" subscriptSizing="dynamic">
          <mat-label>Motif de dérogation</mat-label>
          <mat-select [(ngModel)]="preinscription.idMotifDerogation" required>
            <mat-option *ngFor="let motif of config.listMotifsDerogation" [value]="motif.idMotifDerogation">
              {{ motif.motif }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>
            {{demandeDerogation ? 'Secteur scolaire de destination' : 'Secteur scolaire'}}
          </mat-label>
          <mat-select [(ngModel)]="preinscription.secteurScolaire" (ngModelChange)="secteurScolaireChanged($event)"
            [disabled]="!preinscription.idEnfant || !preinscription.niveauScolaire || (config.enabledDerogationObligatoire && !preinscription.idMotifDerogation)"
            required>
            <mat-option *ngFor="let secteurScolaire of listSecteursScolaireAvailable"
              [value]="secteurScolaire.idSecteurScolaire">
              {{ secteurScolaire.libelle }}
            </mat-option>
          </mat-select>
          <mat-hint
            *ngIf="config.enabledDerogationObligatoire && preinscription.niveauScolaire && !isEdit && !demandeDerogation"
            style="margin-block:5px;">
            <mat-icon color="primary" style="margin-right: 5px;">info</mat-icon>
            <span>{{ 'cogito.edit.message_derogation_secteur_scolaire'|trans }}</span>
          </mat-hint>
        </mat-form-field>
      </p>

      <p>
        <button *ngIf="config.enabledDerogationObligatoire && !isEdit" mat-raised-button
          [color]="demandeDerogation ? 'warn': 'accent'"
          [disabled]="!preinscription.idEnfant || !preinscription.niveauScolaire" (click)="onClickDemandeDerogation()">
          {{demandeDerogation ? 'Supprimer la demande de dérogation' : 'Demande de dérogation'}}
        </button>
      </p>

      <p>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>École</mat-label>
          <mat-select [(ngModel)]="preinscription.ecole"
            [disabled]="!preinscription.idEnfant || !preinscription.secteurScolaire || !preinscription.niveauScolaire"
            required>
            <mat-option *ngFor="let ecole of listEcolesAvailable" [value]="ecole.idEcole">
              {{ ecole.libelle }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Commentaire</mat-label>
          <textarea matInput [(ngModel)]="preinscription.commentaire" cdkTextareaAutosize
            cdkAutosizeMinRows="3"></textarea>
        </mat-form-field>
      </p>

    </mat-card-content>

    <mat-card-actions style="display: flex; justify-content: center; align-items: center;">
      <button mat-raised-button color="primary" (click)="onClickValidatePreInscription()"
        [disabled]="saving || !preinscription.idEnfant || !preinscription.ecole">
        <div class="fx-direction-row fx-start-center">
          <span>Valider</span>
          <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24"
            style="margin-left: 5px;"></mat-progress-spinner>
        </div>
      </button>
    </mat-card-actions>

  </mat-card>

</div>

<ng-template #loader>
  <app-loader class="margin" *ngIf="!config || !preinscription"></app-loader>
  <h3 *ngIf="!preInscriptionsActive && config && preinscription" style="text-align: center;">
    {{ 'cogito.preinscription_disabled'|trans }}</h3>
</ng-template>