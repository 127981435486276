<app-sticky-action-bar *ngIf="!documentPicker" [noSave]="true">
  <ng-container *ngTemplateOutlet="tableControls"></ng-container>
</app-sticky-action-bar>

<ng-template #tableControls>
  <div *ngIf="!readOnly || readOnly === 'add'" class="table-controls">
    <button (click)="OpenUploadFileDialog()" type="button" mat-raised-button color="accent-lighter">
      <mat-icon>add</mat-icon>
      Ajouter
    </button>

    <button type="button" mat-raised-button color="primary" (click)="switchDisplayMode()">
      <mat-icon>{{ displayMode === 'grid' ? 'view_list' : 'grid_view' }}</mat-icon>
      <span>Affichage</span>
    </button>
  </div>
</ng-template>

<div class="file-manager-root">
  <!-- Upload data placeholder, maybe better in a Dialog ... -->

  <div class="table-actions">
    <mat-form-field class="search-field" subscriptSizing="dynamic">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Rechercher">
    </mat-form-field>

    <span class="spacer"></span>

    <ng-container *ngIf="documentPicker">
      <ng-container *ngTemplateOutlet="tableControls"></ng-container>
    </ng-container>
  </div>

  <app-loader-overlay *ngIf="loading"></app-loader-overlay>
  <div *ngIf="displayMode === 'list'" class="mat-elevation-z2">
    <div class="table-content thin-scrollbar {{!!selectMode ? 'in-dialog' : ''}}">

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortActive="creationDate"
        matSortDirection="desc">

        <ng-container *ngIf="selectMode" matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="selection.toggle(row)" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Nom du document</th>
          <td class="file-name-column" mat-cell *matCellDef="let file">{{ file.name }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</th>
          <td class="file-type-column" mat-cell *matCellDef="let file">
            <app-icon size="30" *ngIf="getIcon(file.type) as icon; else default" type="far" [icon]="'file-'+icon"
              [class.invalid]="!valid"></app-icon>
            <ng-template #default><i class="far fa-file"></i></ng-template>
          </td>

        </ng-container>

        <ng-container matColumnDef="taille">
          <th mat-header-cell style="width: 100px;" *matHeaderCellDef mat-sort-header="taille">Taille</th>
          <td class="file-taille-column" mat-cell *matCellDef="let file">{{ file.taille }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="creationDate">Date d'ajout</th>
          <td class="file-date-column" mat-cell *matCellDef="let file">{{ file.creationDate|date:'shortDate' }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width: 150px; padding: 0;"></th>
          <td mat-cell *matCellDef="let file" class="text-center">
            <mat-icon class="list-btn" matTooltip="Visualiser" (click)="openFile(file)">remove_red_eye</mat-icon>
            <mat-icon class="list-btn" color="primary" matTooltip="Modifier" (click)="onClickEdit(file)">edit
            </mat-icon>
            <mat-icon class="list-btn" matTooltip="Supprimer le fichier" (click)="onClickDelete(file)" color="warn">
              delete
            </mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let preview" [attr.colspan]="displayedColumns.length">
            <div class="fx-direction-col fx-center-center preview-detail"
              [@detailExpand]="preview === expandedElement ? 'expanded' : 'collapsed'">
              <div class="preview-container" *ngIf="expandedElement === preview || preview.loaded"
                [ngStyle]="{'display': expandedElement === preview ? 'block' : 'none'}">
                <app-loader *ngIf=" !preview.loaded "></app-loader>
                <img [src]="('files/' + preview.id)|apiUrl" (load)="preview.loaded=true">
              </div>


              <div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <ng-container *ngIf="selectMode">
          <tr mat-row *matRowDef="let file; columns: displayedColumns;"
            [ngClass]="{'primary-lighter-bg': selection && selection.isSelected(row)}" class="example-element-row"
            [class.preview-expanded-row]="expandedElement === file" (click)="onSelect(file)">
          </tr>
        </ng-container>

        <ng-container *ngIf="!selectMode">
          <tr mat-row *matRowDef="let file; columns: displayedColumns;"
            [ngClass]="{'primary-lighter-bg': selection && selection.isSelected(row)}" class="example-element-row"
            [class.preview-expanded-row]="expandedElement === file" (click)="onSelect(file) ">
          </tr>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="preview-detail-row">
        </tr>

      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[20, 50, 100, 200]"></mat-paginator>
  </div>

  <div *ngIf="displayMode === 'grid'" class="grid-container">
    <div *ngFor="let file of dataSource.data" class="grid-item" (click)="onSelect(file)"
      [class.selected]="selection?.isSelected(file)">
      <img [src]="('files/' + file.id)|apiUrl">

      <div class="row items-center">
        <span class="name">{{ file.name }}</span>
        <span *ngIf="selection?.isSelected(file)" class="selected-check">
          <mat-icon color="accent">check_box</mat-icon>
        </span>
      </div>
    </div>
  </div>
</div>