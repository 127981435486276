import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadedFile } from '@app/services/file.service';

@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
    standalone: false
})
export class DocumentEditComponent implements OnInit {

  file: UploadedFile;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit() {
    this.file = this.data.file || {};
    this.title = this.file.name;
  }
}
