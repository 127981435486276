import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BoardCard } from '@app/models/board-card';
import { PlatformService } from '@app/services';

// const linkByComponent = {
//   login: '/login',
//   news: '/news',
//   account: '/account'
// };

@Component({
  selector: 'app-home-board',
  templateUrl: './home-board.component.html',
  styleUrls: ['./home-board.component.scss'],
  standalone: false
})
export class HomeBoardComponent implements OnInit {

  @Input() boardCards: BoardCard[];
  @Input() compact = false;
  @Input() isAdmin: boolean;

  headerStyleParts = ['borderColor', 'backgroundColor', 'borderRadius'];

  constructor(
    public platformService: PlatformService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  getCardClasses(card: BoardCard) {
    const classes = [];

    if (card.width) {
      classes.push(`flex-${card.width}p`);
    } else {
      classes.push('flex-1'); // default
    }

    return classes;
  }

  onCardChange() {
    this.cd.markForCheck();
  }

  // For now link is computed at display time, see later if we want to set it directly on object at save
  getLink(card: BoardCard) {

    if (card.link) {
      return card.link;
    }

    if (card.type === 'component') {
      // return card.component in linkByComponent ? linkByComponent[card.component] : null;
    }

    return null;
  }
}
