import { Component, OnInit, Inject, EventEmitter, OnDestroy } from '@angular/core';
import { ChildService, PlatformService, SnackbarService } from '@app/services';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Child } from '@app/models/child';
import { GedConfig } from '@app/models/ged';
import { GedService } from '@app/services/ged.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-edit-photo',
    templateUrl: './edit-photo.component.html',
    styleUrls: ['./edit-photo.component.scss'],
    standalone: false
})
export class EditPhotoComponent implements OnInit, OnDestroy {

  onSave = new EventEmitter<any>();
  fileName = '';
  previewURL = null;
  file: File;
  uploadProgress: number = null;
  child: Child;
  loadingPhoto: boolean;
  loadedGedConfig: boolean;
  configGed: GedConfig
  errorMessage: string;
  isMobile: boolean;
  private onDestroy$ = new Subject<void>();

  constructor(
    private childService: ChildService,
    private snackbarService: SnackbarService,
    private sanitizer: DomSanitizer,
    private gedService: GedService,
    public platformService: PlatformService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.child = this.data;
  }

  ngOnInit(): void {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => this.isMobile = value);

    this.file = null;
    this.fileName = "";
    this.gedService.getConfig().subscribe(conf => {
      this.configGed = conf;
      this.loadedGedConfig = true;
    })

    if (this.child.photo) {
      this.loadingPhoto = true;
      this.getPhoto();
    }
  }

  getExtension(fileName: string) {
    return fileName.split('.').pop().toLowerCase();
  }

  getPhoto() {
    this.loadingPhoto = true;
    this.childService.getPhoto(this.child.id).subscribe({
      next: resp => {
        if (resp.type === 'response' && resp.value.type.startsWith('image/')) {
          this.previewURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(resp.value));
        }
      },
      error: err => this.snackbarService.error('Erreur lors de la récupération de la photo !'),
      complete: () => {
        this.loadingPhoto = false;
      }
    });
  }

  onFileSelected(event) {
    this.errorMessage = "";
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    if (this.file && this.file.type.startsWith('image/')) {
      this.loadPreview(this.file);
      this.checkSizePhoto(this.configGed, this.file);
    } else {
      this.previewURL = null;
      this.errorMessage = "Ce type de fichier n'est pas autorisé.";
    }
  }

  checkSizePhoto(configGed: GedConfig, uploadingFile: File) {
    const fileConfigSize = +configGed.size * 1048576;
    if (uploadingFile.size > fileConfigSize) {
      this.errorMessage = `Fichier trop volumineux. Le fichier ne doit pas dépasser ${configGed.size}Mo.`;
    }
  }


  loadPreview(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader.result) {
        this.previewURL = reader.result;
      } else {
        this.previewURL = null;
      }
    };
  }

  savePhoto() {
    let progressUpdatedAt = Date.now();
    this.uploadProgress = 0;
    this.childService.sendPhoto(this.file, this.child.id).subscribe({
      next: event => {
        const now = Date.now();
        if (event.type === 'up_progress' && event.value > this.uploadProgress && (event.value === 100 || now - progressUpdatedAt > 300)) {
          this.uploadProgress = event.value as number;
          progressUpdatedAt = now;
        }
      },
      error: err => this.snackbarService.error('Erreur lors de l\'envoi du fichier : ' + err),
      complete: () => {
        this.uploadProgress = null;
        this.onSave.emit(this.previewURL);
        this.snackbarService.info('Fichier envoyé !');
      }
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // deletePhoto() {
  //   this.file = null;
  //   this.fileName = "";
  //   this.previewURL = null;
  //   this.savePhoto();
  //   this.childService.deletePhoto(this.idEnfant).subscribe(_ => {
  //     this.previewURL = null;
  //     this.snackbarService.info('Photo supprimée !')
  //   }, err => {
  //     this.snackbarService.error('Erreur lors de la suppression de la photo !')
  //   })
  // }

}
