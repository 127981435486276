<h3 *ngIf="config && !inscriptionsActive" style="text-align: center;">{{ 'diabolo.inscription_disabled'|trans }}</h3>

<div class="conteneur" *ngIf="config && inscription && inscriptionsActive; else loader">

  <mat-card appearance="raised" class="info-card" *ngIf="config.infoMessage"
    [portailCustomStyle]="config.styleCardInfo">
    <div [innerHtml]="config.infoMessage | safe"></div>
  </mat-card>

  <mat-card appearance="outlined" *ngIf="inscriptionError" class="warn-bg" style="margin-bottom: 5px;">
    <mat-card-content>
      <span *ngIf="inscriptionError">
        {{ ('diabolo.error.form_incomplete.' + inscriptionError)|trans }}
      </span>
      <ul class="errors" *ngIf="inscriptionErrorSteps">
        <li *ngFor="let errorStep of inscriptionErrorSteps">
          <a [routerLink]="errorStep.link">{{ errorStep.label }}</a>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>

  <div *ngIf="dossierAlreadyExist" style="text-align: center;">
    <h3>{{'diabolo.inscription_already_exist'|trans}}</h3>
  </div>

  <ng-container *ngIf="!dossierAlreadyExist">
    <mat-card *ngIf="isLoaded; else loader"
      [ngClass]="{'form-container': !isMobile, 'form-container-mobile': isMobile}">
      <mat-card-header class="card-header fx-direction-row fx-center-center">
        <mat-card-title class="fx-direction-row fx-center-center">
          <span *ngIf="!isEdit">{{ 'diabolo.new_inscription'|trans }}</span>
          <span *ngIf="isEdit">{{ inscription.nom }} {{inscription.prenom}}</span>
        </mat-card-title>
        <mat-card-subtitle *ngIf="isEdit">
          <label>{{ 'diabolo.edit.card_subtitle'|trans }}</label>
          <ng-container *ngIf="inscription.dateDebut && inscription.dateDebut !== '0000-00-00'">
            <span *ngIf="inscription.dateFin && inscription.dateFin !== '0000-00-00'; else fromDate">
              Du {{ inscription.dateDebut|date:'shortDate' }} au {{ inscription.dateFin|date:'shortDate' }}
            </span>
            <ng-template #fromDate>
              <span>À partir du {{ inscription.dateDebut|date:'shortDate' }}</span>
            </ng-template>
          </ng-container>
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content style="padding-top: 25px;">
        <ng-container *ngIf="!isEdit">
          <mat-form-field>
            <mat-label>{{ 'entity.usager'|trans }}</mat-label>
            <mat-select [value]="selectedUsager" required panelClass="usager-select"
              (selectionChange)="onChangeUsager($event)" [(ngModel)]="inscription.usager" [disabled]="fromDemarche &&
          usager">
              <mat-optgroup>
                {{ 'entity.adults'|trans }}
                <ng-container *ngFor="let usager of listUsagers">
                  <mat-option *ngIf="usager.type === 'adulte'" [value]="usager">
                    {{ usager.prenom }} {{ usager.nom }}
                  </mat-option>
                </ng-container>
              </mat-optgroup>

              <mat-optgroup>
                {{ 'entity.children'|trans }}
                <ng-container *ngFor="let usager of listUsagers">
                  <mat-option *ngIf="usager.type === 'enfant'" [value]="usager">
                    {{ usager.prenom }} {{ usager.nom }}
                    <span *ngIf="usager.age">({{ 'enfant.age'|trans:{age: usager.age.years} }})</span>
                  </mat-option>
                </ng-container>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <div *ngIf="config.optionDatesInscription === 'enabledDatesInscription'"
            class="fx-direction-row fx-wrap fx-center-center" [ngStyle]="{'width': isMobile ? '100%' : '80%'}"
            style="margin: auto;">
            <mat-form-field class="date-field">
              <mat-label>Du</mat-label>
              <input matInput [matDatepicker]="openFromDatePicker" [min]="dateNow" [max]="inscription.dateFin"
                [(ngModel)]="inscription.dateDebut" placeholder="jj/mm/aaaa"
                (dateChange)="dateChange($event,'dateDebut')">
              <mat-datepicker-toggle matSuffix [for]="openFromDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #openFromDatePicker disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="date-field">
              <mat-label>au</mat-label>
              <input matInput [matDatepicker]="openUntilDatePicker" [min]="inscription.dateDebut"
                [(ngModel)]="inscription.dateFin" placeholder="jj/mm/aaaa" (dateChange)="dateChange($event,'dateFin')">
              <mat-datepicker-toggle matSuffix [for]="openUntilDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #openUntilDatePicker disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>

          <div *ngIf="config.optionDatesInscription === 'enabledDateDebutInscription'"
            class="fx-direction-row fx-wrap fx-center-center" style="margin: auto;">
            <mat-form-field>
              <mat-label>À partir du</mat-label>
              <input matInput [matDatepicker]="openFromDatePicker" [(ngModel)]="inscription.dateDebut"
                placeholder="jj/mm/aaaa" (dateChange)="dateChange($event,'dateDebut')">
              <mat-datepicker-toggle matSuffix [for]="openFromDatePicker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #openFromDatePicker disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>

        </ng-container>
        <div [ngStyle]="{'width': isMobile ? '100%' : '80%'}" class="container-periode">
          <label style="font-weight: bold;">{{ 'diabolo.edit.selection_periode'|trans }}</label>

          <div *ngIf="!loadingPeriode; else loader" style="margin-top: 20px;" class="treeview-container">

            <!-- <button mat-raised-button *ngIf="inscription.usager && !noAvailablePeriodes" color="accent-lighter" (click)="toggleExpandAll()"
          [matTooltip]="isAllExpanded ? 'Tout refermer' : 'Tout ouvrir'" style="margin-bottom: .5em;">
              <mat-icon>{{ isAllExpanded ? 'chevron_right' : 'expand_more' }}</mat-icon>
              Tous
          </button> -->

            <mat-tree *ngIf="treeSource" [dataSource]="treeSource" [treeControl]="treeControl" class="tree">

              <mat-nested-tree-node *matTreeNodeDef="let periode; when: isPeriode" matTreeNodeToggle>
                <div class="node-title" style="margin-top: 5px;">
                  <i class="icon icodomino-periode" [ngClass]="{'accent': selectionModel.isSelected(periode)}"></i>
                  <mat-checkbox [checked]="selectionModel.isSelected(periode)"
                    (change)="selectionModel.toggle(periode); runProgramsOnChangePeriodes();"
                    [disabled]="periode.disabled || !loadedParamsLibres">
                    {{ periode.name }} {{ periode.label ? '(' + periode.label + ')' : '' }}
                  </mat-checkbox>
                </div>
              </mat-nested-tree-node>

              <mat-nested-tree-node *matTreeNodeDef="let node; when: !isPeriode">
                <div class="node-title" style="cursor: pointer;  margin-top: 10px;" matTreeNodeToggle>
                  <mat-icon [attr.aria-label]="'toggle ' + node.name" color="primary">
                    {{ treeControl.isExpanded(node) ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                  </mat-icon>

                  <i class="icon icodomino-etablissement"
                    [ngClass]="{'accent': descendantsAllSelected(node) || descendantsPartiallySelected(node)}"
                    fxFlexAlign="center" style="margin-right: 5px;" *ngIf="node.level === 'etablissement'"></i>
                  <i class="icon icodomino-accueil"
                    [ngClass]="{'accent': descendantsAllSelected(node) || descendantsPartiallySelected(node)}"
                    fxFlexAlign="center" style="margin-right: 5px;" *ngIf="node.level === 'accueil'"></i>
                  {{ node.name }}

                </div>
                <div [class.tree-node-invisible]="!treeControl.isExpanded(node)" role="group">
                  <ng-container matTreeNodeOutlet></ng-container>
                </div>
              </mat-nested-tree-node>
            </mat-tree>
          </div>
          <div *ngIf="!inscription.usager" style="margin-top: 20px;">{{ 'diabolo.edit.no_user_selected'|trans }}</div>
          <div *ngIf="noAvailablePeriodes">{{ 'diabolo.edit.no_periods_available'|trans }}</div>
        </div>

        <ng-template #loader>
          <app-loader style="margin-top: 20px;"> {{ 'diabolo.edit.load_periodes'|trans }}</app-loader>
        </ng-template>

        <ng-container *ngIf="!loadingPeriode || !config?.programsOnSelectPeriode.length">
          <ng-container
            *ngIf="(selectionModel?.selected.length && config?.programsOnSelectPeriode.length) || !config?.programsOnSelectPeriode.length">
            <div style="width: 80%; margin: auto;" *ngIf="loadedParamsLibres; else loaderParamsLibres">
              <app-params-libre *ngFor="let paramLibre of paramsLibres" class="params-libres-component"
                [paramLibre]="paramLibre">
              </app-params-libre>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #loaderParamsLibres>
          <app-loader message="{{ 'diabolo.edit.load_params_libres'|trans }}" style="margin-top: 20px;">
          </app-loader>
        </ng-template>

        <mat-form-field *ngIf="config.enabledComment">
          <mat-label>Commentaire</mat-label>
          <textarea matInput [(ngModel)]="inscription.commentaire" cdkTextareaAutosize
            cdkAutosizeMinRows="3"></textarea>
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions class="fx-center-center">
        <button mat-raised-button color="primary" (click)="onClickValidateInscription()" [disabled]="saving ||
                  !loadedParamsLibres ||
                  !checkParamsLibres() ||
                  !inscription.usager ||
                  !selectionModel.selected.length ||
                  !checkValidityDates(inscription.dateDebut, inscription.dateFin) ||
                  loadingPeriode">
          Valider
          <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent-lighter"
            class="icon-loader"></mat-progress-spinner>
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</div>

<ng-template #loader>
  <app-loader class="margin" *ngIf="!config || !inscription || !isLoaded"></app-loader>
  <h3 *ngIf="!inscriptionsActive && config && inscription && isLoaded" style="text-align: center;">
    {{ 'diabolo.inscription_disabled'|trans }}</h3>
</ng-template>