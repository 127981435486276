<app-dialog-header [title]="data.title">
  <button mat-icon-button *ngIf="addressResp1Exist && !data.field.readOnly" [matMenuTriggerFor]="optionsRecopy"
    matTooltip="Options">
    <mat-icon>settings</mat-icon>
  </button>
</app-dialog-header>

<mat-menu #optionsRecopy="matMenu">
  <div style="text-align: center; padding:10px;"> <b>Recopie des adresses :</b> </div>
  <button mat-menu-item *ngIf="addressResp2Exist && addressResp1Exist" (click)="copyAddressResp1ToResp2()">
    <span
      class="fx-direction-row fx-align-item-center">{{'formulaire.contrat_accueil_mentalo.option_bloc_famille.responsable1'
      | trans}} <mat-icon style="margin-right: 0;">arrow_right_alt</mat-icon>
      {{'formulaire.contrat_accueil_mentalo.option_bloc_famille.responsable2' | trans}}</span>
  </button>
  <button mat-menu-item *ngIf="addressResp1Exist && addressCorrespondanceExist"
    (click)="copyAddressResp1ToCorrespondance()">
    <span
      class="fx-direction-row fx-align-item-center">{{'formulaire.contrat_accueil_mentalo.option_bloc_famille.responsable1'
      | trans}}<mat-icon style="margin-right: 0;">arrow_right_alt</mat-icon>
      {{'formulaire.contrat_accueil_mentalo.option_bloc_famille.correspondance' | trans}}</span>
  </button>
  <button mat-menu-item *ngIf="addressResp2Exist && addressCorrespondanceExist"
    (click)="copyAddressResp2ToCorrespondance()">
    <span
      class="fx-direction-row fx-align-item-center">{{'formulaire.contrat_accueil_mentalo.option_bloc_famille.responsable2'
      | trans}}<mat-icon style="margin-right: 0;">arrow_right_alt</mat-icon>
      {{'formulaire.contrat_accueil_mentalo.option_bloc_famille.correspondance' | trans}}</span>
  </button>
</mat-menu>

<form [formGroup]="form" (keydown.enter)="onEnter($event)" (ngSubmit)="onSubmit()" *ngIf="fields">
  <div mat-dialog-content>
    <formly-form class="display-flex-row flex-wrap" [form]="form" [fields]="fields" [model]="model"
      [options]="data.options">
    </formly-form>
  </div>

  <div mat-dialog-actions>
    <button type="button" class="close-dialog" mat-raised-button color="warn" (click)="onCancel()">
      <mat-icon>close</mat-icon>
      {{ data.options.formState.disabled || data.readOnly ? 'Fermer':'Annuler' }}
    </button>
    <button type="submit" mat-raised-button color="primary" *ngIf="!(data.options.formState.disabled || data.readOnly)"
      cdkFocusInitial (click)="validate()">
      <mat-icon>check</mat-icon>
      Valider
    </button>
  </div>
</form>