<app-dialog-header cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
  [title]="data.title"></app-dialog-header>

<form [formGroup]="form" (keydown.enter)="onEnter($event)" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>

    <formly-form style="width:100%;" [form]="form" [fields]="fields" [model]="model" [options]="data.options">
    </formly-form>

  </div>
  <div mat-dialog-actions align="end">
    <button type="button" class="close-dialog" mat-raised-button color="primary" (click)="onCancel()">
      <mat-icon>close</mat-icon>
      {{ data.options.formState.disabled || data.readOnly ? 'Fermer':'Annuler' }}
    </button>
    <button type="submit" mat-raised-button color="primary" *ngIf="!(data.options.formState.disabled || data.readOnly)"
      cdkFocusInitial (click)="validate()">
      <mat-icon>check</mat-icon>
      Valider
    </button>
  </div>
</form>