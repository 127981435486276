import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorType, WaitingRoomService } from '@app/services';

@Component({
    selector: 'app-waiting-room-error',
    templateUrl: './waiting-room-error.component.html',
    styleUrls: ['./waiting-room-error.component.scss'],
    standalone: false
})
export class WaitingRoomErrorComponent implements OnInit {

  type: ErrorType;

  message: string;
  errorMessage: string;


  constructor(
    private waitingRoomService: WaitingRoomService,
    @Inject(MAT_DIALOG_DATA) private data,
    public dialogRef: MatDialogRef<WaitingRoomErrorComponent>,
    private router: Router
  ) {

    if (data) {
      this.type = data.type || null;
      this.errorMessage = data.errorMessage || null;
    }

  }

  ngOnInit(): void {
    this.waitingRoomService.config$.subscribe(conf => {
      // this.conf = conf
      switch (this.type) {
        case 'errorEnterRoom':
          this.message = conf.msgInfoGlobal + conf.msgErreurAccesSalleAttente;
          break;
        case 'expiredByServer':
          this.message = conf.msgErreurExpirationByServer;
          break;
        case 'timeIsUp':
          this.message = conf.msgErreurExpiration;
          break;
        case 'other':
          this.message = ''; // errorMessage should be defined 
          break;

      }
    })
  }

  onCancel() {
    this.router.navigate(['/account'])
    this.dialogRef.close()
  }
}
