export class ConfigEdition{
  editions: Edition[];

  objetsDisponibles?: ObjetEdition[];
  etatsDisponibles?: Etat[];
  entetesDisponibles?: EnteteEtat[];
  filtresDisponibles?: FiltreEntite[];
}

export class Edition{
  idEtat: number;
  titre: string;
  enabled: boolean;

  description: string;
  paramEtat: ParamEtat[];
  paramEnteteValue: string;
  filtreEntite: string;

  logiciel: string;
  obj: string;

  icon?: string;
  iconDomino?: string;
  iconFontawesome?: string;
  typeIcon?: string;

  entites?: Entite[];

}

export class Etat{
  idEtat: number;
  nomEtat: string;
  description: string;
  fichier: string;
  obj: string;
  estPerso: boolean;
  logiciel: string;
  filtre: string;
}

export class ObjetEdition {
  logiciel: string;
  nom: string;
  idKey: string;
  objetPourFiltre: string;
}

export class ParamEtat {
  nom: string;
  valeur: any;
  defaut: boolean;
}

export class EnteteEtat{
  type: string;
  valeur: string;
  label: string;
}

export class Entite{
  id: number;
  label: string;
  labelDetail: string;
}

export class FiltreEntite {
  nom: string;
  code: string;
  typesLogicielObjet: string[]; // Logiciel+"#"+Objet
}

