import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  standalone: false
})
export class DialogHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() icon: string;
  @Input() color: string = 'primary';
  @Input() noClose: boolean;
  @Input() draggable: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
