<app-form-viewer *ngIf="!isLoading; else loader" [readOnly]="readOnly" [formFields]="form" [model]="data"
  (save)="onSave($event)" [formTitle]="formTitle" [typeForm]="typeForm">
</app-form-viewer>

<ng-template #loader>
  <div style="width: 100%; height: 50vh; display:flex;">
    <app-loader isLoading="true" style="text-align: center;">
      <h3>{{ loadingMessage|trans }}</h3>
    </app-loader>
  </div>
</ng-template>

<ng-template #updateAddressBottomSheet>

  <div class="fx-direction-row fx-between-center">
    <span></span>
    <span class="primary font-size-title" style="font-weight: bold;">{{'update_address.title_card' | trans}}</span>
    <button mat-icon-button (click)="$event.preventDefault();bottomSheet.dismiss();" matTooltip="Fermer">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-card appearance="outlined">
    <mat-card-content class="fx-center-center">
      <span>{{ data.numRue }} {{ data.rue }}<br></span>
      <span *ngIf="data.rue2">{{ data.rue2 }}<br></span>
      <span *ngIf="data.rue3">{{ data.rue3 }}<br></span>
      <span>{{ data.codePostal }} {{ data.ville }}</span>
    </mat-card-content>
  </mat-card>

  <p>
    <b *ngIf="manyAddressesDisplay">{{'update_address.content_card_addresses' | trans}}</b>
    <b *ngIf="!manyAddressesDisplay">{{'update_address.content_card_only_address' | trans}}</b>
  </p>

  <div class="fx-direction-col" style="margin-bottom: 10px;">
    <mat-card appearance="outlined"
      *ngIf="dataUpdateAddress.familyAddress && !dataUpdateAddress.familyAddressIsSameConjointAddress"
      style="margin-bottom: 3px;">
      <mat-card-content class="fx-direction-row fx-wrap fx-between-center">
        <div class="label-checkbox">
          <b>{{ foyerData.civilite }}
            {{ foyerData.nomFamille | uppercase }}
          </b>
          <div>
            <span>{{ dataUpdateAddress.familyAddress.numRue }} {{ dataUpdateAddress.familyAddress.rue }}<br></span>
            <span *ngIf="dataUpdateAddress.familyAddress.rue2">{{ dataUpdateAddress.familyAddress.rue2 }}<br></span>
            <span *ngIf="dataUpdateAddress.familyAddress.rue3">{{ dataUpdateAddress.familyAddress.rue3 }}<br></span>
            {{ dataUpdateAddress.familyAddress.codePostal }} {{ dataUpdateAddress.familyAddress.ville }}
          </div>
        </div>
        <mat-radio-group class="radio-group" [(ngModel)]="updateFamilyChecked">
          <mat-radio-button value='oui'>Oui</mat-radio-button>
          <mat-radio-button value='non'>Non</mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined"
      *ngIf="dataUpdateAddress.userAddress && !dataUpdateAddress.userAddressIsSameConjointAddress">
      <mat-card-content class="fx-direction-row fx-wrap fx-between-center">
        <div class="label-checkbox">
          <b>{{ userData.civilite }} {{ userData.nom | uppercase }} {{ userData.prenom }}</b>
          <div>
            <span>{{ dataUpdateAddress.userAddress.numRue }} {{ dataUpdateAddress.userAddress.rue }}<br></span>
            <span *ngIf="dataUpdateAddress.userAddress.rue2">{{ dataUpdateAddress.userAddress.rue2 }}<br></span>
            <span *ngIf="dataUpdateAddress.userAddress.rue3">{{ dataUpdateAddress.userAddress.rue3 }}<br></span>
            {{ dataUpdateAddress.userAddress.codePostal }} {{ dataUpdateAddress.userAddress.ville }}
          </div>
        </div>
        <mat-radio-group class="radio-group" [(ngModel)]="updateUserChecked">
          <mat-radio-button value='oui'>Oui</mat-radio-button>
          <mat-radio-button value='non'>Non</mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="fx-direction-row fx-center-center">
    <button mat-raised-button color="accent" (click)="$event.preventDefault(); validUpdate(); bottomSheet.dismiss();"
      [disabled]="!updateFamilyChecked && !updateUserChecked">Valider</button>
  </div>
</ng-template>