<div class="details-container fx-direction-row fx-start-start fx-gap-50">
  <div class="fx-direction-row fx-wrap fx-start-start fx-gap-50">
    <div class="fx-direction-col">
      <div class="fx-direction-row fx-wrap fx-start-center">
        <h3 class="subtitle">
          <i style="margin-right: 5px;" class="icon icodomino-adulte"></i>Compte
        </h3>
      </div>
      <div class="value">Famille / Adulte : <b>{{reglement.debiteur}}</b></div>
      <div class="value">Login : <b>{{reglement.login}}</b></div>
    </div>

    <div class="fx-direction-col">
      <div class="fx-direction-row fx-wrap fx-start-center">
        <h3 class="subtitle">
          <i style="margin-right: 5px;" class="icon icodomino-mode-reglement"></i>Règlement
        </h3>
      </div>
      <div class="value">Montant : <b>{{ reglement.montant|currency:'EUR' }}</b></div>
      <div class="value">Date de règlement : <b>{{reglement.date|date}}</b></div>
      <div class="value">N° de règlement : <b>{{reglement.numero}}</b></div>
      <div class="value">Mode de règlement : <b>{{reglement.mode}}</b></div>
    </div>

    <div class="fx-direction-col">
      <div class="fx-direction-row fx-wrap fx-start-center">
        <h3 class="subtitle">
          <i style="margin-right: 5px;" class="icon icodomino-facturation"></i>Facturation
        </h3>
      </div>
      <div class="value">Régie : <b>{{reglement.regie}}</b></div>
      <button *ngIf="reglement.detailsFacture" mat-raised-button color="primary" (click)="openDialogDetailFacture()"
        class="fx-direction-row fx-center-center">
        <i class="fas fa-search" style="margin-right: 5px;"></i>
        <span>Voir panier</span>
      </button>
      <div class="value fx-direction-row fx-start-center" *ngIf="reglement.factures.length">Facture(s) :
        <span *ngFor="let f of reglement.factures; last as last" class="facture-info">
          <b>
            <a class="link-details fx-direction-row fx-start-center" (click)="getFactureDetails(f.id, f.idRegie)">
              <span>{{ f.numero }}</span>
              <span class="amount">({{ f.montant|currency:'EUR' }}){{ last ? '' : ' - ' }}</span>
              <mat-progress-spinner *ngIf="loadFactureDetails" mode="indeterminate" strokeWidth="2" diameter="24"
                color="accent" class="icon-loader">
              </mat-progress-spinner>
            </a>
          </b>
        </span>
      </div>
    </div>
  </div>

  <div class="fx-direction-col" style="min-width: 350px;">
    <div class="fx-direction-row fx-wrap fx-start-center">
      <h3 class="subtitle">
        <mat-icon>credit_card</mat-icon> Paiement
      </h3>
    </div>
    <div class="value">Type paiement : <b>{{typePaiement}}</b></div>
    <div class="value">Début paiement : <b>{{reglement.startDate|date:dateTimeFormat}}</b></div>
    <div class="value">Retour paiement : <b>{{reglement.returnDate|date:dateTimeFormat}}</b></div>
    <div class="value">Notification : <b>{{reglement.notificationDate|date:dateTimeFormat}}</b></div>
    <div class="value">Validation paiement : <b>{{reglement.validationDate|date:dateTimeFormat}}</b></div>
    <div class="value">Annulation paiement : <b>{{reglement.annulationDate|date:dateTimeFormat}}</b></div>
    <div class="value fx-direction-row fx-start-center">Etat :
      <b>{{('reglement.status.' + reglement.status)|trans}}</b>
      <mat-icon *ngIf="reglement.status === 'Valide'" class="accent">check</mat-icon>
      <mat-icon *ngIf="reglement.status === 'EnAttente'" class="primary">hourglass_empty</mat-icon>
      <mat-icon *ngIf="reglement.status === 'Annule'" class="warn">highlight_off</mat-icon>
    </div>
    <!-- <div class="value">Retour paiement : <b>{{reglement.startDate|date:dateTimeFormat}}</b></div> -->
    <div class="buttons">
      <button mat-raised-button color="primary" (click)="openDialogHistory()" class="fx-direction-row fx-center-center">
        <i class="fas fa-search" style="margin-right: 5px;"></i>
        <span>Voir historique</span>
      </button>
      <button *ngIf="reglement.status !== 'Annule'" mat-raised-button color="warn" (click)="cancelPayment()"
        [disabled]="clickOnCancel || clickOnValidate">
        <mat-icon *ngIf="!clickOnCancel">close</mat-icon>
        <app-loader *ngIf="clickOnCancel" diameter="18" class="icon-loader"></app-loader>
        Annuler le paiement
      </button>
      <button mat-raised-button color="accent" (click)="validatePayment()"
        [disabled]="clickOnValidate || clickOnCancel">
        <mat-icon *ngIf="!clickOnValidate">check</mat-icon>
        <app-loader *ngIf="clickOnValidate" diameter="18" class="icon-loader"></app-loader>
        Valider le paiement
      </button>
    </div>
  </div>

</div>

<ng-template #history>
  <app-dialog-header title="Historique" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
    [cdkDragDisabled]="isMobile" style="cursor: move;" />
  <mat-dialog-content>
    <pre class="history-data">{{ reglement.notes }}</pre>
  </mat-dialog-content>
</ng-template>

<ng-template #detailFacture>
  <app-dialog-header title="Panier" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
    [cdkDragDisabled]="isMobile" style="cursor: move;" />
  <mat-dialog-content style="margin-top: 20px;">
    <div style="margin-bottom: 10px; font-weight: bold;">
      {{factureDetails.civilite}} {{factureDetails.nom}} <br>
      {{factureDetails.rue}} <br>
      {{factureDetails.codePostal}} {{factureDetails.ville}} <br>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z4" matSortDirection="asc">

      <ng-container matColumnDef="usager">
        <th mat-header-cell *matHeaderCellDef> Usager </th>
        <td mat-cell *matCellDef="let detail"> {{detail._NomUsager}} </td>
        <td mat-footer-cell *matFooterCellDef> Total </td>
      </ng-container>
      <ng-container matColumnDef="designation">
        <th mat-header-cell *matHeaderCellDef> Désignation </th>
        <td mat-cell *matCellDef="let detail"> {{detail.designation}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="prixUnitaire">
        <th mat-header-cell *matHeaderCellDef> Prix unitaire </th>
        <td mat-cell *matCellDef="let detail"> {{detail.prixUnitaire | currency:'€' }} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="quantite">
        <th mat-header-cell *matHeaderCellDef> Quantite </th>
        <td mat-cell *matCellDef="let detail"> {{detail.quantite}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="montant">
        <th mat-header-cell *matHeaderCellDef> Montant </th>
        <td mat-cell *matCellDef="let detail"> {{detail.montant | currency:'€' }} </td>
        <td mat-footer-cell *matFooterCellDef> {{factureDetails.montantFacture | currency:'€'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

    </table>
  </mat-dialog-content>
</ng-template>

<ng-template #confirmDialog let-data>
  <mat-toolbar mat-dialog-title color="primary-lighter">
    <span class="title-center fx-direction-row fx-center-center">
      <mat-icon [color]="data.type === 'valid' ? 'primary' : 'warn'">
        {{data.type === 'valid' ? 'check_circle' : 'highlight_off'}}</mat-icon>
      <span>{{data.title}}</span>
    </span>
  </mat-toolbar>

  <div mat-dialog-content style="margin: 10px;">
    <div [innerHTML]="data.message" [ngStyle]="{'margin-bottom.px': data.type === 'valid' ? 0 : 10}"
      style="text-align: center;"></div>
    <mat-form-field class="motif" *ngIf="data.type === 'cancel'" style="width: 100%;">
      <mat-label>Motif de l'annulation</mat-label>
      <input type="text" matInput [(ngModel)]="motif" required>
    </mat-form-field>
    <div *ngIf="data.idReglement" class="fx-direction-row fx-start-center">
      <mat-icon color="warn" style="margin-right: 5px;">warning</mat-icon><span class="warn">Attention, le règlement
        '<b>{{data.numero}}</b>' sera supprimé de Domino</span>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close] color="warn">Fermer</button>
    <button mat-raised-button [mat-dialog-close]="true" color="primary" [disabled]="!motif && data.type === 'cancel'">
      Confirmer
    </button>
  </div>
</ng-template>