import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    standalone: false
})
export class IconComponent implements OnInit {

  @HostBinding('class.app-icon') hostClass = true;

  @Input() type: 'mat' | 'do' | 'fas' | 'far' | 'fab' | 'fa' | 'faIconWithType'; // Material / Domino / FontAwesome solid / FontAwesome regular / FontAwesome brand /FontAwesome icon value with type
  @Input() icon: string;
  @Input() color: string;
  @Input() size: string;

  constructor() { }

  ngOnInit() {
  }

}
