import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { PlatformService, AuthenticationService } from '@app/services';
import { AuthentificationConfig } from '@app/models/authentification-config';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

class FieldMatchErrorState implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective): boolean {
    return (control.dirty || control.touched) && (control.invalid || form.hasError('match'));
  }
}

export interface newPasswordForm {
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss'],
    standalone: false
})
export class NewPasswordComponent implements OnInit {

  zxcvbn: any;

  passForm: FormGroup<newPasswordForm> = this.fb.group({
    password: ['', [Validators.required, this.checkPasswordStrength.bind(this)]],
    confirmPassword: ['']
  }, {
    validators: [this.checkPasswordMatch]
  });

  requiredForce: number = 3;
  minLength: number = 8;
  @Input() label = 'Mot de passe';

  @Output() valueChange: EventEmitter<string>;
  @Output() statusChange: EventEmitter<string>;

  // For zxcvbn userInputs param
  private _username: string;
  get username(): string { return this._username }
  @Input() set username(value: string) {
    this._username = value;
    this.refreshStrength()
  };

  matcher = new FieldMatchErrorState();

  strength: number;
  strengthColor: string;
  showPass = false;

  authConfig: AuthentificationConfig;
  strengthLabel = '';
  actualLength = 0;

  constructor(
    private fb: FormBuilder,
    public platformService: PlatformService,
    private authService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.valueChange = new EventEmitter();
    this.statusChange = new EventEmitter();

  }

  ngOnInit(): void {
    this.passForm.valueChanges.pipe(filter(_ => !!this.valueChange)).subscribe(value => this.valueChange.emit(value.password));
    this.passForm.statusChanges.pipe(filter(_ => !!this.statusChange)).subscribe(status => this.statusChange.emit(status));

    this.authService.getConf().subscribe((conf: AuthentificationConfig) => {
      this.authConfig = conf || ({} as AuthentificationConfig)
      this.minLength = this.authConfig.passwordRules.minPasswordLength;
      this.requiredForce = this.authConfig.passwordRules.minPasswordStrength;
    });


    import('zxcvbn').then(m => this.zxcvbn = m.default);

    const passwordControl = this.passForm.get('password');

    passwordControl.valueChanges.subscribe(x => { this.refreshStrength() });
  }

  refreshStrength() {
    // Avoid error at init time, because we must wait for zxcvbn to be loaded
    if (!this.zxcvbn) {
      setTimeout(this.refreshStrength, 20)
      return;
    }

    const passwordControl = this.passForm.get('password');
    const password = passwordControl.value

    this.actualLength = password.length;
    this.strength = this.computeStrength(password);
    this.strengthColor = this.refreshStrengthColor(this.strength);
    this.strengthLabel = this.translateService.instant('password_strength.' + this.strength)
    passwordControl.updateValueAndValidity({ emitEvent: false });
  }

  getPasswordErrorMessage() {
    const field = this.passForm.get('password');

    if (field.hasError('required')) {
      return 'Obligatoire';
    }

    if (field.hasError('weak')) {
      return 'Mot de passe trop faible';
    }

    if (field.hasError('tooShort')) {
      return `Minimum ${this.minLength} caractères`;
    }

    return null;
  }

  computeStrength(value: string) {
    if (!value) return 0;
    const userInputs = [this._username]
    const zxcvbnResult = this.zxcvbn(value, userInputs)
    return zxcvbnResult.score;
  }

  refreshStrengthColor(strength: number) {
    return (strength < this.requiredForce || this.actualLength < this.minLength) ? 'warn' : 'primary';
  }

  checkPasswordStrength() {
    return this.strength < this.requiredForce ? { weak: true } :
      (this.actualLength < this.minLength) ? { tooShort: true } :
        null;
  }

  checkPasswordMatch(group: FormGroup) {
    // return object containing errors, or null if no error
    if (group.value.password !== group.value.confirmPassword) {
      return { match: true };
    }

    return null;
  }
}
