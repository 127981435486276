import { Component, OnDestroy, OnInit } from '@angular/core';
import { PointageConfig, PointageInscription } from '@app/models/pointage';
import { UserService } from '@app/services';
import { AssmatPointageService } from '@app/services/assmat-pointage.service';
import { BaseConfigService } from '@app/services/config/base.service';
import { DATE_FORMAT } from '@app/services/planning.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-assmat-pointage',
    templateUrl: './assmat-pointage.component.html',
    styleUrls: ['./assmat-pointage.component.scss'],
    standalone: false
})
export class AssmatPointageComponent implements OnInit, OnDestroy {

  legendItems = [
    { title: 'Horaires prévus', icon: 'history_toggle_off', color: '' },
    { title: 'Pointage non enregistré', icon: 'watch_later', color: '' },
    { title: 'Pointage existant', icon: 'query_builder', color: '' },
    { title: 'Pointage disponible', icon: 'edit', color: 'primary' },
    { title: 'Pointage indisponible', icon: 'block', color: 'warn' },
    { title: 'Enfant en remplacement', icon: 'autorenew', color: '' }
  ];

  loading = true;
  selectListChildInscription: PointageInscription[];
  allChildInscription: PointageInscription[];
  config: PointageConfig;
  idAssmat: number;
  selectedInscription: PointageInscription;
  noChild: boolean;
  showCurrentInscriptions: boolean = true;
  currentSelectedInscription: number;
  todayDate = moment().format('YYYY-MM-DD');

  onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private pointageService: AssmatPointageService,
    private configService: BaseConfigService,
  ) { }

  ngOnInit(): void {
    this.idAssmat = this.userService.currentUser.idAssmat;

    this.loadConfigPlanning().pipe(
      switchMap(confData => this.loadInscriptionChildren(this.idAssmat, confData))
    ).subscribe(_ => {
      if (!this.selectListChildInscription.length) {
        this.noChild = true;
      } else {
        this.selectedInscription = this.selectListChildInscription[0];
      }
      this.loading = false;
    })
  }

  loadInscriptionChildren(idAssmat, conf: PointageConfig) {
    const startEditableDate = this.setStartEditableDate(conf);
    return this.pointageService.getListInscriptionsByAssmat(idAssmat)
      .pipe(tap((data: PointageInscription[]) => {
        this.sortDatesRemplacement(data);
        this.sortBynomRef(data);
        this.setIconAndEnabledPointage(data);
        this.allChildInscription = data;
        this.selectListChildInscription = this.setSelectListInscriptionEnabled(data, startEditableDate);
      }));
  }

  sortBynomRef(allInscriptions) {
    allInscriptions.sort((a: PointageInscription, b: PointageInscription) => {
      if (a.nomReferent < b.nomReferent) return -1;
      if (a.nomReferent > b.nomReferent) return 1;
      return 0;
    });
  }

  sortBydateAsc(dateArray) {
    dateArray.sort((a: string, b: string) => {
      if (moment(a) < moment(b)) return -1;
      if (moment(a) > moment(b)) return 1;
      return 0;
    });
  }

  sortDatesRemplacement(inscriptions: PointageInscription[]) {
    inscriptions.forEach(inscription => {
      if (inscription.remplacement) {
        this.sortBydateAsc(inscription.datesRemplacement);
      }
    });
  }

  setSelectListInscriptionEnabled(AllListInscription, startEditableDate): PointageInscription[] {
    return AllListInscription.filter((data: PointageInscription) => {
      if (!data.remplacement) {
        return this.dateInPeriode(data.dateDebut, data.dateFin, startEditableDate);
      } else {
        return data.datesRemplacement.some(date => moment(date) >= moment(startEditableDate) && moment(date) <= moment(new Date));
      }
    });
  }

  // Permet de filtrer les inscriptions modifiables
  dateInPeriode(startPeriodeRef: string, endPeriodeRef: string, searchDate: string) {
    if (moment(searchDate) >= moment(startPeriodeRef) || moment(new Date) >= moment(startPeriodeRef)) {
      if (endPeriodeRef === '0000-00-00' || (endPeriodeRef !== '0000-00-00' && moment(searchDate) <= moment(endPeriodeRef))) {
        return true;
      }
    }
    return false;
  }

  loadConfigPlanning() {
    return this.configService.get<PointageConfig>('assmat-pointage').pipe(tap(conf => this.config = conf));
  }

  setStartEditableDate(conf) {
    return moment().subtract(conf.nbJourLimiteSaisie, 'd').format(DATE_FORMAT);
  }

  updateSelectChild(event) {
    if (!event) {
      this.selectListChildInscription = this.allChildInscription;
    } else {
      this.selectListChildInscription = this.setSelectListInscriptionEnabled(this.allChildInscription, this.setStartEditableDate(this.config));

      if (this.selectListChildInscription.length && !this.pointageService.findInscriptionById(this.selectedInscription.idInscription, this.selectListChildInscription)) {
        this.selectedInscription = this.selectListChildInscription[0];
      }
    }

    if (this.selectListChildInscription.length && this.noChild) {
      this.selectedInscription = this.selectListChildInscription[0];
      this.noChild = false;
    }

    if (!this.selectListChildInscription.length) {
      this.noChild = true;
    }

  }

  setIconAndEnabledPointage(listChildInsc: PointageInscription[]) {
    listChildInsc.forEach(insc => {

      if (!insc.remplacement) {
        if (this.dateInPeriode(insc.dateDebut, insc.dateFin, this.setStartEditableDate(this.config))) {
          this.createIconAndEnabledPointage(insc, 'create', true);
        } else {
          this.createIconAndEnabledPointage(insc, 'block', false);
        }
      } else {
        if (insc.datesRemplacement.some(date => moment(date) >= moment(this.setStartEditableDate(this.config)) && moment(date) <= moment(new Date))) {
          this.createIconAndEnabledPointage(insc, 'create', true);
        } else {
          this.createIconAndEnabledPointage(insc, 'block', false);
        }
      }
    });
  }

  createIconAndEnabledPointage(inscription: PointageInscription, icon: string, enabled: boolean) {
    inscription.icon = icon;
    inscription.enabledPointage = enabled;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
