import { Component, OnInit } from '@angular/core';
import { AnimationReunion, AnimationReunionAll } from '@app/models/animationReunion';
import { ContratAccueilMentalo, EnfantMentalo } from '@app/models/contrat-accueil';
import { PermissionService, PlatformService, SnackbarService, UserService } from '@app/services';
import { AssmatService } from '@app/services/assmat.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-list-animations',
    templateUrl: './list-animations.component.html',
    styleUrls: ['./list-animations.component.scss'],
    standalone: false
})
export class ListAnimationsComponent implements OnInit {

  idAssmat: number;
  listAllAnimations: AnimationReunionAll;
  listChild: EnfantMentalo[];
  listContrats: ContratAccueilMentalo[];
  permToAdd: boolean;
  loadingData: boolean;

  constructor(
    private assmatService: AssmatService,
    private userService: UserService,
    public platformService: PlatformService,
    private snackbar: SnackbarService,
    private permService: PermissionService
  ) { }

  ngOnInit(): void {
    this.permToAdd = this.permService.hasPermission('animations_add');
    this.idAssmat = this.userService.currentUser.idAssmat || null;
    this.loadAnimations();
  }

  loadAnimations() {
    forkJoin([
      this.assmatService.getAnimationsByIdAssmat(this.idAssmat),
      this.assmatService.getEnfantsMentaloByIdAssMat(this.idAssmat),
      this.assmatService.getContratsAccueilByIdAssMat(this.idAssmat)
    ]).subscribe(([animations, childList, contrats]: [AnimationReunionAll, EnfantMentalo[], ContratAccueilMentalo[]]) => {
      this.listAllAnimations = animations;
      this.listChild = childList;
      this.listContrats = contrats;
      this.loadingData = true;
    });
  }

  unsubscribe(animationReunion) {
    animationReunion.saving = true;

    let animationReunionPortail = {
      idAnimation: animationReunion.idAnimation,
      idAssmat: this.idAssmat,
    }

    this.assmatService.desinscriptionAnimationReunion(this.idAssmat, {
      idPortailAnimationReunionAssmat: animationReunion.portailInscription?.idPortailAnimationReunionAssmat,
      selectedChild: animationReunion.listEnfantMentaloRegisteredByAssmat,
      animationReunionPortail
    }).subscribe(_ => {
      this.afterConfirm('Desinscription', animationReunion);
    }, err => this.snackbar.error('Erreur lors de la désinscription'))
  }

  unsubscribeChild(event) {
    let animationReunion = event.animationReunion;
    let child = event.child;
    animationReunion.saving = true;

    let animationReunionPortail = {
      idAnimation: animationReunion.idAnimation,
      idAssmat: this.idAssmat,
    }

    this.assmatService.desinscriptionChildAnimationReunion(
      this.idAssmat,
      child.child.id,
      {
        animationReunionPortail,
        idPortailAnimationReunionEnfant: child.idPortailAnimationReunionEnfant,
        childRegistered: animationReunion.listEnfantMentaloRegisteredByAssmat
      }).subscribe(_ => {
        this.afterConfirm('Desinscription', animationReunion);
      }, err => this.snackbar.error('Erreur lors de la désinscription'))
  }

  registerChild(event) {
    let animationReunion = event.animationReunion;
    let registerChildIdList = event.registerChildList;
    animationReunion.saving = true;

    let animationReunionPortail = {
      idAnimation: animationReunion.idAnimation,
      idAssmat: this.idAssmat,
    }

    this.assmatService.inscriptionChildAnimationReunion(
      this.idAssmat,
      {
        idPortailAnimationReunionAssmat: animationReunion.portailInscription?.idPortailAnimationReunionAssmat,
        animationReunionPortail,
        selectedChild: registerChildIdList,
        childRegistered: animationReunion.listEnfantMentaloRegisteredByAssmat
      }).subscribe(_ => {
        this.afterConfirm('Inscription', animationReunion);
      }, err => this.snackbar.error("Erreur lors de l'inscription"))
  }

  afterConfirm(typeDemande: string, animation: AnimationReunion) {
    this.loadAnimations();
    if (typeDemande === "Desinscription") {
      this.snackbar.info('Désinscription Réussie');
    } else {
      this.snackbar.info('Inscription Réussie');
    }
    animation.saving = false;
  }
}
