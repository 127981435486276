<div
  class="oui-non-radio mat-form-field mat-primary mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label"
  style="width: 100%;">
  <div class="mat-form-field-wrapper">
    <div class="mat-form-field-flex">

      <div class="mat-form-field-infix">

        <span class="mat-form-field-label-wrapper">
          <label class="mat-form-field-label ">
            {{ to.label }}
            <span *ngIf="to.required" class="mat-form-field-required-marker">*</span>
          </label>
        </span>


        <div #formlyValidationMessage *ngIf="showError" class="mat-error">
          <mat-error>
            <formly-validation-message [field]="field"></formly-validation-message>
          </mat-error>
        </div>

        <editor #editor *ngIf="ready" [init]="init" [formControl]="field.formControl"
          [class]="showError?'editor-error':''" [disabled]="field.templateOptions.disabled">
        </editor>

      </div>
    </div>
  </div>
</div>