import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BoardCard } from '@app/models/board-card';
import { LasidoChoiceGroup, LasidoPreInscriptionConfig, LasidoPreinscription } from '@app/models/lasido';
import { BaseConfigService, FamilyService, PermissionService } from '@app/services';
import { LasidoService } from '@app/services/lasido.service';
import { Subject, forkJoin } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-lasido-preinscription',
    templateUrl: './lasido-preinscription.component.html',
    styleUrls: ['./lasido-preinscription.component.scss'],
    standalone: false
})
export class LasidoPreinscriptionComponent implements OnInit, OnDestroy {

  preinscriptions: LasidoPreinscription[];
  config: LasidoPreInscriptionConfig;
  permToCreate: boolean;
  permToEdit: boolean;
  addBtnUrl: string;

  onDestroy$ = new Subject<void>();

  private baseConfigService = inject(BaseConfigService)

  constructor(
    private lasidoPreinscriptionService: LasidoService,
    private familyService: FamilyService,
    private router: Router,
    public permService: PermissionService
  ) { }

  ngOnInit(): void {
    this.permToEdit = this.permService.hasPermission('lasido_edit') && this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('lasido_create') && this.familyService.currentFamily.active;

    this.baseConfigService.getFirstConf$("board-user")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(conf => {
        const cards = conf.content.cards;
        const idDemarche = cards.find((card: BoardCard) => card.component === 'lasido-preinscriptions').addBtnDemarcheId;
        this.addBtnUrl = idDemarche ? `new/${idDemarche}` : 'new';
      })

    this.loadData().subscribe();
  }

  loadData() {
    return this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => { this.preinscriptions = null; }),
      switchMap(f => {
        const loaders = [];

        loaders.push(this.familyService.getFamilyPreInscriptions(f, 'Lasido').pipe(tap((data: LasidoPreinscription[]) => {
          this.preinscriptions = data;
          this.preinscriptions.forEach(preinsc => {
            this.addIsCoursRetenuProperty(preinsc.choiceGroups);
          })
        })));
        loaders.push(this.lasidoPreinscriptionService.getConfigPreinscriptionForUser().pipe(tap(config => this.config = config)));

        return forkJoin(loaders);
      })
    );
  }

  addIsCoursRetenuProperty(choiceGroups: LasidoChoiceGroup[]): void {
    choiceGroups.forEach((group) => {
      group.cours.sort((a, b) => a.ordre - b.ordre)
      group.cours.forEach((course) => {
        course.isCoursRetenu = group._TabCoursRetenu.some((coursRetenu) => coursRetenu.id === course.id);
      });
    });
  }

  onClickEdit(item: LasidoPreinscription) {
    this.router.navigate([`account/lasido-preinscriptions/${item.id}`]);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
