import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/services';
import { AppUpdateService } from '@app/services';

@Component({
    selector: 'app-app-update-band',
    templateUrl: './app-update-band.component.html',
    styleUrls: ['./app-update-band.component.scss'],
    standalone: false
})
export class AppUpdateBandComponent implements OnInit {

  appUpdate: boolean;

  constructor(
    private appUpdateService: AppUpdateService,
  ) { }

  ngOnInit(): void {
    this.appUpdateService.appUpdateSubject.asObservable().subscribe(() => this.appUpdate = true);
  }

  reloadApp() {
    console.log('=> Reloading for new app version !');

    window.location.reload();
  }
}
