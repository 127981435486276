<ng-template appTopbarActions>
  <button mat-raised-button color="accent" style="margin-right: 3px;" routerLink="/account/tickets/achat">
    <mat-icon style="margin-right: 3px;">shopping_cart</mat-icon><span
      *ngIf="(platformService.isMobile$ | async) === false">Achat</span>
  </button>
</ng-template>

<div class="container">

  <div *ngIf="!dataSource && !loading">
    <h3 style="text-align: center;">Aucune données disponibles</h3>
  </div>

  <div *ngIf="!loading" class="fx-direction-row fx-between-center" style="margin-bottom: 10px;">
    <button mat-icon-button color="primary" (click)="onClickNextPrev('prev')" [disabled]="!disabledPrev">
      <mat-icon>chevron_left></mat-icon>
    </button>
    <mat-form-field *ngIf="showSelectMonth" subscriptSizing="dynamic">
      <mat-label>Mois</mat-label>
      <mat-select [(ngModel)]="monthSelected" (ngModelChange)="changeSelectedMonth($event)">
        <mat-option *ngFor="let month of listOfMonth" [value]="month">
          {{month}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span *ngIf="!showSelectMonth" style="font-size: 30px; font-weight: bold; cursor: pointer;"
      (click)="onClickSelectedMonth()">{{monthSelected}}</span>
    <button mat-icon-button color="primary" (click)="onClickNextPrev('next')" [disabled]="!disabledNext">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

  <div class="mat-table-container thin-scrollbar mat-elevation-z2" *ngIf="dataSource">
    <mat-tab-group (selectedIndexChange)="switchTypeRub($event)" color="accent" backgroundColor="primary"
      animationDuration="0ms">
      <mat-tab *ngFor="let data of releveTicketsData.typeTicket" [label]="data.libelleTypeRubrique">
        <!-- <div style="margin-left: 5px;"> -->
        <!-- <h3>Au {{ releveTicketsData.dateSolde | date: 'dd/MM/yyyy' }} : </h3> -->
        <!-- <div style="font-size: 15px; margin: 3px;"><span style="text-decoration: underline;"> Solde Réel </span>:
            <b>{{soldeReel}}</b></div>
          <div style="font-size: 15px; margin: 3px;"><span style="text-decoration: underline;"> Solde Prévisionnel
            </span>: <b>{{soldePrev}}</b></div>
        </div> -->
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="thin-scrollbar mat-elevation-z1">
    <app-loader-overlay *ngIf="loading"></app-loader-overlay>
    <table mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows matSortActive="date"
      matSortDirection="desc" style="width: 100%;">

      <!-- type column  -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let operation">
          <mat-icon *ngIf="operation.achat" style="color: rgba(28, 138, 28, 0.781);" matTooltip="Achat">move_to_inbox
          </mat-icon>
          <mat-icon *ngIf="operation.consommation" style="color: rgba(196, 36, 36, 0.801);" matTooltip="Consommation">
            outbox</mat-icon>
        </td>
      </ng-container>

      <!-- Date column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="date" sort>Date</th>
        <td mat-cell *matCellDef="let operation">
          <span style="margin-right: 10px;"><b>{{operation.date | date: 'dd/MM/yyyy'}}</b></span>
        </td>
      </ng-container>

      <!-- Achat Column -->
      <ng-container matColumnDef="achat">
        <th mat-header-cell *matHeaderCellDef>Achat</th>
        <td mat-cell *matCellDef="let operation">
          <span><b>{{operation.achat}}</b></span>
        </td>
      </ng-container>

      <!-- consommation column -->
      <ng-container matColumnDef="consommation">
        <th mat-header-cell *matHeaderCellDef>Consommation</th>
        <td mat-cell *matCellDef="let operation">
          <span><b>{{operation.consommation}}</b></span>
        </td>
      </ng-container>

      <!-- Solde column -->
      <ng-container matColumnDef="solde">
        <th mat-header-cell *matHeaderCellDef>Solde</th>
        <td mat-cell *matCellDef="let operation">
          <b>{{operation.solde}}</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let operation" [attr.colspan]="displayedColumns.length">
          <div class="operation-detail fx-direction-col fx-center-center"
            [@detailExpand]="operation === expandedOperation ? 'expanded' : 'collapsed'">

            <div *ngFor="let detail of operation.detail | keyvalue" style="margin: 10px 0; width: 100%;">
              <table *ngIf="detail.key === 'lignesFacture'" class="detail-table">
                <thead>
                  <tr style="background-color: rgba(28, 138, 28, 0.781);">
                    <th colspan="4" style="padding: 10px;">Achat</th>
                  </tr>
                </thead>
                <tr class="columnTitle">
                  <th>Quantité</th>
                  <th>Ticket</th>
                  <th>Prix unitaire</th>
                  <th>Montant</th>
                </tr>
                <tbody>
                  <tr *ngFor="let d of detail.value">
                    <td>{{d.quantite}}</td>
                    <td>{{d.rubrique}}</td>
                    <td>{{d.prixUnitaire | currency : '€'}}</td>
                    <td>{{d.montant | currency : '€'}}</td>
                  </tr>
                </tbody>
              </table>

              <table *ngIf="detail.key === 'presences'" class="detail-table">
                <thead>
                  <tr style="background-color: rgba(196, 36, 36, 0.801);">
                    <th colspan="5" style="padding: 10px;">Consommation</th>
                  </tr>
                </thead>
                <tr class="columnTitle">
                  <th>Usager</th>
                  <th>Ticket</th>
                  <th>Type</th>
                  <th>Heure début</th>
                  <th>Heure fin</th>
                </tr>
                <tbody>
                  <tr *ngFor="let d of detail.value">
                    <td>{{d.usager}}</td>
                    <td>{{d.rubrique}}</td>
                    <td>{{d.type}}
                      <span class="previsionnel" *ngIf="d.previsionnel" matTooltip="Prévisionnel">
                        <!-- <b style="margin: 4px;">P</b> -->
                        <em>(prévisionnel)</em>
                      </span>
                    </td>
                    <td>{{d.heureDeb}}</td>
                    <td>{{d.heureFin}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let operation; columns: displayedColumns;" class="operation-row"
        [class.expanded-row]="expandedOperation === operation"
        (click)="expandedOperation = expandedOperation === operation ? null : operation">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
  </div>
</div>

<ng-template #loader>
  <app-loader class="margin" [message]="'Chargement...'"></app-loader>
</ng-template>