import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FactureDetailComponent } from '../detail/detail.component';
import { PlatformService } from '@app/services/platform.service';
import { Facture, RegieConfig } from '@app/models/facturation';

@Component({
    selector: 'app-user-factures-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    standalone: false
})
export class FactureListComponent implements OnInit {

  @Input() factures: Facture[];
  @Input() config: RegieConfig;

  displayedColumns: string[];

  dataSource: MatTableDataSource<Facture>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  totalReste?: number;
  totalNumber?: number;
  totalMontant?: number;

  constructor(
    private dialog: MatDialog,
    public platformService: PlatformService,
  ) { }

  ngOnInit() {
    if (this.config.afficheEtatFacture) {
      this.displayedColumns = ['date', 'details', 'montantFacture', 'etat', 'action'];
    } else {
      this.displayedColumns = ['date', 'details', 'montantFacture', 'action'];
    }

    this.dataSource = new MatTableDataSource(this.factures);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.config.afficheTotaux) {
      this.totalNumber = this.factures.length;
      this.totalMontant = this.factures.reduce((acc, value) => acc + value.montant, 0);
      this.totalReste = this.factures.reduce((acc, value) => acc + value.resteAPayer, 0);
    }
  }

  openDetail(facture: Facture) {
    const detailsDialog = this.dialog.open(FactureDetailComponent, {
      data: { facture, regie: this.config }
    });

    this.platformService.adaptDialogToScreen(detailsDialog);
  }
}
