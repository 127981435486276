import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { EditorComponent } from '@tinymce/tinymce-angular';

@Component({
  selector: 'form-editor-type',
  templateUrl: './editor-type.component.html',
  styleUrls: ['./editor-type.component.scss'],
  standalone: false
})
export class EditorTypeComponent extends FieldType implements OnInit, AfterViewInit {
  ready: boolean;

  // @ViewChild("editor", { static: false }) editorRef: EditorComponent;

  init = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins: 'powerpaste',
    external_plugins: {
      powerpaste: '/tinymce/custom-plugins/custom-powerpaste/plugin.min.js'
    },
    toolbar: `bold italic underline | fontsizeselect | forecolor backcolor |
    alignleft aligncenter alignright ` ,
    toolbar_mode: 'floating',
    menubar: false,
    // forced_root_block: '',
    newline_behavior: 'invert', // replace the behavior of force_root_block:'' which is no longer supported
    language: 'fr_FR',
    language_url: '/assets/i18n/tinymce/fr_FR.js',

    powerpaste_word_import: 'clean',
    powerpaste_googledocs_import: 'clean',
    powerpaste_html_import: 'clean',
    powerpaste_allow_local_images: false,
    powerpaste_block_drop: true,
    images_file_types: '',
    smart_paste: false,
    paste_preprocess: (pluginApi, data) => {
      // Pour interdire l'ajout d'image par copier / coller 
      const content = data.content;
      if (content.toLowerCase().indexOf('<img') >= 0) { data.content = ''; }
    },

    statusbar: true,
    branding: false,
    elementpath: false,

    height: 250
  };

  ngOnInit() {
    this.ready = true;
    // Have to wait for iframe to load, or TinyMCE will throw error on doc.open() in initContentBody()
    // setTimeout(() => this.ready = true);
  }

  //Approche différente qui corrige le warning de la console mais qui ne fonctionne pas avec le code présent dans ngOnInit().
  ngAfterViewInit() {
    // setTimeout(() => {
    //   if(this.ready){
    //     this.editorRef.disabled = this.field.templateOptions.disabled;
    //   }
    // });
  }

}
