import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { PeriodeRubrique } from '@app/models/periode';
import { PointageInscription, PointageConfig, PointagePresence } from '@app/models/pointage';
import { PlatformService } from '@app/services';
import moment from 'moment';

@Component({
    selector: 'app-select-time-dialog',
    templateUrl: './select-time-dialog.component.html',
    styleUrls: ['./select-time-dialog.component.scss'],
    standalone: false
})
export class SelectTimeDialogComponent implements OnInit {

  planningConfig: PointageConfig;
  type: string;
  presences: PointagePresence[];
  existing: PointagePresence;
  editable: boolean;
  isOutsidePeriode: boolean;
  isUpdated: boolean = false;
  isInvoiced: boolean;
  inscription: PointageInscription;
  debutInscriptionDisplay: string;
  finInscriptionDisplay: string;
  date: moment.Moment;
  selectData = [];
  timeError = [];
  conflictPresence = [];
  conflictTimes = [];
  defaultTimes: boolean;
  prev: PointagePresence[];

  absenceRubriqueChoices?: PeriodeRubrique[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public platformService: PlatformService,
    private dialogRef: MatDialogRef<SelectTimeDialogComponent>,
    private dialog: MatDialog,
  ) {
    this.date = moment(data.date);
    this.planningConfig = data.planningConfig;
    this.presences = data.presences;
    this.existing = data.existingPresence;
    this.prev = data.prev;
    this.editable = data.editable;
    this.inscription = data.inscription;

    if (!this.existing) {
      this.type = "P";
      this.debutInscriptionDisplay = moment(data.inscription.dateDebut).format('DD/MM/YYYY');
      this.finInscriptionDisplay = moment(data.inscription.dateFin).format('DD/MM/YYYY');
    }
  }

  ngOnInit(): void {
    if (this.planningConfig.rubriqueAbsence?.ids?.length > 1) {
      this.absenceRubriqueChoices = this.planningConfig.rubriqueAbsence.ids.map(id => this.planningConfig.rubriques?.find(x => x.id === id));
    }

    if (!this.existing) {

      if (this.prev.length && !this.presences.length) {
        this.prev.forEach(prev => {
          this.type = prev.type.toUpperCase();
          this.addPointage(this.type, prev);
          if (prev.plage2Deb && prev.plage2Fin) {
            this.addPointage(this.type, prev);
          }
        })
      } else {
        this.addPointage(this.type);
      }
    } else {
      this.addPointage(this.existing.type, this.existing);
    }

    this.checkConflicts();
  }

  addPointage(type: string, presenceAbsence?: PointagePresence) {
    this.selectData.push(this.buildPointage(type, presenceAbsence));
  }

  onDeletePointage(index) {
    this.selectData.splice(index, 1);
  }

  buildPointage(type: string, presenceAbsence?: PointagePresence) {
    return {
      type,
      idFacture: presenceAbsence ? presenceAbsence.idFacture : null,
      idRubriquePlanning: presenceAbsence ? presenceAbsence.idRubriquePlanning : null,
      plage1Deb: presenceAbsence ? presenceAbsence.plage1Deb : '00:00',
      plage1Fin: presenceAbsence ? presenceAbsence.plage1Fin : '01:00',
      petitDej: presenceAbsence ? presenceAbsence.petitDej : false,
      collation: presenceAbsence ? presenceAbsence.collation : false,
      repasMidi: presenceAbsence ? presenceAbsence.repasMidi : false,
      gouter: presenceAbsence ? presenceAbsence.gouter : false,
      repasSoir: presenceAbsence ? presenceAbsence.repasSoir : false,
    } as PointagePresence
  }

  checkConflicts() {
    this.checkErrTimes();
    this.checkConflictsPresences();
    this.checkConflictsTimes();
    this.checkDateOutsidePeriode();
    this.checkInvoiced();
  }

  checkErrTimes() {
    this.timeError = [];

    this.selectData.forEach((data: PointagePresence) => {
      if (data.plage1Deb > data.plage1Fin || data.plage1Deb === data.plage1Fin) {
        this.timeError.push(data);
      }
    })
  }

  checkInvoiced() {
    if (this.existing) {
      this.existing.idFacture ? this.isInvoiced = true : this.isInvoiced = false;
    }
  }

  checkDateOutsidePeriode() {
    if (!this.existing) {
      this.isOutsidePeriode = this.date > moment(this.inscription.dateFin) || moment(this.inscription.dateDebut) > this.date ? true : false;
    }
  }

  checkConflictsPresences() {
    this.conflictPresence = [];
    this.presences.forEach(pr => {
      for (const data of this.selectData) {
        if (this.isTimesConflict(data, pr)) {
          this.conflictPresence.push(pr);
        }
      }
    })
  }

  checkConflictsTimes() {
    this.conflictTimes = [];
    if (this.selectData.length > 1) {
      this.selectData.forEach(data => {
        const index = this.selectData.indexOf(data);
        const copyOfSelectData = this.selectData.slice();
        copyOfSelectData.splice(index, 1);
        for (const spliceData of copyOfSelectData) {
          if (this.isTimesConflict(spliceData, data)) {
            this.conflictTimes.push(data);
          }
        }
      })
    }
  }

  checkUpdatedData() {
    if (this.existing) {
      this.isUpdated = true;
    }
  }

  isTimesConflict(first: PointagePresence, second: PointagePresence) {
    if (!((first.plage1Deb >= second.plage1Fin && first.plage1Fin > second.plage1Fin) ||
      (first.plage1Deb < second.plage1Deb && first.plage1Fin <= second.plage1Deb))) {
      return true;
    }
  }

  onDelete() {
    const ref = this.dialog.open(ConfirmDialogComponent, { data: { message: 'Voulez vous supprimer ce pointage ?' } });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.selectData.forEach(data => {
          data.remove = true;
        });
        this.dialogRef.close(this.selectData);
      }
    });
  }

  validate() {
    this.selectData.forEach((data: PointagePresence) => {

      if (data.type.toUpperCase() === "A") {
        if (!data.idRubriquePlanning || (data.idRubriquePlanning === this.planningConfig.rubriquePresence.id)) {
          data.idRubriquePlanning = this.planningConfig.rubriqueAbsence.ids[0];
        }
      }

      if (data.type.toUpperCase() === "P") {
        data.idRubriquePlanning = this.planningConfig.rubriquePresence.id;
      }

    });

    this.dialogRef.close(this.selectData);
  }

}
