<mat-drawer-container>
  <mat-drawer-content>
    <div id="animation-conteneur" class="thin-scrollbar" *ngIf="listAnimationsFiltered; else loader">
      <div class="fx-direction-row fx-wrap fx-center-center">

        <mat-form-field class="search-field" style="margin:0 5px 0 5px;" subscriptSizing="dynamic">
          <mat-label>Rechercher</mat-label>
          <input matInput [(ngModel)]="filters.search" (keyup)="changeFilter($event)">
        </mat-form-field>

        <!-- <mat-form-field class="no-hint" style="min-width: 350px; flex: 1; font-size: small;">
        <mat-label>Etablissements</mat-label>
        <mat-select [(ngModel)]="filters.regie" (ngModelChange)="changeFilter($event)">
          <mat-option style="font-style: italic;">Tous</mat-option>
          <mat-option *ngFor="let animation of listAnimations" [value]="animation.etablissement.id">
            {{ animation.etablissement.designation }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

        <mat-form-field class="no-hint" style="margin:0 5px 0 5px; max-width: 170px;" subscriptSizing="dynamic">
          <mat-label>Du</mat-label>
          <input matInput [(ngModel)]="filters.fromDate" [matDatepicker]="fromDatePicker"
            (dateChange)="changeFilter($event)">
          <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="no-hint" style="margin:0 5px 0 5px; max-width: 170px;" subscriptSizing="dynamic">
          <mat-label>Au</mat-label>
          <input matInput [(ngModel)]="filters.toDate" [matDatepicker]="toDatePicker"
            (dateChange)="changeFilter($event)">
          <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="!listAnimationsFiltered.length" style="width: 100%; text-align: center;">
        <h3>{{'animation_reunion.animation.no_animations_available'|trans}}</h3>
      </div>

      <div style="max-width:950px; margin: 10px auto auto auto;">
        <mat-accordion class="headers-align">
          <ng-container *ngFor="let animation of listAnimationsFiltered">
            <mat-expansion-panel [ngStyle]="{'opacity': animation.saving ? 0.7 : 1}">
              <mat-expansion-panel-header style="height: auto;">
                <mat-panel-title style="font-weight:bold;" class="fx-direction-row fx-start-center">
                  <span>{{animation.titre}}</span>
                </mat-panel-title>
                <mat-panel-description style="min-height: 40px;">
                  <div style="padding: 8px;">
                    <div style="margin-bottom: 6px;">Le {{animation.date | date: 'fullDate'}} de
                      {{animation.heureDebut}} à
                      {{animation.heureFin}}
                    </div>
                    <div style="font-size: smaller;">
                      <span *ngIf="animation.lieu">{{animation.lieu}}<br></span>
                      <span *ngIf="animation.rue1">{{ animation.rue1 }}<br></span>
                      <span *ngIf="animation.rue2">{{ animation.rue2 }}<br></span>
                      <span>{{ animation.codePostal }} {{ animation.ville }}</span>
                    </div>
                  </div>
                  <span matTooltip="Places restantes">
                    <app-icon type="fa" icon="ticket-alt" [matBadge]="animation.nbPlacesRestantes" matBadgeSize="small"
                      style="margin-right: 10px;" matBadgeColor="accent" class="primary">
                    </app-icon>
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div *ngIf="animation.description" class="description-html"><span
                  [innerHTML]="animation.description | safe"></span>
              </div>
              <div>
                <div style="margin-top: 10px;">
                  <span
                    style="text-decoration: underline; margin-bottom: 5px;"><b>{{'animation_reunion.etablissement_organisateur'
                      | trans}}
                      :</b></span>
                  <div style="margin-left:10px;">
                    <span *ngIf="animation.etablissement.designation">{{animation.etablissement.designation}}<br></span>
                    <span *ngIf="animation.etablissement.rue1">{{ animation.etablissement.rue1 }}<br></span>
                    <span *ngIf="animation.etablissement.rue2">{{ animation.etablissement.rue2 }}<br></span>
                    <span>{{ animation.etablissement.codePostal }}
                      {{ animation.etablissement.ville }}</span>
                  </div>
                </div>
                <div style="margin-top: 10px;">
                  <span style="text-decoration: underline; margin-bottom: 5px;">
                    <b>Lieu de l'animation : </b></span>
                  <div style="margin-left:10px;">
                    <span *ngIf="animation.lieu">{{animation.lieu}}<br></span>
                    <span *ngIf="animation.rue1">{{ animation.rue1 }}<br></span>
                    <span *ngIf="animation.rue2">{{ animation.rue2 }}<br></span>
                    <span>{{ animation.codePostal }} {{ animation.ville }}</span>
                  </div>
                </div>
              </div>
              <mat-action-row class="fx-direction-row fx-between-center">
                <div>
                  <p><b>Places restantes : </b>{{ animation.nbPlacesRestantes }}</p>
                  <p><b>Date limite d'inscription :</b> {{ animation.dateEcheanceInscription | date: 'longDate' }}</p>
                </div>
                <button *ngIf="!animation.assmatIsRegistered" mat-raised-button color="primary"
                  (click)="openRegisterChildDialog(animation)" [disabled]="animation.saving">
                  S'inscrire
                </button>
              </mat-action-row>
            </mat-expansion-panel>
          </ng-container>

        </mat-accordion>
      </div>
    </div>

  </mat-drawer-content>

  <!-- Options d'affichage -->
  <mat-drawer id="previewDrawer" class="mat-elevation-z4 thin-scrollbar" position="end" [(opened)]="drawerOpened">
    <mat-toolbar class="preview-header fx-direction-row fx-between-center" color='primary'>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
      <span>Options d'affichage</span>
      <span></span>
    </mat-toolbar>

    <div class="fx-direction-col" style="margin: 5px; padding: 5px;">

    </div>

  </mat-drawer>
</mat-drawer-container>

<!-- Inscription + ajout enfants dialog -->
<ng-template #RegisterChildDialog let-data>
  <app-dialog-header title="Inscription"></app-dialog-header>
  <div>
    <app-loader-overlay *ngIf="saving"></app-loader-overlay>
    <div mat-dialog-content class="thin-scrollbar">
      <h3 style="text-align: center;">
        {{ 'animation_reunion.animation.add'|trans:{titre : data.animation.titre} }}</h3>
      <p *ngIf="data.listChild.length">{{'animation_reunion.add_child' | trans}}</p>
      <div class="conteneur-list-child">
        <mat-selection-list [(ngModel)]="registerChildList" style="margin-bottom: 10px;" [disabled]="saving">
          <mat-list-option *ngFor="let child of data.listChild" [value]="child.id" [disabled]="child.id <= 0">
            {{child.nom}} {{child.prenom}}
            <div *ngIf="child.id <= 0" style="font-style:italic; font-size:small"
              class="fx-direction-row fx-start-center">
              <mat-icon color="warn" style="margin-right:5px;">warning</mat-icon>
              <span>{{'animation_reunion.contrat_accueil_en_attente' | trans }}</span>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="center">
    <button mat-raised-button color="warn" (click)="cancel()" [disabled]="saving">Annuler</button>
    <button mat-raised-button color="primary" (click)="clickToConfirm(data.animation)"
      [disabled]="saving">confirmer</button>
  </div>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>