import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Facture, RegieConfig, Reglement } from '@app/models/facturation';
import { FacturationService } from '@app/services';

interface RegieTotals {
  facture_amount?: number;
  facture_count?: number;
  payment_amount?: number;
  payment_count?: number;
  rest_due?: number;
}

@Component({
    selector: 'app-regie-factures',
    templateUrl: './regie-factures.component.html',
    styleUrls: ['./regie-factures.component.scss'],
    standalone: false
})
export class RegieFacturesComponent implements OnInit, OnChanges {

  @Input() regie: RegieConfig;
  @Input() factures: Facture[];
  @Input() reglements: Reglement[];

  visibleReglements: Reglement[];

  totals: RegieTotals = {};

  hasFactureToPay = false;

  constructor(
    private facturationService: FacturationService
  ) { }

  ngOnInit() {
    if (this.regie.afficheTotaux) {
      this.computeTotals();
    }

    this.regie.pausePaiement = this.facturationService.checkPausePaiement(this.regie);

    this.hasFactureToPay = this.factures.some(f => (f.status === 'EnAttenteDePaiement'));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.regie || changes.reglements) {
      // This setting means we must hide the "waiting" payments
      if (this.reglements && !this.regie.affichePaiementEnAttente) {
        this.visibleReglements = this.reglements.filter(reg => reg.status !== 'EnAttente');
      } else {
        this.visibleReglements = this.reglements;
      }
    }
  }

  computeTotals() {
    this.totals = {
      facture_amount: this.factures.map(f => f.montant).reduce((acc, mnt) => acc + mnt, 0),
      facture_count: this.factures.length,
      // Don't count "waiting" payments in total
      payment_amount: this.reglements.filter(reg => reg.status !== 'EnAttente').map(r => r.montant).reduce((acc, mnt) => acc + mnt, 0),
      payment_count: this.reglements.length,
      rest_due: this.factures.map(t => t.resteAPayer).reduce((acc, value) => acc + value, 0)
    };
  }
}
