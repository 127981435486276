import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GedEntity, GedPiece } from '@app/models/ged';
import { MandatSepa } from '@app/models/mandat-sepa';
import { AccountType } from '@app/models/user';
import { FamilyService, SnackbarService, UserService } from '@app/services';
import { GedService } from '@app/services/ged.service';
import { MandatSepaService } from '@app/services/mandat-sepa.service';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-file-upload-dialog',
    templateUrl: './file-upload-dialog.component.html',
    styleUrls: ['./file-upload-dialog.component.scss'],
    standalone: false
})
export class FileUploadDialogComponent implements OnInit {

  piece: GedPiece;
  entite: GedEntity;
  mandat: MandatSepa;
  errorMessage: string;
  uploadingFile: File;

  upload$: Observable<any>;
  uploadRequest: Subscription
  uploadProgress: number = null;
  previewURL: any;

  accountType: AccountType;
  idFamille: number;
  onSubmit = new EventEmitter<boolean>();
  onCancel = new EventEmitter<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private gedService: GedService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private familyService: FamilyService,
    private mandatSepaService: MandatSepaService
  ) {
    this.piece = this.data.piece;
    this.entite = this.data.entite;
    this.mandat = this.data.mandat;
    this.uploadingFile = this.data.uploadingFile;
    this.errorMessage = this.data.errorMessage;
  }

  ngOnInit(): void {
    this.loadPreview(this.uploadingFile);
    this.accountType = this.userService.currentUser.accountType;
    this.idFamille = this.familyService.currentFamily.id;
  }

  cancelUpload() {
    if (this.uploadingFile && this.uploadRequest) {
      // Should really abort the upload ...
      this.uploadRequest.unsubscribe();
    }
    this.onCancel.emit(true);
  }

  onUploadSubmit() {
    let progressUpdatedAt = Date.now();
    this.uploadProgress = 0;

    if (this.entite && this.entite.id) {
      this.upload$ = this.gedService.sendDocument(this.uploadingFile, this.piece, this.entite.id, this.accountType);
    } else if (this.mandat && this.mandat.idSEPAMandat) {
      this.upload$ = this.mandatSepaService.sendDocument(this.uploadingFile, this.piece, this.mandat.idSEPAMandat, this.idFamille);
    }

    this.uploadRequest = this.upload$.subscribe({
      next: event => {
        const now = Date.now();
        if (event.type === 'up_progress' && event.value > this.uploadProgress && (event.value === 100 || now - progressUpdatedAt > 300)) {
          this.uploadProgress = event.value as number;
          progressUpdatedAt = now;
        }
      },
      error: err => {
        console.error('Erreur lors de l\'envoi du fichier : ', err)
        this.snackbarService.error('Erreur lors de l\'envoi du fichier : ' + err)
      },
      complete: () => {
        this.onSubmit.emit(true);
        this.snackbarService.info('Fichier enregistré');
      }
    });
  }

  loadPreview(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader.result && (file.type.startsWith('image/') || file.type.endsWith('pdf'))) {
        this.previewURL = reader.result;
      }
    };
  }

}
